import { Link } from 'react-router-dom';
import EastIcon from '@mui/icons-material/East';
import Footer from '../../layout/footer';
import Logo from '../../components/common/logo';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { captureException } from '@sentry/react';
import useLocale from '../../hooks/useLocale';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

export default function PageNotFound() {
  const { locale: lang } = useLocale();

  const { t } = useTranslation();

  useEffect(() => {
    captureException(new Error('Page not found'));

    const timer = setTimeout(() => {
      Sentry.showReportDialog({
        lang,
      });
    }, 1200);

    return () => clearTimeout(timer);
  }, [lang]);

  return (
    <Box
      className="min-w-screen min-h-screen h-screen w-screen bg-[#FBFBFD] flex flex-col justify-between items-center p-8"
      id="not-found-container"
    >
      <Box className="w-3/4 flex flex-col items-center my-auto">
        <Logo className="mb-12" />
        <Typography variant="h2" className="title">
          404
        </Typography>
        <Typography variant="h2" className="subtitle">
          {t('alerts:page_not_found')}
        </Typography>
        <Link
          to="/"
          className="animate-btn text-primary text-lg cursor-pointer mt-8"
        >
          <Box component="span">{t('actions:go_to_dashboard')}</Box>
          <EastIcon fontSize="medium" className="ml-4 text-primary" />
        </Link>
      </Box>
      <Footer />
    </Box>
  );
}
