import { Box, Grid, InputLabel, Switch, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputFormField from '../../../../components/form/inputFormField';
import userContext from '../../../../contexts/userContext';
import User from '../../../../types/user';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useThemeMode } from '../../../../hooks/useThemeMode';

export type UpdateProps = {
  register: UseFormRegister<Partial<User>>;
  errors: FieldErrors<Partial<User>>;
};

type Props = UpdateProps & {
  editFields: (keyof User)[];
  setValue: UseFormSetValue<Partial<User>>;
  control: Control<Partial<User>>;
};

function UpdatePersonalInfos({
  register,
  errors,
  editFields,
  setValue,
  control,
}: Props) {
  const { user } = useContext(userContext);
  const [checked, setChecked] = useState<boolean>(Boolean(user.twof));

  const { t } = useTranslation('usersPage');
  const { mode } = useThemeMode();

  const toggleChecked = () => {
    setChecked((prev) => !prev);
    setValue('twof', !checked);
  };
  return (
    <Box
      sx={{
        bgColor: 'background.default',
        color: 'text.primary',
        display: 'flex',
        flexDirection: { sm: 'column', md: 'row' },

        borderBottom:
          mode === 'light' ? '#e5e7eb solid 1px' : '#334155 solid 1px',
        width: '100%',
        paddingBottom: '32px',
        gap: '12px',
      }}
    >
      <Typography variant="h5" className="lg:max-w-[240px] w-full">
        {t('yourPersonalInfo')}:
      </Typography>
      <Grid container gap={2} className="!mt-8 lg:!mt-2">
        {editFields.map((field, i) => (
          <Grid key={i} item xs={12} lg={5}>
            {field !== 'phone' ? (
              <InputFormField
                error={Boolean(errors[field])}
                helperText={errors[field] && String(errors[field]?.message)}
                disabled={field === 'email'}
                label={t(field)}
                {...register(field)}
              />
            ) : (
              <Box>
                <label className="!text-xs" htmlFor="phone">
                  {t('phone')}
                </label>
                <Box
                  sx={{
                    '&:hover': {
                      background: 'transparent',
                    },
                  }}
                  className="!mt-2 !h-12 !bg-transparent"
                >
                  <Controller
                    render={({ field }) => (
                      <PhoneInput
                        buttonStyle={{
                          background: 'transparent',
                          borderColor: '#E6E8EC60',
                        }}
                        containerStyle={{
                          background: 'transparent',
                        }}
                        dropdownStyle={{
                          background: 'transparent',
                        }}
                        inputStyle={{
                          height: '56px',
                          width: '100%',
                          borderColor: '#E6E8EC60',
                          background: 'transparent',
                        }}
                        country={'ch'}
                        {...field}
                      />
                    )}
                    name="phone"
                    control={control}
                  />
                </Box>
              </Box>
            )}
          </Grid>
        ))}

        <Grid
          item
          xs={12}
          lg={12}
          sx={{
            display: 'flex',
            gap: 1,
            justifyContent: 'flex-start',
            alignItems: 'center',
            mt: 1,
          }}
        >
          <InputLabel
            onClick={toggleChecked}
            className="cursor-pointer !text-xs"
          >
            {t('twoFactor')}
          </InputLabel>
          <Switch checked={checked} onChange={toggleChecked} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default UpdatePersonalInfos;
