import * as yup from 'yup';
export const GeneraleInfosStep = yup.object().shape({
  // general data
  status: yup.string().min(1).required(),
  agency: yup.string().min(1).required(),
  department: yup.number().required(),
  availability: yup.string().min(1).required(),
  availableFrom: yup.string().min(1).nullable(true),
  availableTo: yup.string().min(1).nullable(true),
  category: yup.string().min(1).required(),
  propertyType: yup.string().min(1).required(),
  // brokers and commissions
  broker: yup.string().min(1).required(),

  // location
  country: yup.string().min(1).required(),
  zipCode: yup.string().min(1).required(),
  city: yup.string().min(1).required(),
  address: yup.string().min(1).required(),
  constructionZone: yup.string().nullable(true),
  LandNumber: yup.string().nullable(true),
  // replacement address
  replacement_country: yup.string(),
  replacement_zipCode: yup.string(),
  replacement_city: yup.string(),
  replacement_address: yup.string(),
  replacement_constructionZone: yup.string().nullable(true),
  replacement_LandNumber: yup.string().nullable(true),
});
export const PropertyInfosStep = yup.object().shape({
  // surfaces
  rooms: yup.string().min(1).required(),
  bedrooms: yup.string().min(1).required(),
  bathrooms: yup.string().min(1).required(),

  // prices
  originalPrice: yup.string().min(1).required(),
  displayedPrice: yup.string().min(1).required(),
  pricePerMeter: yup.string().min(1).required(),
});
export const OwnerInfosStep = yup.object().shape({
  // owner infos
  isExisting: yup.boolean().required(),
  owner: yup.string().when('isExisting', {
    is: true,
    then: yup.string().min(1).required(),
  }),
  gender: yup.string().when('isExisting', {
    is: false,
    then: yup.string().min(1).required(),
  }),
  firstname: yup.string().when('isExisting', {
    is: false,
    then: yup.string().min(1).required(),
  }),
  lastname: yup.string().when('isExisting', {
    is: false,
    then: yup.string().min(1).required(),
  }),
  // company: yup.string().min(1).min(1).required(),
  address: yup.string().when('isExisting', {
    is: false,
    then: yup.string().min(1).required(),
  }),
  zipCode: yup.string().when('isExisting', {
    is: false,
    then: yup.string().min(1).required(),
  }),
  city: yup.string().when('isExisting', {
    is: false,
    then: yup.string().min(1).required(),
  }),
  email: yup
    .string()
    .email('enter_valid_email')
    .trim()
    .when('isExisting', {
      is: false,
      then: yup
        .string()
        .min(1)
        .email('enter_valid_email')
        .trim()
        .required('users_must_have_an_email'),
    }),
});
export const PublicationInfosStep = yup.object().shape({
  // owner infos

  publish_onWebsite: yup.boolean(),
  publish_asFeature: yup.boolean(),
  publish_onHP: yup.boolean(),
});
export const ConveniencesInfosStep = yup.object().shape({
  // owner infos
  environment: yup.string(),
  stores: yup.string(),
  transports: yup.string(),
  children: yup.string(),
  sport: yup.string(),
  leisure: yup.string(),
  outsideEnvironment: yup.string(),
});
export const DescriptionsInfosStep = yup.object().shape({
  // owner infos
  title: yup.string(),
  descriptions: yup.string(),
});
