import { Control, Controller } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import ReactQuill from 'react-quill';
import { defaultQuillModules, toolbarOptions } from '../const';

type Props = {
  control: Control<any, any>;
};

function OverviewBox({ control }: Props) {
  return (
    <Box className="mb-16 flex gap-20">
      <Typography className="stepTitle max-w-[160px]">
        Description / Overview
      </Typography>

      <Box className="flex flex-col w-full gap-6">
        <span className="text-xs text-zinc-500"> Description</span>
        <Controller
          rules={{ required: true }}
          control={control}
          name="description"
          render={({ field: { onChange, ref } }) => (
            <>
              <ReactQuill
                className="min-h-[200px]"
                ref={ref}
                theme="snow"
                modules={{
                  ...defaultQuillModules,
                  toolbar: toolbarOptions,
                }}
                onChange={onChange}
              />
            </>
          )}
        />
      </Box>
    </Box>
  );
}

export default OverviewBox;
