import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { Dispatch, SetStateAction } from 'react';
import { translationT } from '../../../../../../../types/property';
import { Box, Typography } from '@mui/material';
import ControlledSelectedForm from '../../../../../../../components/form/controlledSelectedForm';

export type conveniencesOptionT = {
  id: number;
  translations: translationT;
  value: string;
  name: string;
}[];
type Props = {
  register: UseFormRegister<any>;
  watch: UseFormWatch<any>;
  errors: FieldErrors<any>;
  setValue: UseFormSetValue<any>;
  environmentOptions: conveniencesOptionT;
  transportOptions: conveniencesOptionT;
  storesOptions: conveniencesOptionT;
  schoolingOptions: conveniencesOptionT;
  sportsOptions: conveniencesOptionT;
  leisureOptions: conveniencesOptionT;
  setSelectedIdsToSend: Dispatch<SetStateAction<never[]>>;
};
const Neighborhood = ({
  register,
  watch,
  errors,
  setValue,
  setSelectedIdsToSend,
  environmentOptions,
  storesOptions,
  transportOptions,
  schoolingOptions,
  leisureOptions,
  sportsOptions,
}: Props) => {
  const neighborhoodOptions = [
    { name: 'environment', label: 'Environment', options: environmentOptions },

    { name: 'stores', label: 'Stores', options: storesOptions },
    { name: 'transports', label: 'Transports', options: transportOptions },
    {
      name: 'children',
      label: 'Children and schooling',
      options: schoolingOptions,
    },
    { name: 'sport', label: 'Sport', options: sportsOptions },
    {
      name: 'leisure',
      label: 'Leisure and point of interest',
      options: leisureOptions,
    },
  ];

  return (
    <Box className="flex mb-16 gap-20">
      <Typography className="stepTitle max-w-[160px]">Neighborhood</Typography>
      <Box className="flex w-full flex-col gap-6">
        <Box className="grid grid-cols-2 w-full gap-6">
          {neighborhoodOptions.map(
            ({
              name,
              label,
              options,
            }: {
              name: string;
              label: string;
              options: conveniencesOptionT;
            }) => (
              <ControlledSelectedForm
                key={name + label}
                setSelectedIdsToSend={setSelectedIdsToSend}
                watch={watch}
                setValue={setValue}
                isMultiple
                options={options}
                label={label}
                name={name}
                error={errors[name]}
                register={register}
              />
            )
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Neighborhood;
