import { Box, InputAdornment, Typography } from '@mui/material';

import { FieldErrors, UseFormRegister } from 'react-hook-form';
import InputFormField from '../../../../../../../components/form/inputFormField';
import { useGeneralInfoStore } from '../../../store';

type Props = {
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
};
const propertyDetailsInfos = [
  {
    id: 0,
    label: 'Living area',
    name: 'livingArea',
  },
  {
    id: 1,
    label: 'Useful area',
    name: 'usefulArea',
  },
  {
    id: 2,
    label: 'Land surface',
    name: 'landSurface',
  },
  {
    id: 3,
    label: 'Terrace surface',
    name: 'terraceSurface',
  },
  {
    id: 4,
    label: 'Garden surface',
    name: 'gardenSurface',
  },
];

const SurfacesDetails = ({ register, errors }: Props) => {
  const updateInfo = useGeneralInfoStore((state) => state.updateInfo);

  console.log('register', register, errors);
  return (
    <Box className="grid grid-cols-[2fr_4fr] mt-12">
      <Typography className="stepTitle">Surfaces</Typography>
      <Box width="100%" display="flex" flexDirection="column" gap="48px">
        <Box width="100%" className="grid grid-cols-3" gap="48px">
          {propertyDetailsInfos.map(({ id, label, name }, index) => (
            <InputFormField
              isRequired={index === 0 ? true : false}
              key={label + id}
              label={label}
              inputProps={{
                step: '0.01',
              }}
              // placeholder={`Enter ${label}`}
              id="outlined-start-adornment"
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">m²</InputAdornment>
                ),
              }}
              {...register(name, {
                onChange: (e) =>
                  updateInfo({
                    name: label.toLowerCase(),
                    value: String(e.target.value),
                  }),
              })}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default SurfacesDetails;
