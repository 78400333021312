import { Box, Switch, Typography } from '@mui/material';
import { useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import InputFormField from '../../../../../../../components/form/inputFormField';
import { useGeneralInfoStore } from '../../../store';

type Props = {
  register: UseFormRegister<any>;
};

const ParkingDetails = ({ register }: Props) => {
  const updateInfo = useGeneralInfoStore((state) => state.updateInfo);
  const [isParking, setIsParking] = useState(false);
  const [isParkingBoxesAvailable, setIsParkingBoxesAvailable] = useState(false);

  const handleParkingChange = () => {
    setIsParking(!isParking);
    updateInfo({
      name: 'isParking',
      value: String(!isParking),
    });
  };
  const handleParkingBoxesChange = () => {
    setIsParkingBoxesAvailable(!isParkingBoxesAvailable);
    updateInfo({
      name: 'parkingBoxesAvailable',
      value: String(!isParkingBoxesAvailable),
    });
  };

  return (
    <Box>
      <Box className="grid grid-cols-[2fr_4fr] mt-12">
        <Typography className="stepTitle">Parking</Typography>
        <Box width="100%" display="flex" flexDirection="column" gap="48px">
          <Box width="100%" className="grid grid-cols-3" gap="24px">
            <Switch
              defaultChecked={isParking}
              className="col-span-full"
              {...register('isParking', {
                onChange: () => handleParkingChange(),
              })}
            />
            <InputFormField
              sx={{
                height: '44px',
                borderRadius: '5px',
                background: !isParking ? '#0001' : 'transparent',
              }}
              disabled={!isParking}
              label="Number of parking"
              // placeholder="Enter number of parking "
              inputProps={{
                step: '0.01',
              }}
              id="outlined-start-adornment"
              type="number"
              {...register('parkingNumber', {
                onChange: (e) =>
                  updateInfo({
                    name: 'parkingNumber',
                    value: String(e.target.value),
                  }),
              })}
            />
          </Box>
        </Box>
      </Box>
      <Box className="grid grid-cols-[2fr_4fr] mt-12">
        <Typography className="stepTitle">Parking boxes</Typography>
        <Box width="100%" display="flex" flexDirection="column" gap="48px">
          <Box width="100%" className="grid grid-cols-3" gap="24px">
            <Switch
              defaultChecked={isParkingBoxesAvailable}
              className="col-span-full"
              {...register('parkingBoxesAvailable', {
                onChange: () => handleParkingBoxesChange(),
              })}
            />
            <InputFormField
              sx={{
                height: '44px',
                borderRadius: '5px',
                background: !isParkingBoxesAvailable ? '#0001' : 'transparent',
              }}
              disabled={!isParkingBoxesAvailable}
              label="Number of parking"
              // placeholder="Enter number of parking "
              inputProps={{
                step: '0.01',
              }}
              id="outlined-start-adornment"
              type="number"
              {...register('parkingBoxesNumbers', {
                onChange: (e) =>
                  updateInfo({
                    name: 'parkingBoxesNumbers',
                    value: String(e.target.value),
                  }),
              })}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ParkingDetails;
