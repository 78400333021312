import { useTranslation } from 'react-i18next';
import CheckedIcon from '../../../../features/icons/checkedIcon';
import { Box, Stack, Typography } from '@mui/material';
import IProperty from '../../../../types/property';
import useLocale from '../../../../hooks/useLocale';
import { useMemo } from 'react';

type Props = {
  className?: string;
  featuresStyle?: string;
  data: IProperty;
};

function PropertyFeatures({ className, featuresStyle, data }: Props) {
  const { t } = useTranslation('propertiesPage');
  const { conveniences } = data;
  const { locale } = useLocale();

  const propertyfeatures = useMemo(() => {
    let propertyFeaturesArray = null;
    propertyFeaturesArray = conveniences['hydra:member'];

    if (propertyFeaturesArray) {
      if (propertyFeaturesArray && !propertyFeaturesArray.includes(undefined))
        return propertyFeaturesArray.map(({ name }: any) => name);
      else {
        return [];
      }
    }
  }, [locale, conveniences]);

  return (
    <Stack
      sx={{
        color: 'text.primary',
      }}
      className={
        'bg-[#FFFFFF] w-full rounded border p-8 flex flex-col ' + className
      }
    >
      <Typography variant="h6" className="mb-8">
        {t('property_features')}
      </Typography>
      <Box
        sx={{
          color: 'text.primary',
        }}
        className={'bg-[#FFFFFF]  grid grid-cols-4 gap-4 mt-4 ' + featuresStyle}
      >
        {propertyfeatures.length > 0
          ? propertyfeatures.map((feature: any, index: number) => (
              <Box
                sx={{
                  color: 'text.primary',
                }}
                key={index}
                className=" bg-[#FFFFFF] col-span-1 flex"
              >
                <Box className="w-7 h-7 rounded border aspect-square p-1 flex justify-center items-center mr-2 max-w-8">
                  <CheckedIcon color="green" />
                </Box>
                <Box component="span">{feature}</Box>
              </Box>
            ))
          : '-'}
      </Box>
    </Stack>
  );
}

export default PropertyFeatures;
