import {
  HTMLAttributes,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Transition, TransitionStatus } from 'react-transition-group';
import CSS from 'csstype';
import { Box } from '@mui/material';

type TransitionT = {
  [key in TransitionStatus]: CSS.Properties;
};

type Props = HTMLAttributes<HTMLDivElement> & {
  children: ReactElement | ReactElement[];
  mountDelay?: number;
  mounted?: boolean;
  duration?: number;
  transitionStyles?: Partial<TransitionT>;
};

export default function MountTransition({
  mounted,
  children,
  mountDelay = 500,
  transitionStyles,
  ...rest
}: Props) {
  const defaultTransitionStyles: TransitionT = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
    unmounted: { opacity: 0 },
  };

  const defaultStyle = {
    opacity: 0,
  };

  const [isMounted, setIsMounded] = useState<boolean>(mounted || false);
  const nodeRef = useRef(null);

  useEffect(() => {
    if (typeof mounted === 'undefined' || !isMounted) {
      setIsMounded(true);
    }
  }, [mounted, isMounted]);

  return (
    <Transition nodeRef={nodeRef} in={isMounted} timeout={mountDelay}>
      {(state: TransitionStatus) => (
        <Box
          style={{
            ...defaultStyle,
            ...{
              ...defaultTransitionStyles[state],
              ...transitionStyles?.[state],
            },
          }}
          {...rest}
        >
          {children}
        </Box>
      )}
    </Transition>
  );
}
