import Routing from './routing';
import * as Sentry from '@sentry/react';
import { SWRConfig } from 'swr';
import ToastContainer from './components/toast/toastContainer';
import 'react-toastify/dist/ReactToastify.css';
import { createTheme, ThemeProvider } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { appTheme } from './components/material-ui/theme';
import { useThemeMode } from './hooks/useThemeMode';
import { StepsProvider } from './contexts/stepperContext';
import './assets/scss/app.scss';
import 'react-toastify/dist/ReactToastify.css';
// import { swrConfig } from './libs/swr';
import { http } from './libs/axios';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './hooks/useAuth';

function App() {
  const { mode } = useThemeMode();
  const navigate = useNavigate();
  const { t } = useTranslation('alerts');
  const {
    user: { id: userId },
  } = useAuth();
  const theme = useMemo(() => createTheme(appTheme(mode)), [mode]);

  const getnotificationMessage = (status: string) => {
    switch (status) {
      case '1':
        return t('notification.Change_role_notif');
      case '2':
        return t('notification.Change_agency_notif');
      case '3':
        return t('notification.Unassigned_notif');
    }
  };
  useEffect(() => {
    const url = new URL('https://reals-api.ewm.dev/hub/.well-known/mercure');
    url.searchParams.append('topic', 'https://reals-api.ewm.dev/users');
    url.searchParams.append('topic', 'https://reals-api.ewm.dev/ping');
    const es = new EventSource(url, { withCredentials: false });
    es.onmessage = (e) => {
      const notifMessage = JSON.parse(e.data);
      const {
        message,
        user: { id },
      } = notifMessage;

      if (userId === id) {
        return toast.warning(getnotificationMessage(message));
      }
    };
  }, []);
  return (
    <SWRConfig
      value={{
        fetcher: (url: string, params: unknown) =>
          http
            .get(url, {
              params,
            })
            .then(({ data }: AxiosResponse) => data),
        onError: ({ response }) => {
          console.log('global Errror', response);
          if (response.status === 401) {
            // toast.warning(
            //   'you are not allowed to continue you will be redirect to login page'
            // );
            return navigate('/signin');
            // We can send the error to Sentry,
            // or show a notification UI.
          }
        },
      }}
    >
      <ThemeProvider theme={theme}>
        <StepsProvider>
          <ToastContainer />
          <Routing />
        </StepsProvider>
      </ThemeProvider>
    </SWRConfig>
  );
}

export default Sentry.withProfiler(App);
