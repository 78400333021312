import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AgencyDrawerContent } from '../../types/agency';
import { Box, Typography } from '@mui/material';
import UsersList from './components/usersList';
import { userItem } from './components/userItem';
import { useDragAndDropUsers } from '../../hooks/useDragAndDropUsers';
import { http } from '../../libs/axios';

type Props = {
  content: AgencyDrawerContent;
  setRoleModal: (arg: boolean) => void;
  basedUrl: string;
};

const AgencyDependencies = ({ content, setRoleModal, basedUrl }: Props) => {
  const { t } = useTranslation('agencyPage');
  const { users, updateUnAssignedUsers, unAssignedUsers } =
    useDragAndDropUsers();

  const [newType, setNewType] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [itemsPerPage] = useState<number>(10);
  const { type } = content;

  const getUnassignedUSers = async () => {
    const res = await http.get(
      `/users/not-assigned?page=${page}&itemsPerPage=${itemsPerPage}`
    );
    const unAssignedUsersFetched = res.data['hydra:member'];
    updateUnAssignedUsers(unAssignedUsersFetched);
  };

  useEffect(() => {
    setNewType(type);
  }, [type]);

  useEffect(() => {
    getUnassignedUSers();
  }, []);
  // useEffect(() => {
  //   if (unAssignedUsersFetched) {
  //     const usersfromApi = unAssignedUsersFetched['hydra:member'];
  //     setList(usersfromApi);
  //     // const totalItems = unAssignedUsersFetched['hydra:totalItems'];
  //     // const dataToUpdate = [...unAssignedUsers, ...usersfromApi];
  //     // unAssignedUsersFetched &&
  //     //   dataToUpdate.length <= totalItems &&
  //     //   updateUnAssignedUsers(dataToUpdate);
  //   }
  // }, [unAssignedUsersFetched]);

  const fetchMoreData = () => {
    setPage(page + 1);
  };

  return (
    <div className="flex flex-col gap-12">
      {newType === 'agencyUsers' && users && users.length > 0 && (
        <Box>
          <Typography className="!text-2xl !font-medium !text-[#1D1D1F]">
            {t(newType)}
          </Typography>
          <UsersList
            content={content}
            type="users"
            usersList={users as userItem[]}
            setRoleModal={setRoleModal}
            basedUrl={basedUrl}
          />
        </Box>
      )}
      <Box className="flex flex-col gap-4">
        <Typography className="!text-2xl !font-medium !text-[#1D1D1F]">
          {t('unassignedUsers')}
        </Typography>
        <Box>
          <InfiniteScroll
            dataLength={20}
            next={fetchMoreData}
            hasMore={true}
            loader={<></>}
            height={
              (users && !users.length) || type === 'unassignedUsers'
                ? '85vh'
                : 250
            }
          >
            <UsersList
              content={content}
              setRoleModal={setRoleModal}
              type="unassignedUsers"
              usersList={unAssignedUsers}
              basedUrl={basedUrl}
            />
          </InfiniteScroll>
        </Box>
      </Box>
    </div>
  );
};
export default AgencyDependencies;
