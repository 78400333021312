import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useStore from '../stepper/components/store';

type Props = {
  nbproperties?: number;
};

function PropertiesListingHeader({ nbproperties }: Props) {
  const { t } = useTranslation();
  const reset = useStore((state) => state.reset);

  return (
    <Box className="w-full pb-4 grid grid-cols-3 ">
      <Box className="col-span-1 flex flex-col ">
        <Typography variant="h3" className="text-3xl mb-2 ">
          {t('common:Properties')}
        </Typography>

        <Typography variant="subtitle1" className="text-sm text-gray-400">
          {nbproperties + ' ' + t('propertiesPage:perperties_found')}
        </Typography>
      </Box>
      <Box className="col-span-2 flex items-center justify-end py-4">
        <FormControlLabel
          control={<Checkbox />}
          label={t('propertiesPage:Show_properties_without_promotions')}
        />

        <Link to="addProperty">
          <Button
            onClick={() => {
              localStorage.removeItem('isEditMode'), reset();
            }}
            variant="contained"
            sx={{ background: 'background.default' }}
            startIcon={<AddIcon fontSize="medium" />}
          >
            {t('propertiesPage:add_new_property')}
          </Button>
        </Link>
      </Box>
    </Box>
  );
}

export default PropertiesListingHeader;
