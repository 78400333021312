import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip } from '@material-ui/core';

type TabGridTagT = {
  color?: string;
  textToolTip?: string;
  bgColor?: string;
  textContent: string;
  icon?: JSX.Element | JSX.Element[];
  iconPlacement?: 'start' | 'end';
  fontSize?: string;
  className?: string;
  isToolTipShown?: boolean;
  minWidth?: string;
};

function TabGridTag({
  color = '#3446B9',
  bgColor = 'transparent',
  textContent,
  textToolTip = textContent,
  icon,
  iconPlacement = 'start',
  fontSize = '16px',
  className,
  isToolTipShown = true,
  minWidth,
  ...rest
}: TabGridTagT) {
  const { t } = useTranslation('tags');

  const toolTipText = isToolTipShown ? t(textToolTip) : '';

  if (!textContent) return null;
  return (
    <Tooltip title={toolTipText} arrow placement="top">
      <Box
        {...rest}
        style={{
          borderColor: color,
          color: color,
          fontSize,
          minWidth: minWidth,
        }}
        className={` p-0.5  px-1 rounded max-w-[150px] bg-["${bgColor}"]  m-1  border text-["${color}"] flex items-center justify-center ${
          Boolean(className) && className
        } `}
      >
        {icon && iconPlacement == 'start' && icon}
        <Box
          component="span"
          className="mx-1  text-ellipsis overflow-hidden max-w-full"
        >
          {t(textContent)}
        </Box>
        {icon && iconPlacement == 'end' && icon}
      </Box>
    </Tooltip>
  );
}

export default TabGridTag;
