import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import ControlledSelectedForm from '../../../../../../../components/form/controlledSelectedForm';
import { Dispatch, SetStateAction } from 'react';
import { conveniencesOptionT } from './neighborhood';
type Props = {
  register: UseFormRegister<any>;
  watch: UseFormWatch<any>;
  errors: FieldErrors<any>;
  setValue: UseFormSetValue<any>;
  outsideOptions: conveniencesOptionT;
  setSelectedIdsToSend: Dispatch<SetStateAction<never[]>>;
};

const OutsideConveniences = ({
  register,
  watch,
  errors,
  setValue,
  setSelectedIdsToSend,
  outsideOptions,
}: Props) => {
  return (
    <Box className="flex mb-16 gap-20">
      <Typography className="stepTitle max-w-[160px]">
        Outside conveniences
      </Typography>

      <Box>
        <ControlledSelectedForm
          setSelectedIdsToSend={setSelectedIdsToSend}
          isMultiple
          watch={watch}
          setValue={setValue}
          options={outsideOptions}
          label="Environment"
          name="outsideEnvironment"
          error={errors['outsideEnvironment']}
          register={register}
        />
      </Box>
    </Box>
  );
};

export default OutsideConveniences;
