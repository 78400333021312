import {
  Box,
  ClickAwayListener,
  FormControl,
  MenuItem,
  Select,
  // SelectChangeEvent,
} from '@mui/material';
import { useContext, useRef, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { StepperContext } from '../../contexts/stepperContext';
import { useGeneralInfoStore } from '../../pages/properties/stepper/components/store';

export type selectOptionT = { id: number; value: string };

type SelectForm = {
  name: string;
  isRequired?: boolean;
  className?: string;
  label: string;
  error?: any;
  watch?: any;
  setSelectedIdsToSend?: any;
  setValue?: any;
  register: UseFormRegister<any>;
  agencies?: any;
};
// const defaultOptions = [{ id: 0, value: 'empty' }];

const ControlledSelectedFormWithHierchie = ({
  label,
  name,
  className,
  isRequired = false,
  error,
  setSelectedIdsToSend,
  setValue,
  agencies,
}: SelectForm) => {
  const { handleChange } = useContext(StepperContext);
  const updateInfo = useGeneralInfoStore((state) => state.updateInfo);
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const handleChangeValues = (event: any) => {
    const { target } = event;
    target;
    setSelectedValue(target.innerText);
    setValue('agency', target.innerText);
  };

  // useEffect(() => {
  //   setAnchorEl(null);
  // }, [selectedValue]);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const renderTree = (treeData: any) => {
    if (treeData.children && treeData.children.length > 0) {
      return (
        <span
          className="flex flex-col"
          aria-disabled
          key={treeData.id + Math.random()}
        >
          <span
            className={`${
              treeData.categoryEntity === 'Filial' ? 'ml-4' : 'ml-8'
            } mt-2 text-gray-400`}
          >
            {treeData.name}
          </span>
          {treeData.children.map((child: any) => renderTree(child))}
        </span>
      );
    } else {
      const newTreData = { id: treeData.id, value: treeData.name };
      return (
        treeData.categoryEntity === 'Department' && (
          <MenuItem
            onClick={(event) => {
              setSelectedIdsToSend && setSelectedIdsToSend(treeData.id);
              handleChange(event);
              handleChangeValues(event);
              updateInfo({
                name,
                value: newTreData.value,
              });
              handleClose;
            }}
            id={newTreData.id}
            key={newTreData.id}
            value={newTreData.value}
          >
            <span className="!ml-12 !mt-2 !w-full">{newTreData.value}</span>
          </MenuItem>
        )
      );
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <span className="text-xs text-zinc-500">
        {' '}
        {label} {isRequired && <span className="text-red-500">*</span>}
      </span>
      <FormControl error={error} fullWidth>
        <ClickAwayListener
          onClickAway={handleClose}
          onClose={handleClose as any}
        >
          <Select
            onClick={handleToggle}
            open={open}
            className={'w-[416px] h-12' + ' ' + className}
            defaultValue={''}
            value={selectedValue}
            renderValue={() => <span>{selectedValue}</span>}
            displayEmpty
          >
            {agencies.children && agencies.children.length ? (
              agencies?.children?.map((data: any) => renderTree(data))
            ) : (
              <MenuItem
                onClick={(event) => {
                  setSelectedIdsToSend && setSelectedIdsToSend(agencies.id);
                  handleChange(event);
                  handleChangeValues(event);
                  updateInfo({
                    name,
                    value: agencies.name,
                  });
                  handleClose;
                }}
                id={agencies.id}
                key={agencies.id}
                value={agencies.name}
              >
                <span className="!ml-12 !mt-2 !w-full">{agencies.name}</span>
              </MenuItem>
            )}
          </Select>
        </ClickAwayListener>
      </FormControl>
    </Box>
  );
};
export default ControlledSelectedFormWithHierchie;
