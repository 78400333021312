import { countries, Icountry } from '../../../../const/countries';
import { FileNode } from '../../../../types/agency';

const numberOfChildren = (
  node: FileNode,
  indexChild: number,
  categoryEntity: string,
  level: number
) => {
  for (
    let i = 0;
    categoryEntity !== 'Department' &&
    node.children &&
    node.children.length > 0 &&
    i < node.children.length;
    i++
  ) {
    const currentNode = node.children[i];
    currentNode!.indexChild = indexChild;
    currentNode!.level = level;
    indexChild = numberOfChildren(
      currentNode!,
      currentNode!.children && currentNode!.children.length > 0
        ? indexChild
        : indexChild + 1,
      node.children[i]!.categoryEntity,
      categoryEntity === node.children[i]!.categoryEntity ? level : level + 1
    );
  }

  return indexChild;
};

interface ITreeData extends FileNode {
  title: any;
  subtitle: any;
  expanded: boolean;
}

const calculOffset = (node: FileNode) => {
  const { level, indexChild } = node;
  return {
    x: !level ? 0 : level * 250,
    y: !indexChild && indexChild !== 0 ? 0 : 75 * indexChild,
  };
};

const getCountry = (countries: Icountry[], country: string): any => {
  let obj = {} as Icountry;
  if (countries && country) {
    obj = countries.find((elm) => elm.alpha2 === country) as Icountry;
    return obj;
  }
  return obj;
};
const convertToTreeData = (node: FileNode) => {
  if (node.categoryEntity === 'Company' && node.children?.length === 0) {
    node.title = !node.logo ? (
      node.name
    ) : (
      <div className="-my-3 mx-1 ">
        <img
          className="object-contain h-6 max-w-[128px]"
          src={node.logo}
          alt="logo"
        />
      </div>
    );
    node.subtitle = node.logo ? '' : node.name;
    node.expanded = true;
  } else if (node.categoryEntity === 'Agency') {
    node.title = node.name;
    node.subtitle = node.city;
    node.expanded = true;
  } else if (node.categoryEntity === 'Filial' && node.children?.length === 0) {
    node.title = node.name;
    node.subtitle = (
      <div className="flex gap-1 justify-start items-center mt-2">
        <div
          className={`react-tel-input flag ${node?.country?.toLocaleLowerCase()}`}
        ></div>
        <p className="m-0 pt-1">
          {getCountry(countries, node?.country || '')['en']}
        </p>
      </div>
    );
    node.expanded = true;
  }
  for (
    let i = 0;
    node.categoryEntity !== 'Department' &&
    node.children &&
    node.children.length > 0 &&
    i < node.children.length;
    i++
  ) {
    if (i === 0 && node.categoryEntity === 'Company') {
      node.title = !node.logo ? (
        node.name
      ) : (
        <div className="-my-3 mx-1 ">
          <img
            className="object-contain h-6 max-w-[128px]"
            src={node.logo}
            alt="logo"
          />
        </div>
      );
      node.subtitle = node.logo ? '' : node.name;
      node.expanded = true;
    } else if (i === 0 && node.categoryEntity === 'Filial') {
      node.title = node.name;
      node.subtitle = (
        <div className="flex gap-1 justify-start items-center mt-2">
          <div
            className={`react-tel-input flag ${node?.country?.toLocaleLowerCase()}`}
          ></div>
          <p className="m-0 pt-1">
            {getCountry(countries, node?.country || '')['en']}
          </p>
        </div>
      );
      node.expanded = true;
    } else if (i === 0 && node.categoryEntity === 'Department') {
      node.title = node.city;
      node.subtitle = <p className="m-0 pt-3"> test {node?.city}</p>;
      node.expanded = true;
    }

    const currentNode = node.children[i] as ITreeData;
    const locale = localStorage.getItem('i18nextLng') || 'en';
    // const newCountry = getCountry(countries, 'se')[locale];
    currentNode.title = currentNode?.name;
    currentNode.subtitle =
      currentNode?.categoryEntity === 'Filial' ? (
        <div className="flex gap-1 justify-start items-center mt-2">
          <div
            className={`react-tel-input flag ${currentNode?.country?.toLocaleLowerCase()}`}
          ></div>
          <p className="m-0 pt-1">
            {getCountry(countries, currentNode?.country || '')[locale]}
          </p>
        </div>
      ) : (
        <p className="m-0 pt-3">{currentNode?.city}</p>
      );

    currentNode.expanded = true;
    convertToTreeData(currentNode);
  }
};

const getUserRole = (type: string | undefined, role: string) => {
  if (role === 'admin') {
    switch (type) {
      case 'company':
        return ['ROLE_ADMIN'];
      case 'filial':
        return ['ROLE_ADMIN_ASSISTANT'];
      case 'agency':
        return ['ROLE_ADMIN_AGENT'];
      default:
        return ['ROLE_ADMIN_OPERATOR'];
    }
  } else {
    return ['ROLE_USER'];
  }
};

function findTreeNode(root: any, name: string) {
  const stack = [root];
  while (stack.length > 0) {
    const node = stack.pop();
    if (node.name === name) {
      return node;
    }
    if (node.children) {
      stack.push(...node.children);
    }
  }
  return null;
}

export {
  numberOfChildren,
  calculOffset,
  getCountry,
  convertToTreeData,
  getUserRole,
  findTreeNode,
};
