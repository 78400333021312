import PropertyLocation from './propertyLocation';
import UserInfoCard from './userInfoCard';

type Props = {
  data: any;
};

function PropertyContact({ data }: Props) {
  const { mainBroker } = data;
  return (
    <div className="w-full flex flex-col gap-8">
      <PropertyLocation data={data} />
      {mainBroker && <UserInfoCard mainBroker={mainBroker} />}
    </div>
  );
}

export default PropertyContact;
