import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function KeyIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g stroke={color} clipPath="url(#clip0_197_11618)">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15 12v3h-.5l-1-1h-2v-2h-2v-1.5L8 9a2.828 2.828 0 01-4 0l-.5-.5a3.536 3.536 0 010-5l1.75-1.75a3.89 3.89 0 015.5 5.5l-.25.25L15 12z"
        ></path>
        <circle cx="7" cy="5" r="1.5"></circle>
      </g>
      <defs>
        <clipPath id="clip0_197_11618">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
