import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Success from './Success';
import OwnerStepForm from './steps/ownerStep';
import PropertyStepForm from './steps/propertyStep';
import GeneralInfosStepForm from './steps/generalInfosStep/index';
import useStore from './store';
import Conveniences from './steps/conveniences';
import Descriptions from './steps/descriptions';
import Medias from './steps/medias';
import PublicationStepForm from './steps/publicationStep';
import { useEffect, useState } from 'react';
import User from '../../../../types/user';
import useSWR from 'swr';
import { ApiListing } from '../../../../types/api';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// Step titles
const labels = [
  { id: 1, label: 'General information' },
  { id: 2, label: 'Property details' },
  { id: 3, label: 'Owner' },
  { id: 4, label: 'Conveniences' },
  { id: 5, label: 'Descriptions' },
  { id: 6, label: 'Media' },
  { id: 7, label: 'Publications' },
];

const StepForm = () => {
  const activeStep = useStore((state) => state.activeStep);
  const [brokersList, setBrokersList] = useState<User[]>([] as User[]);

  const { data: listOfBrokers } = useSWR<ApiListing<User>>(`/users/assigned`);

  useEffect(() => {
    if (listOfBrokers && listOfBrokers) {
      setBrokersList(listOfBrokers['hydra:member']);
    }
  }, [listOfBrokers]);

  const handleSteps = (step: number) => {
    switch (step) {
      case 0:
        return <GeneralInfosStepForm />;
      case 1:
        return <PropertyStepForm />;
      case 2:
        return <OwnerStepForm brokersList={brokersList} />;
      case 3:
        return <Conveniences />;
      case 4:
        return <Descriptions />;
      case 5:
        return <Medias />;
      case 6:
        return <PublicationStepForm />;

      default:
        throw new Error('Unknown step');
    }
  };

  return (
    <div>
      {activeStep === labels.length ? (
        <Success />
      ) : (
        <>
          <Stepper
            connector={
              <ArrowForwardIosIcon
                sx={{
                  width: '16px',
                  height: '16px',
                  opacity: 0.5,
                  margin: '0px 8px',
                }}
              />
            }
            className="bg-[#F5F5F7] px-6 py-6 h-[88px]"
            activeStep={activeStep}
          >
            {labels.map(({ id, label }) => (
              <Step key={id + label}>
                <StepLabel
                  StepIconComponent={() => (
                    <div
                      style={{
                        borderColor:
                          activeStep + 1 === id
                            ? '#3446b9'
                            : activeStep >= id
                            ? '#4CAF50'
                            : '#6E6E72',
                      }}
                      className=" flex justify-center items-center w-10 h-10 border rounded-[50%]"
                    >
                      {id}
                    </div>
                  )}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <div className="px-10 py-6">{handleSteps(activeStep)}</div>
        </>
      )}
    </div>
  );
};

export default StepForm;
