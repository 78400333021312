import { Box, Tooltip, Typography } from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid';
import { renderFullPropertyAdress } from '../../../helpers/utils';

type Props = {
  cardKeys: string[];
  data: GridValidRowModel;
};

const displayValue = (data: GridValidRowModel, item: string) => {
  // console.log('indosCard', data, 'item', item);
  // return 'seif';
  if (data[item] !== null) {
    if (item === 'address') {
      return (
        <Tooltip title={renderFullPropertyAdress(data)} arrow placement="top">
          <span>{renderFullPropertyAdress(data)}</span>
        </Tooltip>
      );
    } else {
      return data[item].name ? data[item].name : data[item];
    }
  } else {
    return '-';
  }
};

function InfoCard({ cardKeys, data }: Props) {
  if (!data || !cardKeys) return null;
  return (
    <Box className="col-span-1 flex flex-col border border-[#E6E8EC]">
      {cardKeys &&
        cardKeys.map((item, index) => (
          <Box
            key={item + index}
            className="grid grid-cols-4 border-b border-[#E6E8EC] bg-white h-12 hover:bg-gray-50 "
          >
            <Typography
              variant="subtitle1"
              className="col-span-1 px-4 flex items-center capitalize"
            >
              {item}
            </Typography>
            <Typography
              variant="subtitle1"
              className="col-span-3 px-4 flex items-center overflow-hidden"
            >
              {displayValue(data, item)}
            </Typography>
          </Box>
        ))}
    </Box>
  );
}

export default InfoCard;
