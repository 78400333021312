import Xarrow from 'react-xarrows';
import { AgencyDrawerContent, FileNode } from '../../../types/agency';
import { TreeCard } from './TreeCard';
import { calculOffset } from './utils';

type TreeProps = {
  node: FileNode;
  isDrawerOpen: boolean;
  basedUrl: string;
  setDrawerContent: any;
  handleCloseDrawer: any;
  handleOpenDrawer: any;
  toggleDrawer: any;
  setRoleModal: (arg: boolean) => void;
  drawerContent: AgencyDrawerContent;
};

const Tree = ({
  setDrawerContent,
  handleCloseDrawer,
  handleOpenDrawer,
  isDrawerOpen,
  drawerContent,
  toggleDrawer,
  node,
  basedUrl,
  setRoleModal,
}: TreeProps) => {
  const { children, name, categoryEntity } = node;
  return (
    <div className="min-h-[10vh]">
      <TreeCard
        basedUrl={basedUrl}
        node={node}
        initialOffset={calculOffset(node)}
        setDrawerContent={setDrawerContent}
        drawerContent={drawerContent}
        handleCloseDrawer={handleCloseDrawer}
        handleOpenDrawer={handleOpenDrawer}
        toggleDrawer={toggleDrawer}
        isDrawerOpen={isDrawerOpen}
        setRoleModal={setRoleModal}
      />
      {categoryEntity !== 'Department' && children && children.length > 0 ? (
        (children ?? []).map((node: FileNode) => (
          <Xarrow
            key={node.id + Math.random()}
            start={name}
            end={node.name}
            startAnchor={'right'}
            endAnchor={'left'}
            path="grid"
            gridBreak="55%"
            headShape={{
              svgElem: <></>,
              offsetForward: 4,
            }}
            color="#E6E8EC"
            strokeWidth={1.5}
          />
        ))
      ) : (
        <></>
      )}
      <div>
        {categoryEntity !== 'Department' && children && children.length > 0 ? (
          (children ?? []).map((node: FileNode, index: number) => {
            return (
              <Tree
                key={index + ((node && node.name) || '0')}
                node={node}
                drawerContent={drawerContent}
                setDrawerContent={setDrawerContent}
                handleCloseDrawer={handleCloseDrawer}
                handleOpenDrawer={handleOpenDrawer}
                toggleDrawer={toggleDrawer}
                isDrawerOpen={isDrawerOpen}
                setRoleModal={setRoleModal}
                basedUrl={basedUrl}
              />
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default Tree;
