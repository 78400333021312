import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Data = GridColumns<{
  [key: string]: string;
}>;

type TranslationForTableGridXT = [data: Data, inns: string | string[]];

function useTranslationForTableGridXConfig([
  columnsData,
  inns,
]: TranslationForTableGridXT): Data {
  const { t } = useTranslation(inns);
  const [data, resetData] = useState<Data>([] as Data);

  useEffect(() => {
    resetData(columnsData);
  }, [localStorage.getItem('isLarge'), columnsData]);

  return data.map((item) => {
    return {
      ...item,
      headerName: t(item?.headerName || ''),
    };
  });
}

export default useTranslationForTableGridXConfig;
