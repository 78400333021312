import TableGridX from '../../../components/tableGridX';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import useTranslationForTableGridXConfig from '../../../hooks/useTranslationForTableGridX';
import { useTranslation } from 'react-i18next';
import {
  usersListingConfig,
  usersListingConfigForAgent,
} from '../../../components/material-ui/tablesConfig/usersListingConfig';
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { ApiListing } from '../../../types/api';
import User from '../../../types/user';
import { Box } from '@material-ui/core';
import { useAuth } from '../../../hooks/useAuth';

type Props = PropsWithChildren & {
  data: ApiListing<User>;
  itemsPerPage: number;
  page: number;
  handlePage: (page: number) => void;
  urlRef: string;
};

const UsersListingTable: FunctionComponent<Props> = ({
  data,
  itemsPerPage,
  page,
  handlePage,
}) => {
  const { t } = useTranslation('usersPage');
  const rows = data['hydra:member'];
  const [configList, setConfigList] = useState<any>([{}]);
  const { isAdmin } = useAuth();

  useEffect(() => {
    if (isAdmin) {
      setConfigList(usersListingConfigForAgent);
    } else {
      setConfigList(usersListingConfig);
    }
  });

  const usersListingColumns = useTranslationForTableGridXConfig([
    configList,
    'usersPage',
  ]);

  if (!data) return null;

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{ bgcolor: 'backgroundtra.paper', color: 'text.primary' }}
      >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{
            fileName: `${t('REALS_EXPORT_USERS')} - ${new Date()
              .toISOString()
              .replace('T', ' ')
              .slice(0, 10)}`,
            delimiter: ';',
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <Box className="w-full h-full ">
      <TableGridX
        rows={rows}
        columns={usersListingColumns}
        autoPageSize
        pageSize={itemsPerPage}
        page={page - 1}
        paginationMode="server"
        components={{ Toolbar: CustomToolbar }}
        rowCount={data['hydra:totalItems']}
        onPageChange={(page: number) => {
          handlePage(page + 1);
        }}
      />
    </Box>
  );
};

export default UsersListingTable;
