import {
  Button,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import Card from '../../../components/card';
import RectangleIcon from '../../../features/icons/rectangleIcon';
import TabGridTag from '../../../features/tags/tabGridTag';
import {
  renderFullPropertyAdress,
  renderPropertyAdress,
} from '../../../helpers/utils';
import { ApiListing } from '../../../types/api';
import IProperty from '../../../types/property';
import { convertDipslayedPricePrice } from '../../properties/utils';

const renderPropertyTitle = (row: any) => {
  const locale = localStorage.getItem('i18nextLng');
  const { translations } = row;
  const propertyDesc = translations[`${locale}`];
  if (propertyDesc) {
    return <span>{propertyDesc.title}</span>;
  } else {
    return '';
  }
};

export default function Properties() {
  const { t } = useTranslation('common');
  const { data } = useSWR<ApiListing<IProperty[]>>(
    `/v1/properties?page=1&itemsPerPage=6`
  );
  const rows = data ? data['hydra:member'] : null;
  const totalproperties = data ? data['hydra:totalItems'] : 0;
  return (
    <Card sx={{ bgcolor: 'background.default', color: 'text.primary' }}>
      <Card.Header
        sx={{
          bgcolor: 'background.paper',
          color: 'text.primary',
        }}
      >
        <Typography variant="subtitle1" fontWeight={500}>
          {`${t('Properties')} (${totalproperties})`}
        </Typography>
        <Link to="/properties">
          <Button> {t('showAll')}</Button>
        </Link>
      </Card.Header>

      <Card.Body className="p-0">
        <TableContainer>
          <Table>
            <TableBody>
              {(!data ? Array.from(new Array(3)) : rows)?.map(
                (item: any, i) => (
                  <TableRow
                    key={i}
                    sx={{
                      position: 'relative',
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr 1fr 1fr',
                      gridAutocolumns: 'min-content',
                      borderBottom: '1px solid rgb(230, 232, 246)',
                      alignItems: 'center',
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    {item ? (
                      <TableCell sx={{ borderBottom: 'none' }}>
                        {item?.department && item?.department?.service && (
                          <TabGridTag
                            textContent={item?.department?.service}
                            className="border-[#3446B9]"
                            color="#3446B9"
                            fontSize="12px"
                          />
                        )}
                      </TableCell>
                    ) : (
                      <TableCell className="w-full">
                        <Skeleton width="100%" />
                      </TableCell>
                    )}
                    {item ? (
                      <TableCell
                        sx={{ borderBottom: 'none' }}
                        className="truncate"
                      >
                        <Tooltip
                          title={renderPropertyTitle(item)}
                          arrow
                          placement="top"
                        >
                          <span>{renderPropertyTitle(item)}</span>
                        </Tooltip>
                      </TableCell>
                    ) : (
                      <TableCell className="w-full">
                        <Skeleton width="100%" />
                      </TableCell>
                    )}
                    {item ? (
                      <TableCell
                        sx={{ borderBottom: 'none' }}
                        className="truncate"
                      >
                        <Tooltip
                          title={renderFullPropertyAdress(item)}
                          arrow
                          placement="top"
                        >
                          {renderPropertyAdress(item)}
                        </Tooltip>
                      </TableCell>
                    ) : (
                      <TableCell className="w-full">
                        <Skeleton width="100%" />
                      </TableCell>
                    )}
                    {item ? (
                      <TableCell sx={{ borderBottom: 'none' }}>
                        {item.dipslayedPrice ? (
                          <Tooltip
                            title={
                              convertDipslayedPricePrice(
                                item.dipslayedPrice.toString()
                              ) + '.-'
                            }
                            arrow
                            placement="top"
                          >
                            <span className="flex items-center truncate">
                              <RectangleIcon
                                className="col-span-1"
                                color="#6E6E72"
                              />
                              <span className="ml-1">{`CHF ${
                                convertDipslayedPricePrice(
                                  item.dipslayedPrice.toString()
                                ) + '.-'
                              }`}</span>
                            </span>
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                      </TableCell>
                    ) : (
                      <TableCell className="w-full">
                        <Skeleton width="100%" />
                      </TableCell>
                    )}
                    {/* <TableCell>
                    <Box className="inline-block lowercase rounded border border-black px-2 py-1">
                      7 {t('Properties')}
                    </Box>
                  </TableCell> */}
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card.Body>
    </Card>
  );
}
