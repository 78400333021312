import {
  Box,
  Avatar,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { DragEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { mutate } from 'swr';
import UserDragIcon from '../../../features/icons/userDragIcon';
import TabGridTag from '../../../features/tags/tabGridTag';
import { useDragAndDropUsers } from '../../../hooks/useDragAndDropUsers';
import { http } from '../../../libs/axios';
import { AgencyDrawerContent } from '../../../types/agency';

export type userItem = {
  avatar: string;
  firstname: string;
  id: number;
  lastname: string;
  roles: string[];
  agency?: any;
  company?: any;
  department?: any;
  filial?: any;
  isSuperAdmin: boolean;
};
type Props = {
  content: AgencyDrawerContent;
  userItem: userItem;
  type: 'users' | 'unassignedUsers';
  setRoleModal: (arg: boolean) => void;
  basedUrl: string;
};

function UserItem({ userItem, type, setRoleModal, basedUrl, content }: Props) {
  const { t } = useTranslation('alerts');
  const { currentNode } = content;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { users, updateUserWhenDrop } = useDragAndDropUsers();
  const handleDragStart = (e: DragEvent<HTMLDivElement>) => {
    e.dataTransfer.setData('id', id.toString());
    e.dataTransfer.setData('type', type.toString());
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleUnassignUser = async () => {
    try {
      await http.patch(`/users/${id}/cancel`);
      const updatedUsers = users.filter((item) => item.id !== Number(id));
      updateUserWhenDrop(updatedUsers);
      toast.success(t(`alerts:user_unassigned`) as string);
      mutate(basedUrl);
    } catch (event: any) {
      captureException(event);
      if (event.response.status === 466) {
        toast.warning(event.response.data.message as string);
      } else {
        toast.error(t('alerts:user_not_unassigned') as string);
      }
    }
  };

  const handleChangeUserRole = async () => {
    const { categoryEntity, id: entityId } = currentNode;
    await setRoleModal(true);
    const containerValues = { type: categoryEntity, id: entityId };
    const userDataToSend = {
      id: `${id}`,
      type: 'changeRole',
      containerValues: JSON.stringify(containerValues),
    };
    localStorage.setItem('userDraggedData', JSON.stringify(userDataToSend));
  };

  const {
    agency,
    company,
    department,
    filial,
    avatar,
    firstname,
    lastname,
    id,
    roles,
    isSuperAdmin,
  } = userItem;

  return (
    <Box
      draggable={!isSuperAdmin}
      onDragStart={handleDragStart}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '4px',
        border: '1px solid #E6E8EC',
        background: '#F5F5F7',
        padding: '8px 16px',
      }}
    >
      <div className="flex gap-2 items-center">
        <Avatar alt={firstname} src={avatar} sx={{ width: 32, height: 32 }} />
        <Typography className="capitalize" variant="body1">
          {firstname + ' ' + lastname}
        </Typography>
      </div>
      <div className="flex gap-2 items-center">
        {isSuperAdmin ? (
          <TabGridTag
            key={'superAdmin'}
            textContent={'Super admin'}
            className="m-0 !text-xs"
            color="#ff0000"
          />
        ) : (
          roles.map((role: string) => (
            <TabGridTag
              key={role + Math.random()}
              className="!text-xs"
              textContent={
                role.toLocaleLowerCase().includes('admin') ? 'Admin' : 'Agent'
              }
              color={
                role.toLocaleLowerCase().includes('admin')
                  ? '#ED6C02'
                  : '#4CAF50'
              }
            />
          ))
        )}
        {!isSuperAdmin && (
          <Box>
            <button
              ref={anchorRef}
              id="basic-button"
              aria-controls={open ? 'composition-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={
                agency === null &&
                company === null &&
                department === null &&
                filial === null
                  ? () => {
                      return false;
                    }
                  : handleToggle
              }
            >
              {' '}
              <UserDragIcon className="cursor-pointer" />
            </button>
            <Popper
              className="z-[100]"
              sx={{ minWidth: '140px' }}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                      >
                        <MenuItem onClick={handleUnassignUser}>
                          Unassign user
                        </MenuItem>
                        <MenuItem onClick={handleChangeUserRole}>
                          Change role
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        )}
      </div>
    </Box>
  );
}

export default UserItem;
