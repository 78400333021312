import { Typography } from '@material-ui/core';
import { Box, Button } from '@mui/material';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import InputFormField from '../../../../../../../components/form/inputFormField';
import { countries } from '../../../../../../../const/countries';
import AutocompleteSelect from '../../../form/autoCompleteSelect';
import { useGeneralInfoStore } from '../../../store';
import { initialValueT } from '../../../store/types';
import StepperMapContainer from '../stepperMap';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { getCountry } from '../../../../../../agencies/tree/utils';
import { useState } from 'react';

type Props = {
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  clearErrors: any;
  setValue: any;
  watch: any;
  defaultValues: initialValueT;
};

const CadastralData = ({
  register,
  errors,
  clearErrors,
  setValue,
  watch,
  defaultValues,
}: Props) => {
  const { country } = defaultValues;
  const updateInfo = useGeneralInfoStore((state) => state.updateInfo);
  const [selectedAddress, setSelectedAddress] = useState<string>('');

  const handleMapLocation = () => {
    const countrySelected = getCountry(countries, watch('country')).label;
    const citySelected = watch('city');
    const zipCodeSelected = watch('zipCode');
    const addressSelected = watch('address');

    const fullAddressSelected = `${addressSelected && addressSelected + ','},${
      citySelected && citySelected + ','
    },${zipCodeSelected && zipCodeSelected + ','},${
      countrySelected && countrySelected + ','
    }`;
    setSelectedAddress(fullAddressSelected);
  };
  return (
    <Box>
      <Box className="grid grid-cols-[1fr_5fr] py-10" gap="80px">
        <Typography className="stepTitle">Cadastral data</Typography>
        <Box width="100%" display="flex" flexDirection="column" gap="48px">
          <Box className="grid grid-cols-2" gap="40px">
            <Box width="100%" display="flex" flexDirection="column" gap="40px">
              <AutocompleteSelect
                defaultValue={{
                  label: country,
                  value: country,
                }}
                isStepper
                setValue={setValue}
                clearErrors={clearErrors}
                isCountries
                options={countries}
                label="Country"
                isRequired
                name="country"
                error={errors['country']}
                className="w-full"
                register={register}
              />
            </Box>
            <Box className="grid grid-cols-2 w-full max-w-[416px]" gap={2}>
              <InputFormField
                isRequired
                label="City"
                // placeholder="Enter city"
                onFocus={() => clearErrors('city')}
                sx={{
                  width: '100%',
                  border: errors['city'] && '1px solid red',
                  height: '44px',
                  borderRadius: '5px',
                  zIndex: 9999,
                }}
                {...register('city', {
                  onChange: (e) =>
                    updateInfo({
                      name: 'city',
                      value: String(e.target.value),
                    }),
                })}
              />
              <InputFormField
                isRequired
                label="Zip Code"
                // placeholder="Enter zip code"
                onFocus={() => clearErrors('zipCode')}
                sx={{
                  width: '100%',
                  border: errors['zipCode'] && '0.1px solid red',
                  height: '44px',
                  borderRadius: '5px',
                }}
                {...register('zipCode', {
                  onChange: (e) =>
                    updateInfo({
                      name: 'zipCode',
                      value: String(e.target.value),
                    }),
                })}
              />
            </Box>
          </Box>
          <Box className="grid grid-cols-2" gap="40px">
            <InputFormField
              isRequired
              label="Address"
              // placeholder="Enter address"
              sx={{
                width: '100%',
                border: errors['address'] && '0.1px solid red',
                height: '44px',
                borderRadius: '5px',
              }}
              {...register('address', {
                onChange: (e) =>
                  updateInfo({
                    name: 'address',
                    value: String(e.target.value),
                  }),
              })}
            />
            {/* <InputFormField
            label="Replacement address"
            // placeholder="Enter replacement address"
            {...register('replacementAddress', {
              onChange: (e) =>
                updateInfo({
                  name: 'replacementAddress',
                  value: String(e.target.value),
                }),
            })}
          /> */}
            <InputFormField
              label="Construction zone"
              // placeholder="Enter construction zone"
              {...register('constructionZone', {
                onChange: (e) =>
                  updateInfo({
                    name: 'constructionZone',
                    value: String(e.target.value),
                  }),
              })}
            />
          </Box>
          <Box className="grid grid-cols-2" gap="40px">
            <InputFormField
              label="Land register number"
              // placeholder="Enter Land register number"
              type="number"
              {...register('landNumber', {
                onChange: (e) =>
                  updateInfo({
                    name: 'landNumber',
                    value: String(e.target.value),
                  }),
              })}
            />
            <Button
              variant="contained"
              sx={{
                background: 'background.default',
                height: '44px',
                alignSelf: 'end',
                marginBottom: '4px',
                width: '48%',
              }}
              startIcon={<MyLocationIcon fontSize="medium" />}
              disableElevation
              onClick={handleMapLocation}
            >
              locate in the map
            </Button>
          </Box>
        </Box>
      </Box>
      <Box className="grid grid-cols-[1fr_5fr] py-10" gap="80px">
        <Typography className="stepTitle">
          GPS coordinates of the object
        </Typography>
        <StepperMapContainer address={selectedAddress} />
      </Box>
    </Box>
  );
};
export default CadastralData;
