import { MouseEvent, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import { Avatar, Button, Grid, MenuItem } from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SettingsIcon from '../../../features/icons/settingsIcon';
import NotificationIcon from '../../../features/icons/notificationIcon';
import HelpInterrogationIcon from '../../../features/icons/helpInterrogationIcon';
import { useAuth } from '../../../hooks/useAuth';
import LogoutIcon from '../../../features/icons/logoutIcon';
// import MobileSearchBar from './mobileSearch';
import Logo from '../../../components/common/logo';
import BurgerMenu from './burgerMenu';
import { http } from '../../../libs/axios';

const dummyPath = [
  {
    name: 'profile settings',
    link: '/profile',
    icon: <SettingsIcon />,
  },
  {
    name: 'Notifications',
    link: '/notifications',
    icon: <NotificationIcon />,
  },
  {
    name: 'Support',
    link: '/support',
    icon: <HelpInterrogationIcon />,
  },
];

export default function MobileHeader() {
  const {
    user: { lastname, firstname, avatar },
    logout,
  } = useAuth();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleLogout = async () => {
    await http.post('/logout');
    window.localStorage.clear();
    logout;
    navigate('/signin');
  };
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid item xs={2}>
        <BurgerMenu />
      </Grid>
      <Grid item xs={8}>
        <Link to="/" className="h-16 border-b flex justify-center items-center">
          <Logo />
        </Link>
      </Grid>
      {/* <Grid item xs={1.5}>
        <MobileSearchBar />
      </Grid> */}
      <Grid className="flex justify-end" item xs={2}>
        <Button
          onClick={handleClick}
          className="flex gap-x-1 "
          size="small"
          sx={{
            '& .MuiButton-endIcon': { ml: 0 },
            maxWidth: { md: '216px' },
            minWidth: { md: '216px' },
            borderLeft: ' 1px solid #E6E8EC ',
            paddingLeft: '15px',
          }}
          endIcon={<ArrowDropDownIcon />}
        >
          <Avatar src={avatar} alt={`${firstname} ${lastname}`} />
        </Button>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {dummyPath.map(({ name, link, icon }, i) => (
            <MenuItem
              key={i}
              onClick={handleClose}
              sx={{ display: 'flex', gap: '16px' }}
            >
              <Link
                to={link}
                className="capitalize flex  gap-4 items-center justify-start"
              >
                {icon}
                {name}
              </Link>
            </MenuItem>
          ))}

          <MenuItem
            onClick={handleLogout}
            sx={{ display: 'flex', gap: '16px' }}
          >
            <LogoutIcon />
            <span>Logout</span>
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
}
