import i18n from '../i18n';

export type Locale = 'fr' | 'en' | 'de';

type LocaleState = {
  langues: Readonly<Array<{ [Property in Locale]?: string }>>;
  locale: Locale;
  update: (locale: Locale) => void;
};

const useLocale = (): LocaleState => {
  /**
   * Since default locale comes from #LanguageDetector in different ISO FORMAT
   * We need to change it
   */

  const locale = new Intl.Locale(i18n.language).language as Locale;

  const update = (locale: Locale) => i18n.changeLanguage(locale);

  return {
    locale,
    update,
    langues: [
      {
        en: 'English',
      },
      {
        fr: 'French',
      },
      {
        de: 'German',
      },
    ],
  };
};

export default useLocale;
