import { Box } from '@mui/material';
import { countries } from '../../const/countries';
import LocationIcon from '../../features/icons/locationIcon';
import { getCountry } from '../../pages/agencies/tree/utils';

export const renderPropertyAdress = (row: any) => {
  const fullPropertyAdress = renderFullPropertyAdress(row);
  return (
    <Box className="flex items-center leading-5 w-full">
      {fullPropertyAdress.length > 0 && (
        <span className="flex items-center capitalize h-6 px-2 w-full">
          <LocationIcon className="col-span-1 mr-1" color="#6E6E72" />
          <span className="w-full truncate">{fullPropertyAdress}</span>
        </span>
      )}
    </Box>
  );
};

export const renderFullPropertyAdress = (row: any) => {
  const locale = localStorage.getItem('i18nextLng');
  const { address } = row;
  const localAdress =
    address && address.translations && address.translations[`${locale}`]
      ? address.translations[`${locale}`]
      : '';

  const { country, adresse, city } = localAdress;
  const fullNameCountry = getCountry(countries, country)
    ? getCountry(countries, country)[`${locale}`]
    : null;
  const fullAdresss = `${adresse ? adresse + ', ' : ''}${
    city ? city + ', ' : ''
  }${address.zipCode ? address.zipCode + ', ' : ''}${
    fullNameCountry ? fullNameCountry : ''
  }`.trim();
  return fullAdresss.charAt(fullAdresss.length - 1) === ','
    ? fullAdresss.slice(0, -1)
    : fullAdresss;
};
