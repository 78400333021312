import Logo from '../../components/common/logo';
import Nav from './nav';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

export default function Aside() {
  return (
    <>
      <Link to="/" className="h-16 border-b flex justify-center items-center">
        <Logo />
      </Link>
      <Box className="flex flex-col max-h-[93vh] h-full ">
        <Nav />
      </Box>
    </>
  );
}
