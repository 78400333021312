import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import ControlledSelectedForm from '../../../../../../../components/form/controlledSelectedForm';
import DateRangePicker from '../../../../../../../components/dateRangePicker/dateRangePicker';
import {
  residentialPropertyOptions,
  commercialPropertyOptions,
  stepperOptionT,
  infoType,
  statusOptions,
  categoryOptions,
  availabilityOptions,
} from '../../../../../../../const/propertiesOptions';

import { initialValueT } from '../../../store/types';
import { agencyApiItem } from '..';
import ControlledSelectedFormWithHierchie from '../../../../../../../components/form/controlledSelectedFormWithHierchie';

type Props = {
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  categoryValue: any;
  defaultValues: initialValueT;
  watch: any;
  setValue: any;
  agencies: agencyApiItem[];
};

const GeneralDataForm = ({
  errors,
  register,
  categoryValue,
  watch,
  setValue,
  agencies,
}: Props) => {
  const generalDataInfos: infoType[] = [
    { id: 0, label: 'status', options: statusOptions },
    { id: 1, label: 'agency', options: [] },
    { id: 2, label: 'category', options: categoryOptions },
    { id: 3, label: 'availability', options: availabilityOptions },
    { id: 4, label: 'propertyType', options: residentialPropertyOptions },
  ];

  const infosNumber = generalDataInfos.length;
  const [propertyTypeOptions, setPropertyTypeOptions] = useState<
    stepperOptionT[]
  >([] as stepperOptionT[]);

  useEffect(() => {
    if (categoryValue !== undefined) {
      if (categoryValue === 'commercial') {
        setPropertyTypeOptions(commercialPropertyOptions);
      } else {
        setPropertyTypeOptions(residentialPropertyOptions);
      }
    }
  }, [categoryValue]);

  return (
    <Box display="flex" flexDirection="row" gap="80px">
      <Typography width="20%" className="stepTitle">
        General data
      </Typography>
      <Box width="100%" display="flex" flexDirection="column">
        <Box width="100%" className="grid grid-cols-2 capitalize" gap="48px">
          {generalDataInfos.map(({ id, label, options }, index: number) =>
            label === 'agency' ? (
              <ControlledSelectedFormWithHierchie
                isRequired
                watch={watch}
                setValue={setValue}
                key={`${label + id}`}
                agencies={agencies}
                // options={
                //   index < infosNumber - 1 ? options : propertyTypeOptions
                // }
                label="agency"
                name="agency"
                error={errors['agency']}
                register={register}
              />
            ) : (
              <ControlledSelectedForm
                isRequired
                watch={watch}
                setValue={setValue}
                key={`${label + id}`}
                options={
                  index < infosNumber - 1 ? options : propertyTypeOptions
                }
                label={label}
                name={label}
                error={errors[`${label}`]}
                register={register}
              />
            )
          )}
          {watch('availability') === 'choose date' && (
            <Box
              width="100%"
              className="grid grid-cols-2 items-center justify-center mt-1 max-w-[416px]"
              gap="8px"
            >
              <DateRangePicker
                isRequired
                label={'start date'}
                name={'startDate'}
                setValue={setValue}
              />
              <DateRangePicker
                label={'end date'}
                name={'endDate'}
                setValue={setValue}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default GeneralDataForm;
