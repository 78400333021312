import { yupResolver } from '@hookform/resolvers/yup';
import { PropertyInfosStep } from '../../../../../../libs/yup/propertyValidation';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { http } from '../../../../../../libs/axios';
import useStore, { initialPropertyInfos } from '../../store';
import { propertyStepT } from '../../store/types';
import PropertyDetails from './components/details';
import ParkingDetails from './components/parkingDetails';
import PricesDetails from './components/pricesDetails';
import RoomsDetails from './components/roomsDetails';
import SurfacesDetails from './components/surfacesDetails';
import { useEffect, useState } from 'react';
import { ScrollUp } from '../../../../../../helpers/utils/scroolUp';
import NextButton from '../../form/nextButton';
import PreviousButton from '../../form/previousButton';

const PropertyStepForm = () => {
  const [defaultValues, setDefaultValues] = useState(initialPropertyInfos);

  const handleNext = useStore((state) => state.handleNext);
  const isEditMode = Boolean(localStorage.getItem('isEditMode')) || false;

  const persistedValues = JSON.parse(
    localStorage.getItem('property-infos') as string
  ).state.generalInfos as propertyStepT;
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<propertyStepT>({
    shouldFocusError: false,
    defaultValues,
    resolver: yupResolver(PropertyInfosStep),
  });
  const onSubmit = async (data: any) => {
    const {
      floor,
      balcony,
      terrace,
      lift,
      pool,
      garden,
      terraceSurface,
      gardenSurface,
      rooms,
      bedrooms,
      bathrooms,
      livingArea,
      usefulArea,
      landSurface,
      parkingNumbers,
      parkingAvailable,
      parkingBoxesAvailable,
      parkingBoxesNumbers,
      originalPrice,
      displayedPrice,
      pricePerMeter,
      parkingPrice,
      charges,
    } = data;
    const dataToSend = {
      floor: Number(floor),
      balcony: Number(balcony),
      terrace: Number(terrace),
      lift,
      pool,
      garden,
      terraceSurface: Number(terraceSurface),
      gardenSurface: Number(gardenSurface),
      rooms: Number(rooms),
      bedrooms: Number(bedrooms),
      bathrooms: Number(bathrooms),
      livingArea: Number(livingArea),
      usefulArea: Number(usefulArea),
      landSurface: Number(landSurface),
      parkingNumbers: parkingAvailable ? Number(parkingNumbers) : 0,
      parkingAvailable,
      parkingBoxesAvailable,
      parkingBoxesNumbers: parkingBoxesAvailable
        ? Number(parkingBoxesNumbers)
        : 0,
      originalPrice: Number(originalPrice),
      dipslayedPrice: Number(displayedPrice),
      pricem2: Number(pricePerMeter),
      priceWithParking: Number(parkingPrice),
      charges: Number(charges),
    };
    const createdPropertyId = localStorage.getItem('createdPropertyId');
    try {
      const res = await http.patch(
        `/v1/properties/${createdPropertyId}`,
        dataToSend
      );
      if (res.status === 200) {
        handleNext();
      }
    } catch (error: any) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    ScrollUp('main');
    if (isEditMode) {
      reset({ ...persistedValues });
      setDefaultValues(persistedValues);
    }
  }, []);

  return (
    <Box
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col mt-12"
      component="form"
    >
      <PropertyDetails
        watch={watch}
        setValue={setValue}
        register={register}
        errors={errors}
      />
      <RoomsDetails register={register} errors={errors} />
      <SurfacesDetails register={register} errors={errors} />
      <ParkingDetails register={register} />
      <PricesDetails register={register} errors={errors} />
      <Box className="flex justify-end pt-6 border-t border-[#E6E8EC] gap-6">
        <PreviousButton />
        <NextButton />
      </Box>
    </Box>
  );
};

export default PropertyStepForm;
