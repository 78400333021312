function extractProperty(node: any, propName: string, result: string[]) {
  if (
    node &&
    !!Object.getOwnPropertyDescriptor(node, propName) &&
    node.categoryEntity === 'Department'
  ) {
    result.push(node[propName]);
  }
  if (node && node.children && node.children.length > 0) {
    node.children.forEach((child: any) =>
      extractProperty(child, propName, result)
    );
  }
  return result;
}

export { extractProperty };
