import { FileNode } from '../../../types/agency';
import { Stack } from '@mui/material';
import { useState } from 'react';
import InfosBox from './components/infosBox';
import VerticalTree from './components/verticalTree';
type Props = {
  treeData: FileNode;
};
const VerticalTreeView = ({ treeData }: Props) => {
  const [infosData, setInfoData] = useState<FileNode>();
  if (!treeData) return null;
  return (
    <Stack className="flex !flex-row w-full gap-4">
      <VerticalTree data={[treeData]} setInfoData={setInfoData} />
      <InfosBox infos={infosData} treeData={treeData} />
    </Stack>
  );
};

export default VerticalTreeView;
