import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import useLocale, { Locale } from '../../hooks/useLocale';
import { useTranslation } from 'react-i18next';

export default function LangSwitcher() {
  const { locale, update, langues } = useLocale();
  const { t } = useTranslation('common');
  const handleChange = (e: SelectChangeEvent<Locale>) =>
    update(e.target.value as Locale);

  return (
    <Box>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
          value={locale}
          onChange={handleChange}
          sx={{
            height: 30,
            fontSize: 12,
          }}
        >
          {langues.map((langue, i) => (
            <MenuItem value={Object.keys(langue).join()} key={i}>
              {t(Object.values(langue).join())}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
