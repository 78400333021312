import { useTranslation } from 'react-i18next';
import TabGridTag from '../../../../features/tags/tabGridTag';
import { Box, Typography } from '@mui/material';
import BedroomIcon from '../../../../features/icons/bedroomIcon';
import BathroomIcon from '../../../../features/icons/bathroomIcon';
import SquareIcon from '../../../../features/icons/squareIcon';
import { useMemo } from 'react';
import useLocale from '../../../../hooks/useLocale';
import HomeIcon from '../../../../features/icons/homeIcon';
import { convertDipslayedPricePrice } from '../../utils';
import FieldValueToDisplay from '../../../../components/common/field/fieldValueToDisplay';
import BalconyIcon from '../../../../features/icons/balconyIcon';
import FloorIcon from '../../../../features/icons/floorIcon';
import GardenSurfIcon from '../../../../features/icons/gardenSurfIcon';
import LandSurfIcon from '../../../../features/icons/landSurfIcon';
import TerraceIcon from '../../../../features/icons/terraceIcon';
import UsefulAreaIcon from '../../../../features/icons/usefulAreaIcon';
import TerraceSurfIcon from '../../../../features/icons/terraceSurfIcon';
import ParkingIcon from '../../../../features/icons/parkingIcon';

type Props = {
  data: any;
  firstImg?: string;
};

function PropertyDetails({ data, firstImg }: Props) {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const {
    dipslayedPrice,
    rooms,
    bedrooms,
    bathrooms,
    livingArea,
    balcony,
    floor,
    gardenSurface,
    landSurface,
    terrace,
    terraceSurface,
    usefulArea,
    translations,
    department,
    parkingNumbers,
    parkingAvailable,
    parkingBoxesAvailable,
    parkingBoxesNumbers,
  } = data;

  const propertyDescription = useMemo(() => {
    const translationDescrptArray = translations['hydra:member'];
    const propertyDesc = translationDescrptArray.find(
      (infos: any) => infos.locale === locale
    );
    if (propertyDesc) {
      return propertyDesc;
    } else {
      return { title: '', description: '' };
    }
  }, [translations, locale]);

  const { title, description } = propertyDescription;

  return (
    <Box
      sx={{ color: 'text.primary' }}
      className="w-full bg-[#FFFFFF] rounded border p-8 flex flex-col"
    >
      <Box className="w-full relative">
        {firstImg && (
          <img src={firstImg} alt="d" className="w-full object-cover " />
        )}
        {department && department.service && (
          <TabGridTag
            textContent={department.service}
            className="absolute top-5 left-5 bg-[#3446B9] border-0 "
            color="white"
            fontSize="14px"
          />
        )}
      </Box>
      <Box className="grid grid-cols-3 justify-start items-start my-4 w-full">
        <Typography variant="h2" className="col-span-2 text-lg font-medium">
          {`${title}`}
        </Typography>
        <Box className="col-span-1 flex flex-col border border-[#3446B9] rounded md:ml-12 p-2 ">
          <Box component="span" className="text-[#3446B9]/70 text-sm">
            {t('propertiesPage:price')}
          </Box>
          <Box component="span" className="text-[#3446B9] ">
            {`CHF ${
              dipslayedPrice
                ? convertDipslayedPricePrice(dipslayedPrice.toString()) + '.-'
                : '-'
            }`}
          </Box>
        </Box>
      </Box>
      <Box className="relative w-full flex flex-col ">
        <p className="text-base font-normal text-justify my-2 property_details">
          <FieldValueToDisplay fieldValue={description} defaultValue={''} />
        </p>

        <Box className="w-full flex flex-wrap gap-2 mt-8">
          {rooms > 0 && (
            <TabGridTag
              textContent={`${rooms ? rooms : '-'} rooms`}
              className=" bg-[#F5F5F7] border-0  px-2 py-1"
              color="black"
              fontSize="14px"
              minWidth="max-content"
              iconPlacement="start"
              icon={<HomeIcon className="mr-2 col-span-1 " />}
            />
          )}
          {bedrooms > 0 && (
            <TabGridTag
              textContent={`${bedrooms ? bedrooms : '-'} bedrooms`}
              className=" bg-[#F5F5F7] border-0  px-2 py-1"
              color="black"
              fontSize="14px"
              minWidth="max-content"
              iconPlacement="start"
              icon={<BedroomIcon className="mr-2 col-span-1 " />}
            />
          )}
          {bathrooms > 0 && (
            <TabGridTag
              textContent={`${bathrooms ? bathrooms : '-'} bathrooms`}
              className="  bg-[#F5F5F7] border-0 px-2 py-1 "
              color="black"
              fontSize="14px"
              minWidth="max-content"
              iconPlacement="start"
              icon={<BathroomIcon className="mr-2 col-span-1 " />}
            />
          )}
          {livingArea > 0 && (
            <TabGridTag
              textContent={`${livingArea ? livingArea : '-'} Sq Ft.`}
              className="  bg-[#F5F5F7] border-0 px-2 py-1 "
              color="black"
              fontSize="14px"
              minWidth="max-content"
              iconPlacement="start"
              icon={<SquareIcon className="mr-2 col-span-1 " />}
            />
          )}
          {balcony > 0 && (
            <TabGridTag
              textContent={`${balcony ? balcony : '-'} balconies`}
              className=" bg-[#F5F5F7] border-0  px-2 py-1"
              color="black"
              fontSize="14px"
              minWidth="max-content"
              iconPlacement="start"
              icon={<BalconyIcon className="mr-2 col-span-1 " />}
            />
          )}
          {floor > 0 && (
            <TabGridTag
              textContent={`${floor ? floor : '-'} floors`}
              className=" bg-[#F5F5F7] border-0  px-2 py-1"
              color="black"
              fontSize="14px"
              minWidth="max-content"
              iconPlacement="start"
              icon={<FloorIcon className="mr-2 col-span-1 " />}
            />
          )}
          {gardenSurface > 0 && (
            <TabGridTag
              textContent={`${
                gardenSurface ? gardenSurface : '-'
              } garden surf.`}
              className="  bg-[#F5F5F7] border-0 px-2 py-1 "
              color="black"
              fontSize="14px"
              minWidth="max-content"
              iconPlacement="start"
              icon={<GardenSurfIcon className="mr-2 col-span-1 " />}
            />
          )}
          {landSurface > 0 && (
            <TabGridTag
              textContent={`${landSurface ? landSurface : '-'} land surf.`}
              className="  bg-[#F5F5F7] border-0 px-2 py-1 "
              color="black"
              fontSize="14px"
              minWidth="max-content"
              iconPlacement="start"
              icon={<LandSurfIcon className="mr-2 col-span-1 " />}
            />
          )}
          {parkingAvailable && parkingNumbers > 0 && (
            <TabGridTag
              textContent={`${parkingNumbers ? parkingNumbers : '-'} parkings`}
              className="  bg-[#F5F5F7] border-0 px-2 py-1 "
              color="black"
              fontSize="14px"
              minWidth="max-content"
              iconPlacement="start"
              icon={<ParkingIcon className="mr-2 col-span-1 " />}
            />
          )}
          {parkingBoxesAvailable && parkingBoxesNumbers > 0 && (
            <TabGridTag
              textContent={`${
                parkingBoxesNumbers ? parkingBoxesNumbers : '-'
              } parkings`}
              className="  bg-[#F5F5F7] border-0 px-2 py-1 "
              color="black"
              fontSize="14px"
              minWidth="max-content"
              iconPlacement="start"
              icon={<ParkingIcon className="mr-2 col-span-1 " />}
            />
          )}
          {terrace > 0 && (
            <TabGridTag
              textContent={`${terrace ? terrace : '-'} terraces`}
              className="  bg-[#F5F5F7] border-0 px-2 py-1 "
              color="black"
              fontSize="14px"
              iconPlacement="start"
              icon={<TerraceIcon className="mr-2 col-span-1 " />}
            />
          )}
          {usefulArea > 0 && (
            <TabGridTag
              textContent={`${usefulArea ? usefulArea : '-'} land surf.`}
              className="  bg-[#F5F5F7] border-0 px-2 py-1 "
              color="black"
              fontSize="14px"
              minWidth="max-content"
              iconPlacement="start"
              icon={<UsefulAreaIcon className="mr-2 col-span-1 " />}
            />
          )}
          {terraceSurface > 0 && (
            <TabGridTag
              textContent={`${
                terraceSurface ? terraceSurface : '-'
              } terrace surf.`}
              className="  bg-[#F5F5F7] border-0 px-2 py-1 "
              color="black"
              fontSize="14px"
              iconPlacement="start"
              minWidth="max-content"
              icon={<TerraceSurfIcon className="mr-2 col-span-1 " />}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default PropertyDetails;
