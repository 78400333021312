const getuserRole = (row: any) => {
  const { company, filial, agency, department } = row;
  if (department) {
    return <span>4</span>;
  } else if (agency && !department) {
    return <span>3</span>;
  } else if (filial && !agency && !department) {
    return <span>2</span>;
  } else if (company && !filial && !agency && !department) {
    return <span>1</span>;
  } else {
    return <span></span>;
  }
};

const getuserAgency = (row: any) => {
  const { company, filial, agency, department } = row;
  if (department) {
    return <span className="truncate">{department.name}</span>;
  } else if (agency && !department) {
    return <span className="truncate">{agency.name}</span>;
  } else if (filial && !agency && !department) {
    return <span className="truncate">{filial.name}</span>;
  } else if (company && !filial && !agency && !department) {
    return <span className="truncate">{company.name}</span>;
  } else {
    return <span></span>;
  }
};

export { getuserRole, getuserAgency };
