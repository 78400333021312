import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import useStore from '../../store';
import { ValidationSchema } from '../../../../../../contexts/stepperContext';
import useSWR from 'swr';
import { useEffect, useState } from 'react';
import { http } from '../../../../../../libs/axios';
import { ScrollUp } from '../../../../../../helpers/utils/scroolUp';
import { removeDuplicates } from './utils';
import Neighborhood from './components/neighborhood';
import OutsideConveniences from './components/outside';
import PreviousButton from '../../form/previousButton';
import NextButton from '../../form/nextButton';

const Conveniences = () => {
  const handleNext = useStore((state) => state.handleNext);
  const [environmentOptions, setEnvironmentOptions] = useState([]);
  const [transportOptions, setTransportOptions] = useState([]);
  const [storesOptions, setStoresOptions] = useState([]);
  const [schoolingOptions, setSchoolingOptions] = useState([]);
  const [sportsOptions, setSportsOptions] = useState([]);
  const [leisureOptions, setLeisureOptions] = useState([]);
  const [outsideOptions, setOutsideOptions] = useState([]);
  const [selectedIdsToSend, setSelectedIdsToSend] = useState([]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<ValidationSchema>({
    // resolver: yupResolver(ConveniencesInfosStep),
  });

  const onSubmit = async () => {
    const dataToSend = {
      conveniences: removeDuplicates(selectedIdsToSend),
    };
    const createdPropertyId = localStorage.getItem('createdPropertyId');
    try {
      const res = await http.patch(
        `/v1/properties/${createdPropertyId}`,
        dataToSend
      );
      if (res.status === 200) {
        handleNext();
      }
    } catch (error: any) {
      console.log('error', error);
    }
  };
  const { data: conveniencesOptions } = useSWR(`/conveniences`);

  useEffect(() => {
    if (conveniencesOptions) {
      const envOptions = conveniencesOptions[0].choices;
      const transOptions = conveniencesOptions[1].choices;
      const storeOptions = conveniencesOptions[2].choices;
      const schoolOptions = conveniencesOptions[3].choices;
      const sportOptions = conveniencesOptions[4].choices;
      const leisuresOptions = conveniencesOptions[5].choices;
      const outsideOptions = conveniencesOptions[6].choices;

      for (let i = 0; i < conveniencesOptions.length; i++) {
        conveniencesOptions[i].choices.forEach((element: any) => {
          element.value = element.name;
        });
      }

      setEnvironmentOptions(envOptions);
      setTransportOptions(transOptions);
      setStoresOptions(storeOptions);
      setSchoolingOptions(schoolOptions);
      setSportsOptions(sportOptions);
      setLeisureOptions(leisuresOptions);
      setOutsideOptions(outsideOptions);
    }
  }, [conveniencesOptions]);
  // scroll up
  useEffect(() => {
    ScrollUp('main');
  }, []);

  return (
    <Box
      className="flex flex-col mt-12"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Neighborhood
        register={register}
        watch={watch}
        setValue={setValue}
        errors={errors}
        environmentOptions={environmentOptions}
        transportOptions={transportOptions}
        storesOptions={storesOptions}
        schoolingOptions={schoolingOptions}
        sportsOptions={sportsOptions}
        leisureOptions={leisureOptions}
        setSelectedIdsToSend={setSelectedIdsToSend}
      />

      <OutsideConveniences
        register={register}
        watch={watch}
        setValue={setValue}
        setSelectedIdsToSend={setSelectedIdsToSend}
        errors={errors}
        outsideOptions={outsideOptions}
      />
      <Box className="flex justify-end pt-6 border-t border-[#E6E8EC] gap-6">
        <PreviousButton />
        <NextButton />
      </Box>
    </Box>
  );
};

export default Conveniences;
