import { mutate } from 'swr';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import { GridColumns, GridValidRowModel } from '@mui/x-data-grid';
import { Avatar, Box, Switch, Tooltip } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import TabGridTag from '../../../features/tags/tabGridTag';
import { http } from '../../../libs/axios';
import { getuserAgency, getuserRole } from '../../../pages/users/utils';
import { captureException } from '@sentry/react';

const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
  try {
    const res = await http.patch(`/users/${event.target.id}`, {
      enabled: event.target.checked,
    });
    const { data, status } = res;
    if (status === 200) {
      const getUserApiRequirment = JSON.parse(
        localStorage.getItem('page_userItemPerPage') as string
      );
      mutate(
        `/users?page=${getUserApiRequirment.page}&itemsPerPage=${getUserApiRequirment.itemsPerPage}`
      );
      if (data.enabled) {
        toast.success(i18next.t(`alerts:user_activate`) as string);
      } else {
        toast.success(i18next.t(`alerts:user_desactivate`) as string);
      }
    } else {
      toast.warn(i18next.t(`alerts:user_not_activate`) as string);
    }
  } catch (event: any) {
    captureException(event);
    if (event.response.status === 465) {
      toast.warning(event.response.data.message as string);
    } else {
      toast.warn(i18next.t(`alerts:user_not_activate`) as string);
    }
  }
};

const isLarge = localStorage.getItem('isLarge');
const isMacLarge = localStorage.getItem('isMacLarge');
const isMediumLarge = localStorage.getItem('isMediumLarge');
// const isMedium = localStorage.getItem('isMedium');

export const usersListingConfig: GridColumns<GridValidRowModel> = [
  {
    field: 'avatar',
    headerName: 'Avatar',
    headerClassName: '!bg-white',
    width: 70,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params) => <Avatar src={params.row.avatar} />,
  },
  {
    field: 'roles',
    headerName: 'Role',
    width: 130,
    renderCell: (params) => {
      const {
        row: { isSuperAdmin },
      } = params;
      return (
        <Box className="max-w-full flex-wrap flex  text-xm">
          {isSuperAdmin ? (
            <TabGridTag
              key={'superAdmin'}
              textContent={'Super admin'}
              fontSize="14px"
              className="m-0"
              color="#ff0000"
            />
          ) : params.value.length === 0 ? (
            <TabGridTag
              key={'unassigned'}
              textContent={'Unassigned'}
              fontSize="14px"
              color="#1D1D1F"
            />
          ) : (
            params.value.map((role: string) => (
              <TabGridTag
                key={role + Math.random()}
                textContent={
                  role.toLocaleLowerCase().includes('admin') ? 'Admin' : 'Agent'
                }
                fontSize="14px"
                color={
                  role.toLocaleLowerCase().includes('admin')
                    ? '#ED6C02'
                    : '#4CAF50'
                }
              />
            ))
          )}
        </Box>
      );
    },
  },
  {
    field: 'firstname',
    headerName: 'Firstname',
    width:
      isLarge === 'true'
        ? 250
        : isMacLarge === 'true'
        ? 251
        : isMediumLarge === 'true'
        ? 200
        : 150,
    renderCell: (params) => (
      <Tooltip title={params.row.firstname} arrow placement="top">
        <span>{params.row.firstname}</span>
      </Tooltip>
    ),
  },
  {
    field: 'lastname',
    headerName: 'Lastname',
    width:
      isLarge === 'true'
        ? 250
        : isMacLarge === 'true'
        ? 251
        : isMediumLarge === 'true'
        ? 200
        : 150,
    renderCell: (params) => (
      <Tooltip title={params.row.lastname} arrow placement="top">
        <span>{params.row.lastname}</span>
      </Tooltip>
    ),
  },
  {
    field: 'email',
    headerName: 'Email',
    width: isLarge === 'true' ? 300 : 250,
    renderCell: (params) => {
      return (
        <Tooltip title={params.value} arrow placement="top">
          <a
            className="text-[#3446B9] underline"
            href={`mailto:${params.value}`}
          >
            {params.value}
          </a>
        </Tooltip>
      );
    },
  },
  {
    field: 'phone',
    headerName: 'Phone',
    width:
      isLarge === 'true'
        ? 182
        : isMacLarge === 'true'
        ? 155
        : isMediumLarge === 'true'
        ? 150
        : 130,
  },
  {
    field: 'level',
    headerName: 'Level',
    width: 80,
    renderCell: ({ row }: any) => {
      return (
        <Box className="w-3/4 flex justify-center items-center  ">
          {getuserRole(row)}
        </Box>
      );
    },
  },
  {
    field: 'belong to',
    headerName: 'Belong to',
    width:
      isLarge === 'true'
        ? 150
        : isMacLarge === 'true'
        ? 150
        : isMediumLarge === 'true'
        ? 146
        : 120,
    renderCell: ({ row }: any) => {
      return (
        <Tooltip title={getuserAgency(row)} arrow placement="top">
          {getuserAgency(row)}
        </Tooltip>
      );
    },
  },
  {
    field: 'enabled',
    disableColumnMenu: true,
    sortable: false,
    headerName: 'Activation',
    width: isLarge === 'true' ? 120 : 89,
    renderCell: ({ row }: any) => (
      <Switch
        id={row.id}
        disabled={row.isSuperAdmin}
        checked={row.enabled}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    ),
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: isLarge === 'true' ? 100 : 75,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (value) => {
      return (
        <Box className="w-full flex justify-center items-center  ">
          <Link to={`/users/${value.id}`}>
            <Tooltip title="user details" arrow placement="top">
              <span>
                <VisibilityOutlinedIcon
                  color="primary"
                  className="w-5 h-5 cursor-pointer"
                />
              </span>
            </Tooltip>
          </Link>
        </Box>
      );
    },
  },
];
export const usersListingConfigForAgent: GridColumns<GridValidRowModel> = [
  {
    field: 'avatar',
    headerName: 'Avatar',
    width: 70,
    headerClassName: '!bg-white',
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params) => <Avatar src={params.row.avatar} />,
  },
  {
    field: 'roles',
    headerName: 'Role',
    width: 130,
    renderCell: (params) => {
      const {
        row: { isSuperAdmin },
      } = params;
      return (
        <Box className="max-w-full flex-wrap flex  text-xm">
          {isSuperAdmin ? (
            <TabGridTag
              key={'superAdmin'}
              textContent={'Super admin'}
              fontSize="14px"
              className="m-0"
              color="#ff0000"
            />
          ) : (
            params.value.map((role: string) => (
              <TabGridTag
                key={role + Math.random()}
                textContent={
                  role.toLocaleLowerCase().includes('admin') ? 'Admin' : 'Agent'
                }
                fontSize="14px"
                color={
                  role.toLocaleLowerCase().includes('admin')
                    ? '#ED6C02'
                    : '#4CAF50'
                }
              />
            ))
          )}
        </Box>
      );
    },
  },
  {
    field: 'firstname',
    headerName: 'Firstname',
    width: isLarge === 'true' ? 300 : isMediumLarge === 'true' ? 243 : 171,
    renderCell: (params) => (
      <Tooltip title={params.row.firstname} arrow placement="top">
        <span>{params.row.firstname}</span>
      </Tooltip>
    ),
  },
  {
    field: 'lastname',
    headerName: 'Lastname',
    width: isLarge === 'true' ? 300 : isMediumLarge === 'true' ? 243 : 171,
    renderCell: (params) => (
      <Tooltip title={params.row.firstname} arrow placement="top">
        <span>{params.row.lastname}</span>
      </Tooltip>
    ),
  },
  {
    field: 'email',
    headerName: 'Email',
    width: isLarge === 'true' ? 300 : isMediumLarge === 'true' ? 250 : 250,

    renderCell: (params) => {
      return (
        <Tooltip title={params.value} arrow placement="top">
          <a
            className="text-[#3446B9] underline"
            href={`mailto:${params.value}`}
          >
            {params.value}
          </a>
        </Tooltip>
      );
    },
  },
  {
    field: 'phone',
    headerName: 'Phone',

    width: isLarge === 'true' ? 300 : 200,
  },
  {
    field: 'level',
    headerName: 'Level',

    renderCell: ({ row }: any) => {
      return getuserRole(row);
    },
  },
  {
    field: 'belong to',
    headerName: 'Belong to',
    width: isLarge === 'true' ? 132 : 152,
    renderCell: ({ row }: any) => {
      return (
        <Tooltip title={getuserAgency(row)} arrow placement="top">
          {getuserAgency(row)}
        </Tooltip>
      );
    },
  },
];
