import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EditIcon from '../../../features/icons/editIcon';
import ShareIcon from '../../../features/icons/shareIcon';
import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import useLocale from '../../../hooks/useLocale';
import { getCountry } from '../../agencies/tree/utils';
import { countries } from '../../../const/countries';

type Props = {
  data: any;
};

function SinglePropertyHeader({ data }: Props) {
  const { t } = useTranslation('usersPage');
  const {
    address: { zipCode },
  } = data;

  const { locale } = useLocale();
  const propertyAddress = useMemo(() => {
    // console.log('we chage the local to ', locale, zipCode);
    if (data && data.address && data.address.translations) {
      const { translations } = data.address;
      const adressTranslationArray = translations['hydra:member'];
      // console.log('adressTranslationArray', adressTranslationArray);
      const newAdress = adressTranslationArray.find(
        (address: any) => address.locale === locale
      );
      // transform the right country
      const propertyCountry = newAdress?.country || null;
      let fullNameCountry = '';

      if (propertyCountry) {
        fullNameCountry = getCountry(countries, propertyCountry)[locale];
      }
      return {
        fullNameCountry,
        ...newAdress,
      };
    }
  }, [locale, data]);

  const { city, fullNameCountry, adresse } = propertyAddress;
  return (
    <Box className="w-full flex flex-col mb-2">
      <Box className="col-span-2 flex mb-4  ">
        <Link
          to="/properties"
          className="px-4 py-1 rounded border border-gray-300"
        >
          <KeyboardBackspaceIcon fontSize="small" />
          <span className="ml-2 text-sm">{t('usersPage:Back_to_list')}</span>
        </Link>
      </Box>
      <Box className="w-full pb-4 grid grid-cols-4 ">
        <Box className="col-span-2 flex flex-col ">
          <Typography variant="h2" className="text-3xl mb-2 ">
            {t('propertiesPage:property_details')}
          </Typography>

          <Typography className=" col-span-1 text-base text-gray-400">
            {adresse && `${adresse}, `}
            {city && `${city}, `}
            {zipCode && city && `${zipCode}, `}
            {fullNameCountry && `${fullNameCountry}`}
          </Typography>
        </Box>
        <Box className="col-span-2 flex items-center justify-end py-4">
          <Box className="px-4 py-2 rounded border border-gray-300 cursor-pointer flex items-center">
            <ShareIcon />
            <Box component="span" className="ml-3 text-sm">
              {t('Share')}
            </Box>
          </Box>
          <Box className="px-4 py-2 rounded bg-[#3446B9] ml-3 cursor-pointer flex items-center">
            <EditIcon color="#FFFFFF" />
            <Box component="span" className="ml-3 text-sm text-white">
              {t('Edit_info')}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SinglePropertyHeader;
