import { useEffect, useState } from 'react';
import { Box, Button, Snackbar, Tooltip, Typography } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { captureException } from '@sentry/react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-toastify';
import { countries } from '../../const/countries';
import { AgencyDrawerContent } from '../../types/agency';
import { useDragAndDropUsers } from '../../hooks/useDragAndDropUsers';
import useLocale from '../../hooks/useLocale';
import { getCountry } from './tree/utils';
import GroupAvatars from './verticalTree/components/avatarGroup';
import { userItem } from './components/userItem';
import ActionBtnDataGrid from '../../features/icons/actionBtnDataGrid';
import { useTranslation } from 'react-i18next';
import { http } from '../../libs/axios';
import { mutate } from 'swr';

type Props = {
  content: AgencyDrawerContent;
  setDrawerContent: any;
  basedUrl: string;
  firstNodeId: number;
};

export const AgencyInfos = ({
  content,
  setDrawerContent,
  basedUrl,
  firstNodeId,
}: Props) => {
  const { locale } = useLocale();
  const [infosCountry, setInfosCountry] = useState('');
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('usersPage');
  const {
    currentNode,
    currentNode: {
      country,
      location,
      email,
      phone,
      website,
      cp,
      categoryEntity,
      city,
      users,
      agencyKey,
      name,
      service,
      id,
    },
  } = content;

  const { updateUser } = useDragAndDropUsers();

  useEffect(() => {
    users && users?.length ? updateUser(users as userItem[]) : [];
  }, [currentNode]);

  useEffect(() => {
    if (country) {
      const newCountry = getCountry(countries, country)[locale];
      setInfosCountry(newCountry);
    }
  }, [locale]);

  const handleCopyClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(agencyKey!);
  };

  const getApiKey = () => {
    switch (categoryEntity) {
      case 'Company':
        return `/companies/${id}`;
      case 'Filial':
        return `/filials/${id}`;
      case 'Agency':
        return `/agencies/${id}`;
      case 'Department':
        return `/departments/${id}`;
      default:
        return ``;
    }
  };

  const deleteAgencyHandler = async () => {
    try {
      await http.delete(getApiKey());
      toast.success(
        t(`alerts:agency.success.delete_${categoryEntity}`) as string
      );
      mutate(basedUrl);
    } catch (e: any) {
      captureException(e);
      if (
        e.response.status === 460 ||
        e.response.status === 461 ||
        e.response.status === 462
      ) {
        toast.warning(e.response.data.message as string);
      } else {
        toast.error(
          t(`alerts:agency.error.${categoryEntity}_not_deleted`) as string
        );
      }
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
        {categoryEntity === 'Filial' && (
          <>
            {country && (
              <div
                className={`react-tel-input flag ${country.toLocaleLowerCase()}`}
              ></div>
            )}
            <Typography variant="h5" sx={{ color: '#1D1D1F' }}>
              {infosCountry}
            </Typography>{' '}
          </>
        )}
        {categoryEntity === 'Agency' && city && (
          <Typography variant="h6" className="text-sm text-gray-400">
            {city || ''}
          </Typography>
        )}
      </Box>
      {categoryEntity === 'Department' && service && (
        <Box sx={{ marginBottom: '32px' }}>
          <Typography sx={{ color: '#1D1D1F', fontSize: '12px', opacity: 0.5 }}>
            Service
          </Typography>
          <Typography sx={{ fontSize: '16px' }}>{service}</Typography>
        </Box>
      )}
      {name && (
        <Box sx={{ marginBottom: '32px' }}>
          <Typography sx={{ color: '#1D1D1F', fontSize: '12px', opacity: 0.5 }}>
            Name
          </Typography>
          <Typography sx={{ fontSize: '16px' }}>{name}</Typography>
        </Box>
      )}
      {location && (
        <Box sx={{ marginBottom: '32px' }}>
          <Typography sx={{ color: '#1D1D1F', fontSize: '12px', opacity: 0.5 }}>
            Location / address
          </Typography>
          <Typography sx={{ fontSize: '16px' }}>{location}</Typography>
        </Box>
      )}
      {cp && (
        <Box sx={{ marginBottom: '32px' }}>
          <Typography sx={{ color: '#1D1D1F', fontSize: '12px', opacity: 0.5 }}>
            Zip code
          </Typography>
          <Typography sx={{ fontSize: '16px' }}>{cp}</Typography>
        </Box>
      )}
      {email && (
        <Box sx={{ marginBottom: '32px' }}>
          <Typography sx={{ color: '#1D1D1F', fontSize: '12px', opacity: 0.5 }}>
            Company Email
          </Typography>
          <Typography
            className="underline cursor-pointer"
            sx={{ fontSize: '16px' }}
          >
            {email}
          </Typography>
        </Box>
      )}
      {phone && (
        <Box sx={{ marginBottom: '32px' }}>
          <Typography sx={{ color: '#1D1D1F', fontSize: '12px', opacity: 0.5 }}>
            Phone
          </Typography>
          <Typography sx={{ fontSize: '16px' }}>{phone}</Typography>
        </Box>
      )}
      {website && (
        <Box sx={{ marginBottom: '32px' }}>
          <Typography sx={{ color: '#1D1D1F', fontSize: '12px', opacity: 0.5 }}>
            Website link
          </Typography>
          <Typography
            className="underline cursor-pointer"
            sx={{ fontSize: '16px' }}
          >
            {website}
          </Typography>
        </Box>
      )}
      {agencyKey && (
        <Box sx={{ marginBottom: '32px' }}>
          <Typography sx={{ color: '#1D1D1F', fontSize: '12px', opacity: 0.5 }}>
            Agency key
          </Typography>
          <Box className="flex items-center">
            <Tooltip title={agencyKey} arrow placement="top">
              <Typography
                className=" cursor-pointer truncate ... w-5/6 !mr-4"
                onClick={handleCopyClick}
                sx={{ fontSize: '16px' }}
              >
                {agencyKey}
              </Typography>
            </Tooltip>
            <Tooltip title="copy" arrow placement="top">
              <span className=" cursor-pointer" onClick={handleCopyClick}>
                <ContentCopyIcon className="!w-4 !h-4" />
              </span>
            </Tooltip>
            <Snackbar
              message="Copied to clibboard"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              autoHideDuration={2000}
              onClose={() => setOpen(false)}
              open={open}
            />
          </Box>
        </Box>
      )}
      <Box className="flex justify-between items-center">
        <Button
          sx={{
            width: '100%',
            border: '1px solid #3446B9',
            color: '#3446B9',
            marginRight: '10px',
          }}
          onClick={() =>
            setDrawerContent({
              type: `formAgenciesEdit-${categoryEntity}`,
              currentNode,
            })
          }
          startIcon={<ActionBtnDataGrid color="#3446B9" />}
        >
          Edit infos
        </Button>
        {firstNodeId !== id && (
          <Button
            sx={{
              width: '100%',
              border: '1px solid #3446B9',
              color: '#F50057',
            }}
            onClick={deleteAgencyHandler}
            startIcon={
              <DeleteOutlineIcon fontSize="small" sx={{ color: '#F50057' }} />
            }
          >
            {t('Remove')}
          </Button>
        )}
      </Box>
      {users?.length ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginTop: '20px',
          }}
        >
          <GroupAvatars avatarGroup={users} length={5} />
          <Typography
            className="subtitle"
            sx={{
              cursor: 'pointer',
              fontWeight: '400',
              fontSize: '1em',
              color: '#4b5bc1',
              textDecoration: 'underline',
            }}
            onClick={() =>
              setDrawerContent({
                type: `agencyUsers`,
                currentNode,
              })
            }
          >
            show all users
          </Typography>
        </Box>
      ) : null}
    </>
  );
};

export default AgencyInfos;
