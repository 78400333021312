import { Box, TextField, Typography } from '@mui/material';
import { UseFormRegister } from 'react-hook-form';

type Props = {
  register: UseFormRegister<any>;
  title: string;
  name: string;
};

const MediaLinkBlock = ({ register, title, name }: Props) => {
  return (
    <Box className="flex gap-20 mb-16">
      <Typography className="stepTitle w-2/5">{title}</Typography>

      <Box className="w-full flex flex-col gap-8">
        <TextField {...register(name)} variant="outlined" />
      </Box>
    </Box>
  );
};
export default MediaLinkBlock;
