import { Box } from '@mui/material';

type Props = {
  status: 'available' | 'active' | 'reserved' | 'sold';
  value: string | number;
};

function PlanTag({ status, value }: Props) {
  const colors = {
    available: '#4CAF50',
    active: '#F50057',
    reserved: '#3446B9',
    sold: '#E6E8EC',
  };
  return (
    <Box className=" border p-4 border-b-0 last:border-b max-w-[210px]  ">
      <Box
        sx={{ backgroundColor: 'background.default', color: 'text.primary' }}
        className=" flex gap-4 items-center"
      >
        <Box
          component="span"
          style={{ background: colors[status] }}
          className={`w-1.5 h-1.5 rounded `}
        ></Box>
        <p className="capitalize ">
          Apt {status}: {value}
        </p>
      </Box>
    </Box>
  );
}

export default PlanTag;
