import { Box, InputAdornment, Typography } from '@mui/material';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

import InputFormField from '../../../../../../../components/form/inputFormField';

import User from '../../../../../../../types/user';
import AutocompleteSelect from '../../../form/autoCompleteSelect';
import { useGeneralInfoStore } from '../../../store';
import { initialValueT } from '../../../store/types';

type Props = {
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  clearErrors: any;
  setValue?: any;
  brokersList: User[];
  defaultValues: initialValueT;
  watch: any;
};

const BrokerData = ({
  register,
  errors,
  setValue,
  defaultValues,
  brokersList,
  clearErrors,
  watch,
}: Props) => {
  const { broker, intermediateBroker } = defaultValues;
  const updateInfo = useGeneralInfoStore((state) => state.updateInfo);
  return (
    <Box className="grid grid-cols-[1fr_5fr] my-10" gap="80px">
      <Typography className="stepTitle">Brokers and commission</Typography>
      <Box width="100%" display="flex" flexDirection="column" gap="40px">
        <Box className="grid grid-cols-2 " gap="48px">
          <AutocompleteSelect
            defaultValue={{
              label: broker.toString(),
              value: broker.toString(),
            }}
            disabled={watch('agency')?.length > 0 ? false : true}
            isRequired
            clearErrors={clearErrors}
            isStepper
            setValue={setValue}
            options={brokersList}
            label="Broker responsible"
            name="broker"
            error={errors['broker']}
            className="max-w-[416px] h-12"
            register={register}
          />
          <InputFormField
            label="Commission for the broker in charge"
            type="number"
            placeholder="Commission for the broker in charge"
            inputProps={{
              step: '0.01',
            }}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
            {...register('brokerRate', {
              onChange: (e) =>
                updateInfo({
                  name: 'brokerRate',
                  value: String(e.target.value),
                }),
            })}
          />
        </Box>
        <Box className="grid grid-cols-2" gap="48px">
          <AutocompleteSelect
            defaultValue={{
              label: intermediateBroker,
              value: intermediateBroker,
            }}
            disabled={watch('agency')?.length > 0 ? false : true}
            clearErrors={clearErrors}
            isStepper
            options={brokersList}
            setValue={setValue}
            label="Intermediate broker responsible"
            name="intermediateBroker"
            error={errors[' ']}
            className="w-full"
            register={register}
          />
          <InputFormField
            label="Commission for the intermediate broker in charge"
            placeholder="Enter Commission for the intermediate broker in charge"
            type="number"
            inputProps={{
              step: '0.01',
            }}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
            {...register('intermediateBrokerRate', {
              onChange: (e) =>
                updateInfo({
                  name: 'intermediateBrokerRate',
                  value: String(e.target.value),
                }),
            })}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default BrokerData;
