import { Stack, SxProps } from '@mui/material';
import { HTMLAttributes, ReactElement } from 'react';

export type CardProps = HTMLAttributes<HTMLDivElement> & {
  children: ReactElement | ReactElement[] | string;
  sx?: SxProps;
};

const Card = ({ children, ...rest }: CardProps) => (
  <Stack direction="column" className="border rounded bg-white" {...rest}>
    {children}
  </Stack>
);

const Header = ({ children, ...rest }: CardProps) => (
  <Stack
    direction="row"
    className="  flex justify-between items-center py-2 px-4 border-b bg-gray-100"
    {...rest}
  >
    {children}
  </Stack>
);

const Footer = ({ children, ...rest }: CardProps) => (
  <Stack
    direction="row"
    className="flex justify-between items-center py-2 px-4 bg-gray-100"
    {...rest}
  >
    {children}
  </Stack>
);

const Body = ({ children, ...rest }: CardProps) => (
  <Stack direction="column" className="py-3 px-4" {...rest}>
    {children}
  </Stack>
);

Card.Body = Body;

Card.Header = Header;

Card.Footer = Footer;

export default Card;
