import { Box, Switch, Typography } from '@mui/material';

import { FieldErrors, UseFormRegister } from 'react-hook-form';
import InputFormField from '../../../../../../../components/form/inputFormField';
import { useGeneralInfoStore } from '../../../store';

type Props = {
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  watch: any;
  setValue: any;
};
const propertyDetailsInfos = [
  {
    id: 0,
    label: 'Floor',
  },
  {
    id: 1,
    label: 'Balcony',
  },
  {
    id: 2,
    label: 'Terrace',
  },
];
const propertyDetailsChecks = [
  {
    id: 3,
    label: 'Lift',
  },
  {
    id: 4,
    label: 'Pool',
  },
  {
    id: 5,
    label: 'Garden',
  },
];
const PropertyDetails = ({ register, errors, watch, setValue }: Props) => {
  const updateInfo = useGeneralInfoStore((state) => state.updateInfo);

  console.log('register', register, errors);
  return (
    <Box className="grid grid-cols-[2fr_4fr]">
      <Typography className="stepTitle">Property Details</Typography>
      <Box width="100%" display="flex" flexDirection="column" gap="48px">
        <Box width="100%" className="grid grid-cols-3" gap="48px">
          {propertyDetailsInfos.map(({ id, label }) => {
            return (
              <InputFormField
                key={label + id}
                label={label}
                inputProps={{
                  step: '0.01',
                }}
                // placeholder={`Enter ${label}`}
                id="outlined-start-adornment"
                type="number"
                value={watch(label)}
                {...register(label.toLowerCase(), {
                  onChange: (e) => {
                    setValue(label, e.target.value);
                    updateInfo({
                      name: label.toLowerCase(),
                      value: String(e.target.value),
                    });
                  },
                })}
              />
            );
          })}
        </Box>
        <Box width="100%" className="grid grid-cols-3" gap={3}>
          {propertyDetailsChecks.map(({ id, label }) => (
            <div className="flex items-center gap-4" key={label + id}>
              <p>{label}</p>
              <Switch {...register(label.toLowerCase())} />
            </div>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default PropertyDetails;
