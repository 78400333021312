import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { forwardRef } from 'react';
type Props = TextFieldProps & {
  className?: string;
  type?: 'text' | 'number' | 'email';
  isRequired?: boolean;
  width?: string;
};

const InputFormField = forwardRef<HTMLInputElement, Props>(
  ({ label, className, width, isRequired = false, type, ...props }, ref) => (
    <Box
      className={width ? width : 'max-w-[416px]'}
      sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
    >
      {label && (
        <InputLabel className="!text-xs capitalize">
          {label}
          {isRequired && <span className="text-red-500">*</span>}
        </InputLabel>
      )}
      <TextField
        type={type}
        className={className}
        variant="outlined"
        ref={ref}
        {...props}
      />
    </Box>
  )
);

InputFormField.displayName = 'InputFormField';

export default InputFormField;
