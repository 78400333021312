import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import useStore, { initialGeneralInfos } from './store';

export default function Success() {
  const reset = useStore((state) => state.reset);

  return (
    <Box className="flex justify-center items-center flex-col py-6">
      <Typography
        align="center"
        sx={{ py: 4, fontWeight: '400', fontSize: '24px' }}
      >
        Your property has been added successfully !!
      </Typography>
      <Link to="/properties">
        <Button
          onClick={() => {
            localStorage.removeItem('isEditMode');
            localStorage.setItem(
              'general-infos',
              JSON.stringify({ state: initialGeneralInfos })
            );
            localStorage.removeItem('createdPropertyId');
            reset();
          }}
          variant="contained"
          sx={{
            background: 'background.default',
            hover: { background: 'background.default' },
          }}
        >
          back to properties list
        </Button>
      </Link>
    </Box>
  );
}
