export const TableGridXStyling = {
  color: 'text.primary',
  width: '100%',
  height: '100%',
  '& .MuiDataGrid-main ,& .MuiDataGrid-root': {
    position: 'relative',
    border: ' none !important',
  },
  '& .MuiDataGrid-columnHeader': {
    maxWidth: '100% !important',
    paddingLeft: '18px',
    height: '48px !important',
  },
  '& .MuiDataGrid-columnHeader  .MuiDataGrid-menuIcon ': {
    width: 'auto ',
    visibility: 'visible !important',
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: 'transparent',
    height: '48px !important',
    minHeight: '48px !important',
    marginBottom: '30px !important',
    border: `1px solid #E6E8EC`,
  },
  '& .MuiDataGrid-virtualScroller': {
    backgroundColor: 'background.paper',
  },
  '& .MuiDataGrid-columnHeader:first-of-type': {
    backgroundColor: 'transparent',
  },
  '& .MuiDataGrid-columnHeader ': {
    backgroundColor: 'background.paper',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell:first-of-type': {
    borderRight: `1px solid #E6E8EC`,
  },
  '& .MuiDataGrid-renderingZone': {
    maxHeight: 'none !important',
    maxWidth: 'none !important',
  },
  '& .MuiDataGrid-cell': {
    lineHeight: 'unset !important',
    maxHeight: 'none !important',
    whiteSpace: 'normal',
  },
  '& .MuiDataGrid-row': {
    maxHeight: 'none !important',
  },
  '& .MuiDataGrid-toolbarContainer': {
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
    color: 'text.primary',
  },
};
