import { Box, Button } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import useStore from '../store';

const PreviousButton = () => {
  const handlePrev = useStore((state) => state.handlePrev);
  return (
    <Button
      type="submit"
      variant="contained"
      className="flex justify-center items-center w-[104px]  "
      sx={{
        background: '#FFFFFF',
        color: 'black',
        ':hover': { background: 'transparent' },
      }}
      onClick={() => {
        localStorage.setItem('isEditMode', 'true');
        handlePrev();
      }}
    >
      <Box className="flex justify-center items-center" gap="16px">
        <KeyboardBackspaceIcon className=" w-4 h-4" fontSize="small" />
        <p className="capitalize"> Prev</p>
      </Box>
    </Button>
  );
};

export default PreviousButton;
