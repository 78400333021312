import * as Sentry from '@sentry/react';
import { ReactElement } from 'react';
import { isProdEnv } from '../../const';
import ErrorBoundaryAlert from '../alert/errorBoundaryAlert';

type Props = {
  children: ReactElement | ReactElement[];
};

export function SentryErrorBoundary({ children }: Props) {
  return (
    <Sentry.ErrorBoundary
      fallback={<ErrorBoundaryAlert />}
      showDialog={isProdEnv}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
