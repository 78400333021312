import { IconProps } from './locationIcon';

export default function RemoveIcon({ color, className }: IconProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_54_16127)">
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M14 3.5V15H2V3.5h4m8 0h2m-2 0h-4m-10 0h6m0 0V1h4v2.5m-4 0h4M5 7v5m3-5v5m3-5v5"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_54_16127">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
