import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function UsersFilledIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill={color}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 14c0-5.476-8-5.188-8 0h8zM9.398 10.328C11.755 9.363 15 10.512 15 14h-4.5c0-1.6-.734-3.115-1.102-3.672z"
      ></path>
      <circle cx="5" cy="6" r="2.5" fill={color}></circle>
      <circle cx="11.5" cy="6" r="2.5" fill={color}></circle>
    </svg>
  );
}
