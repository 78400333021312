import { GridValidRowModel } from '@mui/x-data-grid';
import SpinnerLoading from '../../../features/spinner/spinnerLoading';
import { Box } from '@mui/material';
import InfoCard from './infoCard';

type PropsT = { data: GridValidRowModel };

function UserInfos({ data }: PropsT) {
  const leftDataToDisplay = ['firstname', 'lastname', 'email', 'address'];

  const rightDataToDisplay = ['company', 'phone', 'mobile'];
  if (!data) return <SpinnerLoading />;

  return (
    <Box
      component="section"
      className="w-full grid grid-cols-1 gap-6 md:grid-cols-2  "
    >
      <InfoCard cardKeys={leftDataToDisplay} data={data} />

      <InfoCard cardKeys={rightDataToDisplay} data={data} />
    </Box>
  );
}

export default UserInfos;
