import { Box } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import useSWR from 'swr';
import ControlledSelectedForm, {
  selectOptionT,
} from '../../../../../../../components/form/controlledSelectedForm';

type Props = {
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<any>;
  setSelectedIdsToSend: Dispatch<SetStateAction<any>>;
};

function OwnersList({
  register,
  errors,
  watch,
  setValue,
  setSelectedIdsToSend,
}: Props) {
  const { data } = useSWR('/owners');

  useEffect(() => {
    if (data) {
      const ownersData: selectOptionT[] = [];
      for (let i = 0; i < data.length; i++) {
        ownersData.push({
          value: data[i].firstname + ' ' + data[i].lastname,
          id: data[i].id,
        });
      }
      setOwnersList(ownersData);
    }
  }, [data]);
  const [ownersList, setOwnersList] = useState<selectOptionT[]>([]);

  return (
    <Box width="35%">
      <ControlledSelectedForm
        watch={watch}
        setSelectedIdsToSend={setSelectedIdsToSend}
        setValue={setValue}
        options={ownersList}
        label="owner"
        isRequired
        name="owner"
        error={errors['owner']}
        register={register}
      />
    </Box>
  );
}

export default OwnersList;
