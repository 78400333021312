import { CardMedia, Stack, Typography } from '@mui/material';

function PromotionPresentation() {
  return (
    <Stack
      sx={{
        padding: '32px',
        display: 'flex',
        gap: '16px',
        backgroundColor: 'background.default',
        color: 'text.primary',
      }}
      className="border rounded"
    >
      <CardMedia
        className="w-[512px] h-[288px]"
        component="iframe"
        image="https://www.youtube.com/embed/_g3IpXPV5cE"
      />
      <Typography variant="h5">Video Presentation</Typography>
    </Stack>
  );
}

export default PromotionPresentation;
