import LocationIcon from '../../../../features/icons/locationIcon';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import useLocale from '../../../../hooks/useLocale';
import { useMemo } from 'react';
import { countries } from '../../../../const/countries';
import { getCountry } from '../../../agencies/tree/utils';
import GoogleMapContainer from '../../../../components/map/googleMapContainer';

type Props = {
  data: any;
};

function PropertyLocation({ data }: Props) {
  const { t } = useTranslation('propertiesPage');
  const {
    address: { zipCode },
  } = data;

  const { locale } = useLocale();
  const propertyAddress = useMemo(() => {
    // console.log('we chage the local to ', locale, zipCode);
    if (data && data.address && data.address.translations) {
      const { translations } = data.address;
      const adressTranslationArray = translations['hydra:member'];
      // console.log('adressTranslationArray', adressTranslationArray);
      const newAdress = adressTranslationArray.find(
        (address: any) => address.locale === locale
      );
      // transform the right country
      const propertyCountry = newAdress?.country || null;
      let fullNameCountry = '';

      if (propertyCountry) {
        fullNameCountry = getCountry(countries, propertyCountry)[locale];
      }
      return {
        fullNameCountry,
        ...newAdress,
      };
    }
  }, [locale, data]);
  const { city, fullNameCountry, adresse } = propertyAddress;

  const fullAddress =
    adresse &&
    city &&
    zipCode &&
    fullNameCountry &&
    `${adresse && adresse}, ${city && city}, ${zipCode && city && zipCode},${
      fullNameCountry && fullNameCountry
    }`;
  return (
    <Stack
      sx={{ color: 'text.primary' }}
      className="w-full rounded border bg-[#FFFFFF] p-8 flex flex-col"
    >
      {/* <Box className="w-full h-full grid aspect-square overflow-hidden">
         <img src="/img/maps.png" alt="d" className="w-full h-full " /> 
      </Box> */}
      <GoogleMapContainer address={fullAddress} />

      <h2 className=" my-4 font-medium text-lg">{t('property_location')}</h2>
      {zipCode && city && (
        <div className="flex items-center ">
          <LocationIcon className="mr-2 col-span-1 " color="#6E6E72" />
          <h3 className="col-span-7 text-sm">
            {adresse && `${adresse}, `}
            {city && `${city}, `}
            {zipCode && city && `${zipCode}, `}
            {fullNameCountry && `${fullNameCountry}`}
          </h3>
        </div>
      )}
    </Stack>
  );
}

export default PropertyLocation;
