import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function UsersOutlinedIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();
  color = color ? color : mode === 'light' ? '#3446B9' : '#3446B9';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 14c0-5.476-8-5.188-8 0h8zM9.398 10.328C11.755 9.363 15 10.512 15 14h-4.5"
      ></path>
      <circle cx="5" cy="6" r="2" stroke={color}></circle>
      <circle cx="11.5" cy="6" r="2" stroke={color}></circle>
    </svg>
  );
}
