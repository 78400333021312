import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/common/privateRoute';
import Dashboard from './pages/index';
import ProfileSettings from './pages/user/profile';
import UsersListingPage from './pages/users/usersListingPage';
import SingleUserPage from './pages/users/singleUserPage';
import SignIn from './pages/auth/signIn';
import ForgetPassword from './pages/auth/forgetPassword';
import PageNotFound from './pages/404/404';
import PromotionsPage from './pages/promotions';
import SinglePromotion from './pages/promotions/singlePromotion';
import SingleProperty from './pages/properties/singleProperty';
import ModifyPassword from './pages/auth/modifyPassword';
import TwoFactorAuthentication from './pages/auth/twoFactorAuth';
import Agencies from './pages/agencies';
import PropertiesPage from './pages/properties';
import Stepper from './pages/properties/stepper';
import { useAuth } from './hooks/useAuth';
import { useEffect } from 'react';
import { useMedia } from 'react-use';
import Contacts from './pages/contacts';
import SingleContactPage from './pages/contacts/singleContactPage';

function Routing() {
  const {
    user: { roles },
  } = useAuth();
  const defaultRoles = [
    'ROLE_ADMIN',
    'ROLE_ADMIN_ASSISTANT',
    'ROLE_ADMIN_AGENT',
    'ROLE_ADMIN_OPERATOR',
  ];
  const isUserAdmin = (defaultRoles: string[], roles: string[]) => {
    for (const item of roles) {
      if (defaultRoles.includes(item)) {
        return true;
      }
    }
    return false;
  };
  const isAdmin = roles && isUserAdmin(defaultRoles, roles);
  // media screens
  const isLarge = useMedia('(min-width: 1920px)');
  isLarge
    ? localStorage.setItem('isLarge', 'true')
    : localStorage.setItem('isLarge', 'false');
  const isMacLarge = useMedia('(min-width: 1790px) and (max-width: 1919px)');
  isMacLarge
    ? localStorage.setItem('isMacLarge', 'true')
    : localStorage.setItem('isMacLarge', 'false');
  const isMediumLarge = useMedia('(min-width: 1680px) and (max-width: 1789px)');

  isMediumLarge
    ? localStorage.setItem('isMediumLarge', 'true')
    : localStorage.setItem('isMediumLarge', 'false');
  const isMedium = useMedia('(min-width: 1441px) and (max-width: 1679px)');

  isMedium
    ? localStorage.setItem('isMedium', 'true')
    : localStorage.setItem('isMedium', 'false');
  //
  useEffect(() => {
    localStorage.setItem('isAdmin', JSON.stringify({ isAdmin }));
  }, [roles]);

  return (
    <Routes>
      <Route path="/" element={<PrivateRoute />}>
        <Route index element={<Dashboard />} />
        <Route path="/profile" element={<ProfileSettings />} />
      </Route>
      {/* USERS PAGE ROUTES  */}

      <Route path="users" element={<PrivateRoute />}>
        <Route index element={<UsersListingPage />} />
        <Route path=":userId" element={<SingleUserPage />} />
      </Route>

      {/* AGENCIES PAGE ROUTES  */}
      {isAdmin && !roles.includes('ROLE_ADMIN_OPERATOR') && (
        <Route path="agencies" element={<PrivateRoute />}>
          <Route index element={<Agencies />} />
        </Route>
      )}
      {/* PROMOTIONS PAGE ROUTES  */}
      <Route path="promotions" element={<PrivateRoute />}>
        <Route index element={<PromotionsPage />} />
        <Route path=":promotionId" element={<SinglePromotion />} />
      </Route>
      {/* PROPERTIES PAGE ROUTES  */}
      <Route path="properties" element={<PrivateRoute />}>
        <Route index element={<PropertiesPage />} />
        <Route path="addProperty" element={<Stepper />} />
        <Route path=":propertyId" element={<SingleProperty />} />
      </Route>
      {/* WEBSITES PAGE ROUTES  */}
      <Route path="websites" element={<PrivateRoute />}>
        <Route index element={<div>Websites</div>} />
      </Route>
      {/* CONTACTS PAGE ROUTES  */}
      <Route path="contacts" element={<PrivateRoute />}>
        <Route index element={<Contacts />} />
        <Route path=":userId" element={<SingleContactPage />} />
      </Route>

      {/* PUBLIC ROUTES  */}
      <Route path="/signin" element={<PrivateRoute fromLoggedUsers />}>
        <Route index element={<SignIn />} />
      </Route>
      <Route path="/forget-password" element={<PrivateRoute fromLoggedUsers />}>
        <Route index element={<ForgetPassword />} />
      </Route>
      <Route
        path="/set-password/:token"
        element={<PrivateRoute fromLoggedUsers />}
      >
        <Route index element={<ModifyPassword type="set" />} />
      </Route>
      <Route
        path="/reset-password/:token"
        element={<PrivateRoute fromLoggedUsers />}
      >
        <Route index element={<ModifyPassword type="reset" />} />
      </Route>
      <Route path="/2fa/:token" element={<PrivateRoute fromLoggedUsers />}>
        <Route element={<TwoFactorAuthentication />} />
      </Route>

      {/* 404 ROUTES  */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default Routing;
