import GoBackTag from '../../features/tags/goBackTag';

function PromotionsPage() {
  return (
    <>
      <GoBackTag link="/promotions/1" />
    </>
  );
}

export default PromotionsPage;
