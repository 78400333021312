import { useEffect, useState } from 'react';
import useSWR from 'swr';
import PropertiesListingHeader from './propertiesListingHeader';
import PropertiesFilter from './propertiesFilter';
import PropertiesListingTable from './propertiesTable';
import IProperty, { IfieldFilter } from '../../../types/property';
import { ApiListing } from '../../../types/api';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { GridMoreVertIcon } from '@mui/x-data-grid';
import { Box } from '@material-ui/core';
import { useMedia } from 'react-use';

// import { useEffect } from 'react';

const advancedOptions: IfieldFilter[] = [
  { title: 'Date', type: 'date' },
  { title: 'Price', type: 'range-number' },
  { title: 'Nombre of rooms', type: 'number' },
  { title: 'Nombre of bedrooms', type: 'number' },
  { title: 'Nombre of Parkings', type: 'number' },
  { title: 'Living area', type: 'number' },
  { title: 'Land surface', type: 'number' },
];

const mainOptions: IfieldFilter[] = [
  { title: 'Reference', type: 'listSearch' },
  { title: 'Title', type: 'listSearch' },
  { title: 'Location / zip code', type: 'listSearch' },
  { title: 'Agent', type: 'listSearch' },
  {
    title: 'Status',
    type: 'list',
    items: [
      { id: 0, value: 'active' },
      { id: 1, value: 'sold' },
      { id: 2, value: 'rent' },
      { id: 3, value: 'reserved' },
      { id: 4, value: 'suspended' },
    ],
  },
  {
    title: 'Category',
    type: 'list',
    items: [
      { id: 0, value: 'Residential' },
      { id: 1, value: 'Commercial' },
    ],
  },
  {
    title: 'Property Type',
    type: 'list',
    items: [],
  },
];

const options = [
  'Envoyer par email',
  'Dupliquer',
  'Modifier le courtier',
  'Archiver',
];
const ITEM_HEIGHT = 48;
function PropertiesListingPage() {
  const isLarge = useMedia('(min-width: 1640px)');

  const [page, setPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(7);
  const [selectedRows, setSelectedRows] = useState<IProperty[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    //MEouseEvent<HTMLElement>
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => setItemsPerPage(isLarge ? 10 : 5), [isLarge]);

  const { data } = useSWR<ApiListing<IProperty[]>>(
    `/v1/properties?page=${page}&itemsPerPage=${itemsPerPage}`
  );

  return (
    <section className="w-full h-full flex flex-col ">
      <PropertiesListingHeader
        nbproperties={data && data['hydra:totalItems']}
      />
      <PropertiesFilter
        mainOptions={mainOptions}
        advancedOptions={advancedOptions}
      />
      {selectedRows.length > 0 && (
        <Box className="mb-4">
          <IconButton
            sx={{
              background: 'rgb(52, 70, 185, 0.9)',
              color: 'white',
              ':hover': { background: 'rgb(52, 70, 185, 1)' },
            }}
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <GridMoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
              },
            }}
          >
            {options.map((option, index) => (
              <MenuItem
                key={`${option}-${index}`}
                selected={option === 'Pyxis'}
                onClick={handleClose}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}

      {data && (
        <PropertiesListingTable
          itemsPerPage={itemsPerPage}
          data={data}
          handlePage={setPage}
          page={page}
          setSelectedRows={(selectedRows: IProperty[]) =>
            setSelectedRows(selectedRows)
          }
        />
      )}
    </section>
  );
}

export default PropertiesListingPage;
