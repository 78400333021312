import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import useStore from '../../store';

import { ValidationSchema } from '../../../../../../contexts/stepperContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { DescriptionsInfosStep } from '../../../../../../libs/yup/propertyValidation';
import useLocale from '../../../../../../hooks/useLocale';
import { http } from '../../../../../../libs/axios';
import 'react-quill/dist/quill.snow.css';
import { ScrollUp } from '../../../../../../helpers/utils/scroolUp';
import { useEffect } from 'react';
import TitleBox from './components/titleBox';
import OverviewBox from './components/overviewBox';
import PreviousButton from '../../form/previousButton';
import NextButton from '../../form/nextButton';

const Descriptions = () => {
  const handleNext = useStore((state) => state.handleNext);
  const { locale: currentLanguage } = useLocale();

  const {
    register,
    handleSubmit,
    control,
    // formState: { errors, isSubmitting },
  } = useForm<ValidationSchema>({
    resolver: yupResolver(DescriptionsInfosStep),
  });
  const onSubmit = async (data: any) => {
    const { title, description } = data;
    const dataToSend = {
      translations: {
        [`${currentLanguage}`]: {
          locale: currentLanguage,
          title,
          description,
        },
      },
    };
    const createdPropertyId = localStorage.getItem('createdPropertyId');

    try {
      const res = await http.patch(
        `/v1/properties/${createdPropertyId}`,
        dataToSend
      );
      if (res.status === 200) {
        handleNext();
      }
    } catch (error: any) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    ScrollUp('main');
  }, []);
  return (
    <Box
      className="flex flex-col mt-12"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <TitleBox register={register} />
      <OverviewBox control={control} />
      <Box className="flex justify-end pt-6 border-t border-[#E6E8EC] gap-6">
        <PreviousButton />
        <NextButton />
      </Box>
    </Box>
  );
};

export default Descriptions;
