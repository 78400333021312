import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import useStore from '../../store';
import { ValidationSchema } from '../../../../../../contexts/stepperContext';
import { useEffect, lazy, Suspense } from 'react';
import { ScrollUp } from '../../../../../../helpers/utils/scroolUp';
import MediasBlock from './components/mediasBlock';
import MediaLinkBlock from './components/mediaLinkBlock';
import PreviousButton from '../../form/previousButton';
import NextButton from '../../form/nextButton';
import { GetMediasFromProperty } from '../../../../../../types/property';
import SpinnerLoading from '../../../../../../features/spinner/spinnerLoading';

const EditModal = lazy(() => import('../../form/editImageModal/editModal'));

const getImages: GetMediasFromProperty = (property) =>
  property?.media?.images || [];

const getPdfs: GetMediasFromProperty = (property) => property?.media?.pdf || [];

const Medias = () => {
  const handleNext = useStore((state) => state.handleNext);

  const {
    register,
    handleSubmit,
    // formState: { errors, isSubmitting },
  } = useForm<ValidationSchema>();
  // scroll up
  useEffect(() => {
    ScrollUp('main');
  }, []);

  const onSubmit = (data: any) => {
    handleNext();
    console.log(data);
  };
  return (
    <Box
      className="flex flex-col mt-12"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <MediasBlock title="Upload images">
        <Suspense fallback={<SpinnerLoading />}>
          <EditModal
            isMultiple
            name="single"
            getMediasFromProperty={getImages}
          />
        </Suspense>
      </MediasBlock>
      <MediaLinkBlock
        register={register}
        name="video"
        title="Upload video link"
      />
      <MediaLinkBlock
        register={register}
        name="3dLink"
        title="Virtual 3D Tour"
      />

      <MediasBlock title="Download brochure">
        <Suspense fallback={<SpinnerLoading />}>
          <EditModal
            accept=".pdf"
            isMultiple
            name="pdf"
            getMediasFromProperty={getPdfs}
          />
        </Suspense>
      </MediasBlock>

      <Box className="flex justify-end pt-6 border-t border-[#E6E8EC] gap-6">
        <PreviousButton />
        <NextButton />
      </Box>
    </Box>
  );
};

export default Medias;
