import { Box, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import useStore from '../../store';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { yupResolver } from '@hookform/resolvers/yup';
import { PublicationInfosStep } from '../../../../../../libs/yup/propertyValidation';
import { ScrollUp } from '../../../../../../helpers/utils/scroolUp';
import { useEffect } from 'react';
import PublishOnWebsites from './components/publishOnWebsites';
import PublishOnGateWays from './components/publishOnGateWays';

type PublicationT = {
  publish_onWebsite: boolean;
  publish_asFeature: boolean;
  publish_onHP: boolean;
};
const PublicationStepForm = () => {
  const handleNext = useStore((state) => state.handleNext);
  const handlePrev = useStore((state) => state.handlePrev);

  // const { locale: currentLanguage } = useLocale();
  const { register, handleSubmit, setValue } = useForm<PublicationT>({
    resolver: yupResolver(PublicationInfosStep),
    shouldFocusError: false,
    defaultValues: { publish_onWebsite: false },
  });

  const onSubmit = async (data: any) => {
    console.log('data', data);
    handleNext();
  };
  // scroll up
  useEffect(() => {
    ScrollUp('main');
  }, []);
  return (
    <Box
      component="form"
      className="flex flex-col mt-12"
      onSubmit={handleSubmit(onSubmit)}
    >
      <PublishOnWebsites setValue={setValue} register={register} />
      <PublishOnGateWays setValue={setValue} />
      <Box
        className="pt-6 border-t border-[#E6E8EC]"
        display="flex"
        gap={2}
        justifyContent="end"
      >
        <Button
          type="submit"
          variant="contained"
          className="flex justify-center items-center w-[104px]  "
          sx={{
            background: '#FFFFFF',
            color: 'black',
            ':hover': { background: 'transparent' },
          }}
          onClick={() => {
            localStorage.setItem('isEditMode', 'true');
            handlePrev();
          }}
        >
          <Box className="flex justify-center items-center" gap="16px">
            <KeyboardBackspaceIcon className=" w-4 h-4" fontSize="small" />
            <p className="capitalize"> Prev</p>
          </Box>
        </Button>
        <Button
          type="submit"
          variant="contained"
          className="flex justify-center items-center  w-[104px]  "
          sx={{
            background: '#3446B9',
          }}
          // onClick={handleNext}
        >
          <Box className="flex justify-center items-center" gap="16px">
            <p className="capitalize"> Submit</p>
            <KeyboardBackspaceIcon
              className="rotate-180 w-4 h-4"
              fontSize="small"
            />
          </Box>
        </Button>
      </Box>
    </Box>
  );
};

export default PublicationStepForm;
