import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useStore, { initialGeneralInfos } from '../../store';
import { GeneraleInfosStep } from '../../../../../../libs/yup/propertyValidation';

import GeneralDataForm from './components/generalData';
import BrokerData from './components/brokerData';
import CadastralData from './components/cadastralData';
import useLocale from '../../../../../../hooks/useLocale';
import { http } from '../../../../../../libs/axios';
// import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { initialValueT } from '../../store/types';
import User from '../../../../../../types/user';
import useSWR from 'swr';
import ReplacementAddress from './components/replacementAddress';
import Publication from './components/publication';
import { findTreeNode } from '../../../../../agencies/tree/utils';
import NextButton from '../../form/nextButton';

// import NumberField from '../../form/NumberField';
export type agencyApiItem = {
  category: string;
  id: number;
  name: string;
  users: User[];
};
export const DefaultOptions = [
  { id: 0, value: 'Option 1' },
  { id: 1, value: 'Option 2' },
  { id: 2, value: 'Option 3' },
];
export default function GeneralInfosStepForm() {
  const [agencies, setAgencies] = useState<agencyApiItem[]>(
    [] as agencyApiItem[]
  );
  const [brokersList, setBrokersList] = useState<any>();
  const [defaultValues] = useState(initialGeneralInfos);
  const handleNext = useStore((state) => state.handleNext);
  const isEditMode = Boolean(localStorage.getItem('isEditMode')) || false;
  const { locale: currentLanguage } = useLocale();
  // const persistedValues = JSON.parse(
  //   localStorage.getItem('general-infos') as string
  // ).state.generalInfos as initialValueT;
  const {
    handleSubmit,
    register,
    watch,
    setValue,

    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<initialValueT>({
    resolver: yupResolver(GeneraleInfosStep),
  });
  // useEffect(() => {
  //   if (isEditMode) {
  //     reset({ ...persistedValues });
  //     setDefaultValues(persistedValues);
  //   }
  // }, []);
  const onSubmit = async (data: any) => {
    const {
      address,
      department,
      availability,
      broker,
      brokerRate,
      category,
      city,
      constructionZone,
      country,
      agency,
      intermediateBroker,
      intermediateBrokerRate,
      landNumber,
      propertyType,
      status,
      startDate,
      endDate,
      zipCode,
      replacement_zipCode,
      replacement_country,
      replacement_city,
      replacement_constructionZone,
      replacement_address,
    } = data;

    const dataToSend: any = {
      status,
      agency,
      department,
      availability: availability === 'choose date' ? 'by date' : availability,
      category,
      type: propertyType,
      mainBroker: Number(broker),
      rateMainBroker: Number(brokerRate),
      intermediateBroker: Number(intermediateBroker) || null,
      rateIntermediateBroker: Number(intermediateBrokerRate),
      landRegister: landNumber,
      address: {
        zipCode,
        translations: {
          [`${currentLanguage}`]: {
            locale: currentLanguage,
            country,
            city,
            zone: constructionZone,
            adresse: address,
          },
        },
      },
      replacementAddress: {
        zipCode: replacement_zipCode,
        translations: {
          [`${currentLanguage}`]: {
            locale: currentLanguage,
            country: replacement_country,
            city: replacement_city,
            zone: replacement_constructionZone,
            adresse: replacement_address,
          },
        },
      },
    };
    if (startDate) {
      dataToSend.availableFrom = startDate;
    }
    if (endDate) {
      dataToSend.availableTo = endDate;
    }
    // const errorMessage = "property doesn't create it please try again ";
    try {
      const createdPropertyId = localStorage.getItem('createdPropertyId');
      const res = isEditMode
        ? await http.patch(`/v1/properties/${createdPropertyId}`, dataToSend)
        : await http.post('/v1/properties', dataToSend);

      if (res.status === 201 || res.status === 200) {
        const newPropertyId = res.data.id;
        localStorage.setItem(
          'createdPropertyId',
          JSON.stringify(newPropertyId)
        );
        // res.status === 201
        //   ? toast.success('property added successfully')
        //   : toast.success('property modified successfully');
        handleNext();
      } else {
        // toast.error(errorMessage as string);
      }
    } catch (error: any) {
      // toast.error(errorMessage as string);
    }

    // ;
  };
  // const { data: getAgencies } = useSWR<agencyApiItem[]>('/users/lateral');
  const { data: hierarchy } = useSWR<any>('/users/hierarchy');
  useEffect(() => {
    if (hierarchy) {
      setAgencies(hierarchy);
    }
  }, [hierarchy]);

  useEffect(() => {
    const brockerDept: any = findTreeNode(agencies, watch('agency'));
    const { id, users } = brockerDept;
    const brokerDepartment: any = id;
    setValue('broker', '');
    setValue('department', brokerDepartment);

    setBrokersList(users);
  }, [watch('agency')]);

  return (
    <>
      <Box
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: 'flex',
          gap: 10,
          flexDirection: 'column',
          marginTop: '48px',
        }}
        component="form"
      >
        <GeneralDataForm
          agencies={agencies}
          defaultValues={defaultValues}
          register={register}
          errors={errors}
          watch={watch}
          setValue={setValue}
          categoryValue={watch('category')}
        />
        <BrokerData
          watch={watch}
          clearErrors={clearErrors}
          brokersList={brokersList}
          defaultValues={defaultValues}
          setValue={setValue}
          register={register}
          errors={errors}
        />
        <CadastralData
          watch={watch}
          clearErrors={clearErrors}
          defaultValues={defaultValues}
          setValue={setValue}
          register={register}
          errors={errors}
        />
        <ReplacementAddress
          watch={watch}
          clearErrors={clearErrors}
          defaultValues={defaultValues}
          setValue={setValue}
          register={register}
          errors={errors}
        />
        <Publication />

        <Box className="flex justify-end pt-6 border-t border-[#E6E8EC] gap-6">
          <NextButton disabled={isSubmitting} />
        </Box>
      </Box>
    </>
  );
}
