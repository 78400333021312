import {
  Button,
  Skeleton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import Card from '../../../components/card';
import { useAuth } from '../../../hooks/useAuth';
import { FileNode } from '../../../types/agency';
import { extractProperty } from '../utils';

const getTreeData = (user: any, locale: string): string | undefined => {
  const { company, filial, agency, department, roles } = user;
  if (roles.includes('ROLE_ADMIN')) {
    const baseUrl = `/companies/${company?.id}=${locale}`;
    return baseUrl;
  } else if (roles.includes('ROLE_ADMIN_ASSISTANT')) {
    const baseUrl = `/filials/${filial?.id}=${locale}`;
    return baseUrl;
  } else if (roles.includes('ROLE_ADMIN_AGENT')) {
    const baseUrl = `/agencies/${agency?.id}=${locale}`;
    return baseUrl;
  } else if (roles.includes('ROLE_ADMIN_OPERATOR')) {
    const baseUrl = `/departments/${department?.id}=${locale}`;
    return baseUrl;
  }
};

export default function Agencies() {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState<string>('');
  const { user } = useAuth();

  const { i18n } = useTranslation('');
  const { t } = useTranslation('common');
  const locale = i18n.language;

  useEffect(() => {
    if (locale) {
      const newUrl = getTreeData(user, locale)!;
      setUrl(newUrl);
    }
  }, [locale]);

  const handleCopyClick = (agencyKey: string) => {
    setOpen(true);
    navigator.clipboard.writeText(agencyKey);
  };

  const { data } = useSWR<FileNode>(Object.keys(user).length > 0 && url);
  const names = extractProperty(data, 'name', []);
  const servicesToDisplay = names.slice(0, 6);
  const apiKeys = extractProperty(data, 'agencyKey', []).slice(0, 6);
  return (
    <Card sx={{ bgcolor: 'background.default', color: 'text.primary' }}>
      <Card.Header
        sx={{
          bgcolor: 'background.paper',
          color: 'text.primary',
        }}
      >
        <Typography variant="subtitle1" fontWeight={500}>
          {`${t('Services')} (${names.length})`}
        </Typography>
        <Link to="/agencies">
          <Button> {t('showAll')}</Button>
        </Link>
      </Card.Header>

      <Card.Body className="p-0">
        <TableContainer>
          <Table>
            <TableBody>
              {(!data ? Array.from(new Array(3)) : servicesToDisplay).map(
                (item, i) => (
                  <TableRow
                    key={i}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      borderBottom: '1px solid rgb(230, 232, 246)',
                      alignItems: 'center',
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    {item ? (
                      <TableCell
                        className="truncate"
                        sx={{ borderBottom: 'none' }}
                      >
                        {`${data?.name} | ${item}`}
                      </TableCell>
                    ) : (
                      <TableCell className="w-full">
                        <Skeleton width="100%" />
                      </TableCell>
                    )}
                    {item && apiKeys.length && apiKeys[i] ? (
                      <TableCell sx={{ borderBottom: 'none' }}>
                        <Button
                          sx={{
                            textDecoration: 'underline',
                            textDecorationStyle: 'dashed',
                          }}
                          className="!px-1 !text-xs"
                          onClick={() => handleCopyClick(apiKeys[i]!)}
                        >
                          {t('copyAPI')}
                        </Button>
                        <Snackbar
                          message="Copied to clibboard"
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          autoHideDuration={2000}
                          onClose={() => setOpen(false)}
                          open={open}
                        />
                      </TableCell>
                    ) : (
                      <TableCell className="w-1/5">
                        <Skeleton width="100%" />
                      </TableCell>
                    )}
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card.Body>
    </Card>
  );
}
