import { Box, Stack, Typography } from '@mui/material';
import PlanTag from './planTag';

function PromotionPlan() {
  const PlanImg = '/img/promotion/plan.png';
  return (
    <Stack
      className=" border rounded"
      sx={{
        padding: '32px',
        display: 'flex',
        gap: '16px',
      }}
    >
      <Typography variant="h5">Construction Plan </Typography>
      <Stack
        justifyContent="space-between"
        alignItems="flex-start"
        direction="row"
        sx={{
          borderWidth: '1px  ',
          padding: '16px',
        }}
      >
        <Box
          sx={{ backgroundColor: 'background.default', color: 'text.primary' }}
        >
          <img src={PlanImg} alt="plan" />
        </Box>
        <Box className="min-w-[210px]">
          <PlanTag status="available" value={8} />
          <PlanTag status="active" value={7} />
          <PlanTag status="reserved" value={1} />
          <PlanTag status="sold" value={4} />
        </Box>
      </Stack>
    </Stack>
  );
}

export default PromotionPlan;
