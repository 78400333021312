import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useContext, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { StepperContext } from '../../contexts/stepperContext';
import { useGeneralInfoStore } from '../../pages/properties/stepper/components/store';

export type selectOptionT = { id: number; value: string };

type SelectForm = {
  name: string;
  isRequired?: boolean;
  className?: string;
  label: string;
  error?: any;
  watch?: any;
  setSelectedIdsToSend?: any;
  setValue?: any;
  register: UseFormRegister<any>;
  options: selectOptionT[];
  isMultiple?: boolean;
};
const defaultOptions = [{ id: 0, value: 'empty' }];

const ControlledSelectedForm = ({
  label,
  name,
  className,
  register,
  isRequired = false,
  error,
  watch,
  setSelectedIdsToSend,
  setValue,
  isMultiple = false,
  options = defaultOptions,
}: SelectForm) => {
  const { handleChange } = useContext(StepperContext);
  const updateInfo = useGeneralInfoStore((state) => state.updateInfo);
  const [selectedValue, setSelectedValue] = useState<string[]>([]);

  const handleChangeValues = (
    event: SelectChangeEvent<typeof selectedValue>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedValue(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    setValue(watch(label));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <span className="text-xs text-zinc-500">
        {' '}
        {label} {isRequired && <span className="text-red-500">*</span>}
      </span>
      <FormControl error={error} fullWidth>
        <Select
          className={'w-[416px] h-12' + ' ' + className}
          defaultValue={''}
          multiple={isMultiple}
          value={isMultiple ? selectedValue : selectedValue[0]}
          displayEmpty
          {...register(name, {
            onChange: (e) => {
              handleChange(e);
              handleChangeValues(e);
              updateInfo({
                name,
                value: String(e.target.value),
              });
            },
          })}
        >
          {options.map((item: selectOptionT) => (
            <MenuItem
              onClick={(event) => {
                const { myValue } = event.currentTarget.dataset as any;
                setSelectedIdsToSend &&
                  isMultiple &&
                  setSelectedIdsToSend((prev: any) => [...prev, myValue]);
                setSelectedIdsToSend &&
                  !isMultiple &&
                  setSelectedIdsToSend(myValue);
              }}
              key={item.id + Math.random()}
              value={item.value}
              data-my-value={item.id}
            >
              {item.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
export default ControlledSelectedForm;
