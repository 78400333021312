import { Avatar, Box, Stack } from '@mui/material';
import PhoneIcon from '../../../../features/icons/phoneIcon';
import { UserPayload } from '../../../../types/property';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

type Props = {
  mainBroker: UserPayload;
};
function UserInfoCard({ mainBroker }: Props) {
  const { avatar, email, firstname, lastname, phone } = mainBroker;
  return (
    <Stack className="w-full rounded bg-[#FFFFFF] border  flex flex-col">
      <Box
        sx={{ backgroundColor: 'background.default' }}
        className="w-full p-8 grid grid-cols-3 gap-8"
      >
        <Avatar
          variant="rounded"
          src={avatar}
          alt=""
          sx={{
            width: '100%',
            height: '100%',
            aspectRatio: '1/1',
            gridColumn: 'span 1 / span 1',
          }}
        />

        <div className=" col-span-2 flex flex-col justify-center">
          <h3 className="font-medium mb-1">{`${firstname} ${lastname}`}</h3>
          <h5 className="text-sm">Agent</h5>
        </div>
      </Box>
      <Box className="w-full px-8 py-6">
        {email && (
          <div className=" mt-6">
            <a
              href={`mailto:${email}`}
              className="rounded border border-[#3446B9] justify-center items-center text-[#3446B9] px-4 py-2 flex gap-4"
            >
              <MailOutlineIcon /> {email}
            </a>
          </div>
        )}
        {phone && (
          <div className=" mt-6   ">
            <a
              href={`tel::${phone}`}
              className="rounded border border-[#3446B9] justify-center items-center text-[#3446B9] px-4 py-2 flex gap-4"
            >
              <PhoneIcon color="#3446B9" /> {phone}
            </a>
          </div>
        )}
      </Box>
    </Stack>
  );
}

export default UserInfoCard;
