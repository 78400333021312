import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useFiltersProperties } from '../../../../hooks/useFiltersProperties';
import SettingsBarsIcon from '../../../../features/icons/settingsBarsIcon';
import EraseIcon from '../../../../features/icons/eraseIcon';
import PlusIcon from '../../../../features/icons/plusIcon';
import { IfieldFilter } from '../../../../types/property';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 200,
    border: 'none !important',
  },
  // select: {
  //    !important',
  // },
  indeterminateColor: {
    color: '#f50057',
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}));

type Props = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  advancedOptions: IfieldFilter[];
};

function PropertiesFilterHeader({ setIsOpen, advancedOptions }: Props) {
  const { t } = useTranslation('propertiesPage');
  const [openAdvFilterIsOpen, setOpenAdvFilterIsOpen] = useState(false);
  const classes = useStyles();
  const { selectedAdvancedFilter, setSelectedAdvancedFilter } =
    useFiltersProperties();

  const handleClose = () => {
    setOpenAdvFilterIsOpen(false);
  };

  const handleOpen = () => {
    setOpenAdvFilterIsOpen(true);
  };

  const options: string[] = useMemo(() => {
    return advancedOptions.map(({ title }) => title);
  }, [advancedOptions]);

  const isAllSelected =
    options.length > 0 && selectedAdvancedFilter.length === options.length;

  const handleChange = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      setSelectedAdvancedFilter(
        selectedAdvancedFilter.length === options.length ? [] : options
      );
      return;
    }
    setSelectedAdvancedFilter(value);
  };
  // console.log('selectedAdvancedFilter', selectedAdvancedFilter);
  const handelMenuOpen = () => setIsOpen((prev) => !prev);
  return (
    <Box
      className="flex h-10 justify-between px-4 rounded "
      sx={{ bgcolor: '#E6E8EC', color: 'text.primary' }}
    >
      {' '}
      <Typography
        className="h-full flex items-center cursor-pointer  "
        sx={{ color: '#6E6E72', fontFamily: 'Inter' }}
        onClick={handelMenuOpen}
      >
        <SettingsBarsIcon className="mr-4" color="#6E6E72" />
        {t('search_filter').toUpperCase()}
      </Typography>
      <Box
        className="flex h-10 justify-between text-[#3446B9]"
        sx={{ bgcolor: '#dfdfe4' }}
      >
        <FormControl className={`${classes.formControl} !m-0`}>
          <Typography
            variant="subtitle1"
            className="h-full z-10 flex items-center bg-[#E6E8EC] px-4 cursor-pointer"
            onClick={handleOpen}
          >
            <PlusIcon className="mr-2" color="#3446B9" />
            {t('advanced_search')}
          </Typography>
          <Select
            // className="bg-[#dfdfe4]  "
            sx={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
            }}
            open={openAdvFilterIsOpen}
            onClose={handleClose}
            onOpen={handleOpen}
            labelId="mutiple-select-label"
            multiple
            value={selectedAdvancedFilter}
            renderValue={() => false}
            onChange={(event: any) => handleChange(event)}
          >
            <MenuItem
              value="all"
              classes={{
                root: isAllSelected ? classes.selectedAll : '',
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: classes.indeterminateColor }}
                  checked={isAllSelected}
                  indeterminate={
                    selectedAdvancedFilter.length > 0 &&
                    selectedAdvancedFilter.length < options.length
                  }
                />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.selectAllText }}
                primary="Select All"
              />
            </MenuItem>
            {options.map((option: string, i: number) => (
              <MenuItem key={option + i} value={option}>
                <ListItemIcon>
                  <Checkbox
                    checked={selectedAdvancedFilter.indexOf(option) > -1}
                  />
                </ListItemIcon>
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography
          variant="subtitle1"
          className="h-full bg-[#E6E8EC] border-l border-gray-300  px-4 flex items-center cursor-pointer "
        >
          <EraseIcon className="mr-2" color="#3446B9" />
          {t('clear')}
        </Typography>
        {/* <Typography
          variant="subtitle1"
          className="h-full flex items-center border-l border-gray-300  px-4 cursor-pointer"
        >
          <SettingsIcon className="mr-2" />
          {t('configuration')}
        </Typography> */}
      </Box>
    </Box>
  );
}

export default PropertiesFilterHeader;
