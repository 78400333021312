import { userItem } from './../pages/agencies/components/userItem';
import create from 'zustand';
interface DragUsersState {
  users: userItem[] | [];
  unAssignedUsers: userItem[];
  updateUser: (user: userItem[]) => void;
  updateUserWhenDrop: (user: userItem[]) => void;
  updateUnAssignedUsers: (users: userItem[]) => void;
  updateUnAssignedUserWhenDrop: (id: number) => void;
}

const initialState = { users: [], unAssignedUsers: [] };

export const useDragAndDropUsers = create<DragUsersState>((set, get) => ({
  ...initialState,
  updateUser: (users: userItem[]) => {
    set({
      users: users,
    });
  },
  updateUserWhenDrop: (NewUsersList: userItem[]) => {
    set({
      users: NewUsersList,
    });
  },
  updateUnAssignedUsers: (users: userItem[]) => {
    set({
      unAssignedUsers: users,
    });
  },
  updateUnAssignedUserWhenDrop: (id: number) => {
    const data = get().unAssignedUsers.filter((item) => item.id !== Number(id));
    set({
      unAssignedUsers: data,
    });
  },
}));
