import { Box, Typography } from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid';
import TabGridTag from '../../../features/tags/tabGridTag';
import { getuserRole, getuserAgency } from '../utils';

type Props = {
  cardKeys: string[];
  data: GridValidRowModel;
};

const displayValue = (data: GridValidRowModel, item: string) => {
  // console.log('superadmin', data, item);
  if (data[item] !== null) {
    if (item === 'Role') {
      return data.isSuperAdmin ? (
        <TabGridTag
          key={'superAdmin'}
          textContent={'Super admin'}
          fontSize="14px"
          className="m-0"
          color="#ff0000"
        />
      ) : data['roles'].length === 0 ? (
        <TabGridTag
          key={'unassigned'}
          textContent={'Unassigned'}
          fontSize="14px"
          color="#1D1D1F"
        />
      ) : (
        data['roles'].map((role: string, i: number) => (
          <TabGridTag
            key={`${role}-${i}`}
            textContent={
              role.toLocaleLowerCase().includes('admin') ? 'Admin' : 'Agent'
            }
            fontSize="14px"
            color={
              role.toLocaleLowerCase().includes('admin') ? '#ED6C02' : '#4CAF50'
            }
          />
        ))
      );
    } else if (item === 'Level') {
      return getuserRole(data);
    } else if (item === 'Belong to') {
      return getuserAgency(data);
    } else {
      return data[item].name ? data[item].name : data[item];
    }
  } else {
    return '-';
  }
};

function InfoCard({ cardKeys, data }: Props) {
  if (!data || !cardKeys) return null;
  return (
    <Box className="col-span-1 flex flex-col border border-[#E6E8EC]">
      {cardKeys &&
        cardKeys.map((item, i) => (
          <Box
            key={`${item}-${i}`}
            className="grid grid-cols-4 border-b border-[#E6E8EC] bg-white h-12 hover:bg-gray-50 "
          >
            <Typography
              variant="subtitle1"
              className="col-span-1 px-4 flex items-center capitalize"
            >
              {item}
            </Typography>
            <Typography
              variant="subtitle1"
              className="col-span-3 px-4 flex items-center overflow-hidden"
            >
              {displayValue(data, item)}
            </Typography>
          </Box>
        ))}
    </Box>
  );
}

export default InfoCard;
