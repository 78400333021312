import { Box, Grid, Stack, Typography } from '@mui/material';
import HistoryIcon from '../../../../features/icons/historyIcon';
import PDFIcon from '../../../../features/icons/pdfIcon';
import UsersFilledIcon from '../../../../features/icons/usersFilledIcon';
import UsersOutlinedIcon from '../../../../features/icons/usersOutlinedIcon';

function PromotionProperties() {
  const Property1 = '/img/promotion/property1.png';
  const Property2 = '/img/promotion/property2.png';
  const Property3 = '/img/promotion/property3.png';

  const properties = [
    {
      img: Property1,
      active: true,
      amenities: [
        { label: 'reference', value: '22-622-01' },
        { label: 'rooms', value: '5' },
        { label: 'price', value: '63’000' },
        { label: 'building', value: 'A1' },
        { label: 'bedrooms', value: '2' },
        { label: 'Price/m2', value: '326' },
        { label: 'features', value: '15' },
        { label: 'floor', value: '5' },
        { label: 'All surface / m2', value: '253' },
        { label: 'Habitable / m2', value: '159' },
      ],
    },
    {
      img: Property2,
      active: false,

      amenities: [
        { label: 'reference', value: '22-622-01' },
        { label: 'rooms', value: '5' },
        { label: 'price', value: '63’000' },
        { label: 'building', value: 'A1' },
        { label: 'bedrooms', value: '2' },
        { label: 'Price/m2', value: '326' },
        { label: 'features', value: '15' },
        { label: 'floor', value: '5' },
        { label: 'All surface / m2', value: '253' },
        { label: 'Habitable / m2', value: '159' },
      ],
    },
    {
      img: Property3,
      active: false,

      amenities: [
        { label: 'reference', value: '22-622-01' },
        { label: 'price', value: '63’000' },
        { label: 'building', value: 'A1' },
        { label: 'rooms', value: '5' },
        { label: 'bedrooms', value: '2' },
        { label: 'Price/m2', value: '326' },
        { label: 'features', value: '15' },
        { label: 'floor', value: '5' },
        { label: 'All surface / m2', value: '253' },
        { label: 'Habitable / m2', value: '159' },
      ],
    },
  ];
  return (
    <Stack
      sx={{
        backgroundColor: 'background.default',
        color: 'text.primary',
        padding: '32px',
        display: 'flex',
        gap: '16px',
      }}
    >
      <Typography variant="h5">Properties</Typography>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '12px',
        }}
      >
        {properties.map(({ img, amenities }, index) => (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 8fr 1fr',
              gap: '16px',
              borderBottomWidth: '1px',
              '&:last-child': {
                borderBottom: 'none',
              },
            }}
            key={index}
          >
            <Box className="flex items-center">
              <img src={img} alt="" />
            </Box>
            <Grid container>
              {amenities.map(({ label, value }, index) => (
                <Grid
                  item
                  xs={3}
                  className="flex gap-2 text-xs capitalize p-2"
                  key={index}
                >
                  <p>
                    {label}: {value}
                  </p>
                </Grid>
              ))}
            </Grid>
            <Box className="flex flex-col gap-4">
              <Box className="flex gap-4">
                <UsersFilledIcon className="w-5 h-5 cursor-pointer" />
                <UsersOutlinedIcon className="w-5 h-5 cursor-pointer" />
              </Box>
              <Box className="flex  gap-4">
                <HistoryIcon className="w-5 h-5 cursor-pointer" />
                <PDFIcon className="w-5 h-5 cursor-pointer" />
              </Box>
            </Box>
          </Box>
        ))}
      </Stack>
    </Stack>
  );
}

export default PromotionProperties;
