import { Box } from '@material-ui/core';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useRef, useState } from 'react';
type Props = {
  address: string | null;
};
const StepperMapContainer = ({ address = 'zurich' }: Props) => {
  const [longitude, setLongitude] = useState<number>(0);
  const [latitude, setLatitude] = useState<number>(0);
  const containerStyle = { width: '100%', height: '385px' };
  const options = {
    zoomControl: false,
    streetViewControl: false,
    disableDefaultUI: true,
  };
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  });

  const handleButtonClick = async () => {
    const geocoder = new google.maps.Geocoder();
    await geocoder.geocode(
      {
        address: address,
      },
      (results, status) => {
        if (
          status === 'OK' &&
          results?.length &&
          results[0]?.geometry.location
        ) {
          const { lat, lng } = results[0].geometry.location;
          setLatitude(lat());
          setLongitude(lng());
        }
      }
    );
  };
  useEffect(() => {
    handleButtonClick();
  });

  const mapRef = useRef<any | null>(null);
  const onLoad = (map: any): void => {
    mapRef.current = map;
  };

  const onUnmount = (): void => {
    mapRef.current = null;
  };

  if (!isLoaded) return <div>Loading ...</div>;
  return (
    <Box>
      <GoogleMap
        id="marker-example"
        mapContainerStyle={containerStyle}
        options={options}
        center={{ lat: latitude, lng: longitude }}
        zoom={12}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {latitude && longitude && (
          <div className="">
            <Marker
              visible={true}
              animation={google.maps.Animation.DROP}
              position={{ lat: latitude, lng: longitude }}
            />
          </div>
        )}
      </GoogleMap>
    </Box>
  );
};

export default StepperMapContainer;
