import { useState, useRef, useEffect } from 'react';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Box, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { AgencyDrawerContent, FileNode, Offset } from '../../../types/agency';
import { countries } from '../../../const/countries';
import useLocale from '../../../hooks/useLocale';
import { useTranslation } from 'react-i18next';
import _boxStyle from '../../../const/style';
import { getCountry } from './utils';
import { toast } from 'react-toastify';
import { useDragAndDropUsers } from '../../../hooks/useDragAndDropUsers';
import { http } from '../../../libs/axios';
import { mutate } from 'swr';

type Props = {
  initialOffset: Offset;
  node: FileNode;
  basedUrl: string;
  isDrawerOpen: boolean;
  setDrawerContent: (typeContent: any) => void;
  handleCloseDrawer: () => void;
  handleOpenDrawer: () => void;
  toggleDrawer: any;
  drawerContent: AgencyDrawerContent;
  setRoleModal: (arg: boolean) => void;
};

export const TreeCard = ({
  initialOffset,
  node,
  isDrawerOpen,
  setDrawerContent,
  drawerContent,
  basedUrl,
  setRoleModal,
  toggleDrawer,
  ...style
}: Props) => {
  const { currentNode: drawerCurrentNode } = drawerContent;
  const { locale } = useLocale();
  const [infosCountry, setInfosCountry] = useState('');
  const { t } = useTranslation('alerts');
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const divRef = useRef<HTMLInputElement>(null);
  const { country, name, categoryEntity, city, brand, id, logo } = node;
  const {
    users,
    updateUserWhenDrop,
    updateUser,
    unAssignedUsers,
    updateUnAssignedUsers,
  } = useDragAndDropUsers();
  const isAdmin = JSON.parse(localStorage.getItem('isAdmin') as string);

  const moreStyle: CSSProperties = initialOffset
    ? { position: 'absolute', left: initialOffset.x, top: initialOffset.y }
    : {};

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (country) {
      const newCountry = getCountry(countries, country)[locale];
      setInfosCountry(newCountry);
    }
  }, [locale]);

  const getBoxWidthByLevel = (categoryEntity: string | undefined) => {
    switch (categoryEntity) {
      case 'Filial':
        return '63px';
      case 'Agency':
        return '55px';
      case 'Department':
        return '32px';
      default:
        return '90px';
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const renderAddformLevel = (categoryEntity: string | undefined) => {
    switch (categoryEntity) {
      case 'Company':
        return t('add-country');
      case 'Filial':
        return t('add-agency');
      case 'Agency':
        return t('add-departement');
    }
  };

  const setDrawerContentFromMenu = (categoryEntity: string | undefined) => {
    switch (categoryEntity) {
      case 'Company':
        setDrawerContent({ type: `formAgenciesAdd-Filial`, currentNode: node });
        break;
      case 'Filial':
        setDrawerContent({ type: `formAgenciesAdd-Agency`, currentNode: node });
        break;
      case 'Agency':
        setDrawerContent({
          type: `formAgenciesAdd-Department`,
          currentNode: node,
        });
        break;
    }
  };

  const renderboxforCompany = () => {
    return (
      <Box className="flex flex-col justify-center items-center gap-3 w-full ">
        {logo && (
          <>
            <img className="w-[105px] h-6" src={logo} alt="company brand" />
          </>
        )}
        <Divider className="w-full !border-[#F5F5F7]" />
        <Typography
          sx={{
            color: '#1D1D1F',
            opacity: 0.5,
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '120%',
          }}
        >
          {name}
        </Typography>
      </Box>
    );
  };

  const renderboxforFilial = () => {
    return (
      <Box className="flex flex-col justify-center w-full ">
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: '500',
            lineHeight: '16px',
            fontSize: '14px',
            color: '#1D1D1F',
          }}
        >
          {name}
        </Typography>
        <Box className="flex gap-2 align-middle  ">
          <div
            className={`react-tel-input flag ${country?.toLocaleLowerCase()}`}
          />
          <Typography
            variant="subtitle1"
            className="text-sm leading-4 text-gray-400"
          >
            {infosCountry}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderboxforAgency = () => {
    return (
      <Box>
        <Typography variant="subtitle1" className="text-sm text-gray-400">
          {city}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: '500',
            lineHeight: '16px',
            fontSize: '14px',
            color: '#1D1D1F',
          }}
        >
          {name}
        </Typography>
      </Box>
    );
  };

  const renderboxforDepartment = () => {
    return (
      <Box>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: '500',
            lineHeight: '16px',
            fontSize: '14px',
            color: '#1D1D1F',
          }}
        >
          {name}
        </Typography>
      </Box>
    );
  };

  const renderBoxContent = () => {
    switch (categoryEntity) {
      case 'Company':
        return renderboxforCompany();
      case 'Filial':
        return renderboxforFilial();
      case 'Agency':
        return renderboxforAgency();
      case 'Department':
        return renderboxforDepartment();
    }
  };
  const boxHeight = { height: getBoxWidthByLevel(categoryEntity) };

  const adminHandleDrop = async (e: any) => {
    await setRoleModal(true);
    const id = e.dataTransfer.getData('id');
    const type = e.dataTransfer.getData('type');
    const containerValues = divRef?.current?.getAttribute('data-value');
    const userDataToSend = { id, type, containerValues };
    localStorage.setItem('userDraggedData', JSON.stringify(userDataToSend));
    mutate(basedUrl);
  };

  const userHandleDrop = async (e: any) => {
    const id = e.dataTransfer.getData('id');
    const type = e.dataTransfer.getData('type');
    const containerValues = divRef?.current?.getAttribute('data-value');
    const containerType = JSON.parse(containerValues!).type.toLocaleLowerCase();
    const containerId = JSON.parse(containerValues!).id;
    const dataToSend = {
      [`${containerType}`]: containerId,
    };
    try {
      const res = await http.patch(`/users/${id}`, dataToSend);
      if (res.status === 200) {
        if (type === 'users') {
          const newusers = users.filter((item) => item.id !== Number(id));
          updateUserWhenDrop(newusers);
        } else {
          mutate(`/users/not-assigned?page=${1}&itemsPerPage=${10}`);
          updateUnAssignedUsers(
            unAssignedUsers.filter((item) => item.id !== Number(id))
          );
        }
        mutate(basedUrl);
        toast.success(t(`alerts:user_added`) as string);
      } else {
        toast.warn(t(`alerts:user_not_added`) as string);
      }
    } catch (error) {
      toast.warn(t(`alerts:user_not_added`) as string);
    }
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  return (
    <>
      <div
        ref={divRef}
        data-value={JSON.stringify({ type: node['categoryEntity'], id })}
        onDrop={!isAdmin.isAdmin ? userHandleDrop : adminHandleDrop}
        onDragOver={handleDragOver}
        id={name}
        style={
          {
            ..._boxStyle,
            ...style,
            ...moreStyle,
            ...boxHeight,
          } as CSSProperties
        }
      >
        <button
          ref={anchorRef}
          style={{
            position: 'absolute',
            width: '20px',
            height: '22px',
            background: '#E6E8EC',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start',
            padding: 0,
            margin: 0,
            top: 5,
            right: 10,
          }}
          id="basic-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          ...
        </button>
        <Popper
          className="z-[100]"
          sx={{ minWidth: '140px' }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    // onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      onClick={(event) => {
                        setDrawerContent({ type: 'info', currentNode: node });
                        handleClose(event);
                      }}
                    >
                      {t('info')}
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        if (node && node.users) {
                          updateUser(node?.users);
                        }
                        setDrawerContent({
                          type: 'agencyUsers',
                          currentNode: node,
                        });
                        handleClose(event);
                      }}
                    >
                      {t('users')}
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        setDrawerContentFromMenu(categoryEntity);
                        handleClose(event);
                      }}
                    >
                      {renderAddformLevel(categoryEntity)}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <div
          onClick={() => {
            setDrawerContent({ type: 'info', currentNode: node });
            toggleDrawer(true);
            drawerCurrentNode.id === id && isDrawerOpen
              ? toggleDrawer(false)
              : toggleDrawer(true);
          }}
          style={{
            display: 'flex',
            cursor: 'pointer',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '4px',
              height: '100%',
              background: brand,
            }}
          ></div>
          {renderBoxContent()}
        </div>
      </div>
    </>
  );
};
