import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { Box, Stack, Switch, Typography } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DateRangePicker from '../../../../../../../components/dateRangePicker/dateRangePicker';

type Props = { register: UseFormRegister<any>; setValue: UseFormSetValue<any> };

const PublishOnWebsites = ({ register, setValue }: Props) => {
  return (
    <Box display="flex" flexDirection="row" className="mb-16" gap="80px">
      <Typography width="20%" className="stepTitle" variant="h6">
        Publish on websites
      </Typography>
      <Box className="flex gap-52 w-full">
        <Box className="flex flex-col gap-3 w-2/5">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
              <Box className="grid grid-cols-1 items-center justify-center mt-1 w-full gap-8">
                <DateRangePicker
                  label={'start date'}
                  name={'startDate'}
                  setValue={setValue}
                />
                <DateRangePicker
                  label={'end date'}
                  name={'endDate'}
                  setValue={setValue}
                />
              </Box>
            </Stack>
          </LocalizationProvider>
        </Box>
        <Box className="flex flex-col gap-3  ">
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={3}
          >
            <Typography className="min-w-[250px]">
              Publish on Website
            </Typography>
            <Switch {...register('publish_onWebsite')} />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={3}
          >
            <Typography className="min-w-[250px] ">featured</Typography>
            <Switch {...register('publish_asFeature')} />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={3}
          >
            <Typography className="min-w-[250px] ">
              Publish on home page
            </Typography>
            <Switch {...register('publish_onHP')} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PublishOnWebsites;
