export type stepperOptionT = { id: number; value: string };

const statusOptions: stepperOptionT[] = [
  { id: 0, value: 'in progress' },
  { id: 1, value: 'active' },
  { id: 2, value: 'sold' },
  { id: 3, value: 'rent' },
  { id: 4, value: 'reserved' },
  { id: 5, value: 'suspended' },
];
const dealOptions: stepperOptionT[] = [
  { id: 0, value: 'for sale' },
  { id: 1, value: 'for rent' },
  { id: 2, value: 'commercial rent' },
  { id: 3, value: 'commercial sale' },
];
const availabilityOptions: stepperOptionT[] = [
  { id: 0, value: 'to be agreed' },
  { id: 1, value: 'immediate' },
  { id: 2, value: 'not available' },
  { id: 3, value: 'choose date' },
];
const categoryOptions: stepperOptionT[] = [
  { id: 0, value: 'residential' },
  { id: 1, value: 'commercial' },
];
const residentialPropertyOptions: stepperOptionT[] = [
  { id: 0, value: 'Apartment' },
  { id: 1, value: 'House' },
  { id: 2, value: 'Villa' },
  { id: 3, value: 'Chalet' },
  { id: 4, value: 'Logement de vacances' },
  { id: 5, value: 'Terrain' },
  { id: 6, value: 'Immeubles' },
];
const commercialPropertyOptions: stepperOptionT[] = [
  { id: 0, value: 'Shop' },
  { id: 1, value: 'Office' },
  { id: 2, value: 'Arcade' },
  { id: 3, value: 'deposit' },
  { id: 4, value: 'parking' },
  { id: 5, value: 'industrial surface' },
  { id: 6, value: 'land' },
];

export type infoType = {
  id: number;
  label: string;
  options: stepperOptionT[];
};
const GenreOptions = [
  { id: 0, value: 'Mr' },
  { id: 1, value: 'Mrs' },
];
const titleOptions = [
  { value: 'Dear Mr.', id: 0 },
  { value: 'Dear Mrs.', id: 1 },
  { value: 'Dear Mr.,Dear Mrs.', id: 2 },
];
const originOptions = [
  { value: 'Website', id: 0 },
  { value: 'Telephone', id: 1 },
  { value: 'Newsletter', id: 2 },
  { value: 'Email', id: 3 },
  { value: 'Properstar', id: 4 },
  { value: 'immomig.ch', id: 5 },
  { value: 'Acheter-Louer', id: 6 },
  { value: 'Immoscout 24', id: 7 },
];
const typeOptions = [
  { value: 'Buyer', id: 0 },
  { value: 'Tenant Investor', id: 1 },
  { value: 'Newsletter Viewings', id: 2 },
  { value: 'Developer', id: 3 },
  { value: 'General contractor', id: 4 },
  { value: 'Relocation', id: 5 },
  { value: 'Partner', id: 6 },
  { value: 'Prospect', id: 7 },
];
const languagesOptions = [
  { id: 0, value: 'French' },
  { id: 1, value: 'English' },
];
export {
  statusOptions,
  originOptions,
  typeOptions,
  languagesOptions,
  GenreOptions,
  titleOptions,
  dealOptions,
  availabilityOptions,
  categoryOptions,
  residentialPropertyOptions,
  commercialPropertyOptions,
};
