import { useThemeMode } from '../../hooks/useThemeMode';

export type IconProps = {
  color?: string;
  className?: string;
};

function RectangleIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        d="M8.788.646A.5.5 0 019.14.5H15a.5.5 0 01.5.5v5.859a.5.5 0 01-.146.353L7.626 14.94a.5.5 0 01-.707 0L1.061 9.081a.5.5 0 010-.707L8.788.646z"
      ></path>
    </svg>
  );
}

export default RectangleIcon;
