const toolbarOptions = [
  ['bold', 'italic', 'underline'],
  ['blockquote'],
  [{ header: 1 }, { header: 2 }],
  [{ list: 'ordered' }, { list: 'bullet' }],
];
const defaultQuillModules = {
  clipboard: { matchVisual: false },
};
export { toolbarOptions, defaultQuillModules };
