import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '../../hooks/useAuth';
import Layout from '../../layout/layout';

interface Props {
  fromLoggedUsers?: boolean;
}

const PrivateRoute: FC<Props> = ({ fromLoggedUsers = false }) => {
  const isAuthenticated = Boolean(useAuth().tokens);

  if (isAuthenticated && fromLoggedUsers) {
    return <Navigate to="/" />;
  }

  if (!isAuthenticated && !fromLoggedUsers) {
    return <Navigate to="/signin" />;
  }

  if (!isAuthenticated) {
    return <Outlet />;
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default PrivateRoute;
