import { Box, Typography } from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import Agencies from './components/agencies';
import { Link } from 'react-router-dom';
import WithModal, {
  InjectedModalState,
} from '../../components/modal/widthModal';
import UserForm from '../users/addNewUserPage/userForm';
import Modal from '../../components/modal';
import Users from './components/users';
import Properties from './components/properties';

function Dashboard({ ...rest }) {
  const {
    user: { firstname, roles },
  } = useAuth();

  const modalState = rest as InjectedModalState;
  const [isAbleToAddUser, setIsAbleToAddUser] = useState(false);
  const { t } = useTranslation('common');
  const isAdmin = JSON.parse(localStorage.getItem('isAdmin') as string);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  useEffect(() => {
    if (!isAdmin.isAdmin || roles.includes('ROLE_ADMIN_OPERATOR')) {
      setIsAbleToAddUser(false);
    } else {
      setIsAbleToAddUser(true);
    }
  }, [isAdmin, roles]);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ color: 'text.primary' }}>
      <Box
        className="flex justify-between items-center"
        sx={{ color: 'text.primary' }}
      >
        <Box>
          <Typography variant="h3">{t('Dashboard')}</Typography>
          <Typography variant="body2" sx={{ margin: '4px' }}>
            {t('welcomeBack')}, {firstname}
          </Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <AddIcon fontSize="small" />
            <Box component="span" className="ml-2">
              {t('createNew')}
            </Box>
          </Button>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {isAbleToAddUser && (
              <MenuItem>
                <Box>
                  <Modal
                    width={{
                      md: 500,
                    }}
                    title={t('Add_new_user')}
                    buttonProps={{
                      title: t('User'),
                      onClick: () => modalState.openModal(),
                      sx: { ':hover': { background: 'none' }, padding: '0' },
                      className:
                        'font-normal text-base leading-normal !w-full text-left text-[#1D1D1F]',
                    }}
                    {...modalState}
                  >
                    <UserForm
                      type="add"
                      closeModal={modalState.closeModal}
                      apiKey={'/users'}
                      itemsPerPage={7}
                    />
                  </Modal>
                </Box>
              </MenuItem>
            )}
            <Link to="/properties/addProperty">
              <MenuItem>{t('Property')}</MenuItem>
            </Link>

            {/* <MenuItem onClick={handleClose}>{t('Website')}</MenuItem> */}
          </Menu>
        </Box>
      </Box>

      <Box className="grid xl:grid-cols-2 gap-x-3 gap-y-6 mt-8">
        {isAdmin.isAdmin && <Agencies />}
        <Users />
        <Properties />
        {/* <Agencies /> */}
      </Box>
    </Box>
  );
}

export default WithModal(Dashboard);
