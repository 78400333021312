import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { UseFormRegister } from 'react-hook-form';
// import { useContext } from 'react';
import { Box, FormControl } from '@mui/material';
import useLocale from '../../../../../hooks/useLocale';
import { useGeneralInfoStore } from '../store';
import { useEffect, useState } from 'react';

type AutocompleteSelectT = {
  name: string;
  label: string;
  className?: string;
  defaultValue?: { label: string; value: string };
  isStepper?: boolean;
  error?: any;
  width?: string;
  height?: string;
  isRequired?: boolean;
  clearErrors: any;
  isMultiple?: boolean;
  setValue?: any;
  register?: UseFormRegister<any>;
  options?: any;
  isCountries?: boolean;
  disabled?: boolean;
};
const convertOptionsData = (options: any) => {
  if (options.length) {
    for (let i = 0; i < options.length; i++) {
      options[i].label = `${
        options[i]?.firstname + ' ' + options[i]?.lastname
      }`;
      options[i].value = options[i]?.id;
    }
  }
  return options;
};
const convertCountriesData = (options: any, locale: string) => {
  if (options.length) {
    for (let i = 0; i < options.length; i++) {
      options[i].label = `${options[i][locale]}`;
      options[i].value = options[i]?.alpha2;
    }
  }
  return options;
};

const defaultOptions = [
  {
    id: 1,
    firstname: '',
    lastname: '',
    label: '',
    value: null,
  },
];

const AutocompleteSelect = ({
  label,
  setValue,
  height,
  width,
  name,
  isMultiple = false,
  disabled = false,
  error,
  isStepper,
  defaultValue,
  clearErrors,
  isRequired = false,
  className,
  isCountries = false,
  options = defaultOptions,
}: AutocompleteSelectT) => {
  const { locale } = useLocale();
  const updateInfo = useGeneralInfoStore((state) => state.updateInfo);
  const generalInfos: any = useGeneralInfoStore((state) => state.generalInfos);
  const optionsList = isCountries
    ? convertCountriesData(options, locale)
    : convertOptionsData(options);
  const [autocplValue, setAutocplValue] = useState<any>(
    isMultiple ? [] : defaultValue
  );
  useEffect(() => {
    name === 'broker' && setAutocplValue({ value: '', label: '' });
    name === 'intermediateBroker' && setAutocplValue({ value: '', label: '' });
    updateInfo({
      name: 'broker',
      value: '',
    });
    updateInfo({
      name: 'intermediateBroker',
      value: '',
    });
  }, [generalInfos.agency]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <span className="text-xs text-zinc-500">
        {' '}
        {label} {isRequired && <span className="text-red-500">*</span>}
      </span>
      <FormControl error={error} fullWidth>
        <Autocomplete
          disabled={disabled}
          defaultValue={defaultValue}
          multiple={isMultiple}
          disablePortal
          className={className}
          options={optionsList}
          onFocus={() => clearErrors(name)}
          value={autocplValue}
          sx={{
            width: width ? width : '416px',
            height: height ? height : '44px',
            borderRadius: '5px',
            border: error && '0.1px solid red ',
            background: disabled ? '#0001' : 'transparent',
          }}
          onChange={(event: any, newValue: any) => {
            if (newValue === null) {
              !isMultiple && setAutocplValue({ value: '', label: '' });
              setValue(name, '');
              updateInfo({
                name,
                value: '',
              });
            } else {
              const value = isCountries ? newValue.alpha2 : newValue.id;
              const valueToUpdate = newValue.label;
              isMultiple
                ? setAutocplValue([...newValue])
                : setAutocplValue({ value, label: newValue.label });
              isStepper &&
                updateInfo({
                  name,
                  value: String(valueToUpdate),
                });
              setValue(name, value);
            }
          }}
          renderInput={(params: any) => (
            <TextField style={{ height: '12px !important' }} {...params} />
          )}
        />
      </FormControl>
    </Box>
  );
};
export default AutocompleteSelect;
