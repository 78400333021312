export const _canvasStyle = {
  background: 'white',
  position: 'relative',
};
// box Style
const _boxStyle = {
  position: 'relative',
  border: '1px solid #E6E8EC',
  borderRadius: '3px',
  padding: '10px',
  width: '200px',
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  background: '#FFFFFF',
  fontSize: '14px',
  lineHeight: '120%',
};
export default _boxStyle;
