import {
  Button,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { User } from '@sentry/types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import Card from '../../../components/card';
import TabGridTag from '../../../features/tags/tabGridTag';
import { ApiListing } from '../../../types/api';
import { getuserAgency } from '../../users/utils';

export default function Users() {
  const { t } = useTranslation('common');
  const { data } = useSWR<ApiListing<User>>(
    `/users/assigned?page=1&itemsPerPage=6`
  );

  const rows = data ? data['hydra:member'] : null;
  const totalUsers = data ? data['hydra:totalItems'] : 0;
  return (
    <Card sx={{ bgcolor: 'background.default', color: 'text.primary' }}>
      <Card.Header
        sx={{
          bgcolor: 'background.paper',
          color: 'text.primary',
        }}
      >
        <Typography variant="subtitle1" fontWeight={500}>
          {`${t('Users')} (${totalUsers})`}
        </Typography>
        <Link to="/users">
          <Button> {t('showAll')}</Button>
        </Link>
      </Card.Header>

      <Card.Body className="p-0">
        <TableContainer>
          <Table>
            <TableBody>
              {(!data ? Array.from(new Array(3)) : rows)?.map((item, i) => (
                <TableRow
                  key={i}
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '2fr 2fr 2fr 2fr ',
                    borderBottom: '1px solid rgb(230, 232, 246)',
                    alignItems: 'center',
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  {item ? (
                    <TableCell
                      sx={{ borderBottom: 'none' }}
                      className="truncate"
                    >
                      <Tooltip
                        title={`${item.firstname} ${item.lastname}`}
                        arrow
                        placement="top"
                      >
                        <span>{`${item.firstname} ${item.lastname}`}</span>
                      </Tooltip>
                    </TableCell>
                  ) : (
                    <TableCell className="w-full">
                      <Skeleton width="100%" />
                    </TableCell>
                  )}
                  {item ? (
                    <TableCell sx={{ borderBottom: 'none' }}>
                      {item.isSuperAdmin ? (
                        <TabGridTag
                          key={'superAdmin'}
                          textContent={'Super admin'}
                          className="m-0 !text-xs"
                          color="#ff0000"
                        />
                      ) : (
                        item.roles.map((role: string) => (
                          <TabGridTag
                            key={role}
                            textContent={
                              role.toLocaleLowerCase().includes('admin')
                                ? 'Admin'
                                : 'Agent'
                            }
                            className="m-0.5 !text-xs"
                            color={
                              role.toLocaleLowerCase().includes('admin')
                                ? '#ED6C02'
                                : '#4CAF50'
                            }
                          />
                        ))
                      )}
                    </TableCell>
                  ) : (
                    <TableCell className="w-full">
                      <Skeleton width="100%" />
                    </TableCell>
                  )}
                  {item ? (
                    <TableCell
                      sx={{ borderBottom: 'none' }}
                      className="truncate"
                    >
                      <Tooltip
                        title={getuserAgency(item)}
                        arrow
                        placement="top"
                      >
                        {getuserAgency(item)}
                      </Tooltip>
                    </TableCell>
                  ) : (
                    <TableCell className="w-full">
                      <Skeleton width="100%" />
                    </TableCell>
                  )}
                  {item ? (
                    <TableCell
                      sx={{ borderBottom: 'none' }}
                      className="truncate"
                    >
                      <Tooltip title={item.email} arrow placement="top">
                        <span className="text-[#3446B9] underline">
                          {item.email}
                        </span>
                      </Tooltip>
                    </TableCell>
                  ) : (
                    <TableCell className="w-full">
                      <Skeleton width="100%" />
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card.Body>
    </Card>
  );
}
