import { Box, Button } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
type Props = {
  disabled?: boolean;
};
function NextButton({ disabled = false }: Props) {
  return (
    <Button
      disabled={disabled}
      type="submit"
      variant="contained"
      className="flex justify-center items-center  w-[104px]  "
      sx={{
        background: '#3446B9',
      }}
    >
      <Box className="flex justify-center items-center" gap="16px">
        <p className="capitalize">Next</p>
        <KeyboardBackspaceIcon
          className="rotate-180 w-4 h-4"
          fontSize="small"
        />
      </Box>
    </Button>
  );
}

export default NextButton;
