import { Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';

type Props = {
  length?: number;
  avatarGroup?: any;
};
export default function GroupAvatars({ length, avatarGroup }: Props) {
  if (!avatarGroup.length) return null;
  return (
    <AvatarGroup max={length}>
      {avatarGroup.map(({ avatar, id, firstname, lastname }: any) => (
        <Tooltip
          key={avatar + id}
          title={`${firstname} ${lastname}`}
          arrow
          placement="top"
        >
          <Avatar
            className="cursor-default"
            key={avatar + id}
            alt="user"
            src={avatar}
          />
        </Tooltip>
      ))}
    </AvatarGroup>
  );
}
