export const removeDuplicates = (arr: number[]) => {
  const uniqueArr = [];
  // loop through the array
  for (let i = 0; i < arr.length; i++) {
    const currentItem = arr[i];
    const indexOfDuplicate = arr.indexOf(currentItem!, i + 1);
    // if the array has a duplicated number remove both of the items
    if (indexOfDuplicate !== -1) {
      // remove both of the duplicated items
      arr.splice(indexOfDuplicate, 1);
      arr.splice(i, 1);
      i -= 2;
    } else {
      // add the item to the array
      uniqueArr.push(currentItem);
    }
  }
  // filter the undefined values from the items
  const arrayToReturn = uniqueArr
    .filter((el) => el !== undefined)
    .filter((item, index, self) => self.indexOf(item) === index);
  // return a new array that contains only uniq values
  // return [...new Set(arrayToReturn)];

  return arrayToReturn;
};
