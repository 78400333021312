import { Box, Grid } from '@mui/material';
import GoBackTag from '../../../features/tags/goBackTag';
import PromotionDescription from './description';
import SinglePromotionHeader from './header';
import PromotionProperties from './properties';
import PromotionPlan from './plan';
// import PropertyContact from '../../properties/singleProperty/propertyContact';
// import PropertyGallery from '../../properties/singleProperty/propertyInfos/propertyGallery';
// import PropertyFeatures from '../../properties/singleProperty/propertyInfos/propertyFeatures';
import PromotionPresentation from './presentation';

function SinglePromotion() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <div>
        <GoBackTag link="/promotions" />

        <SinglePromotionHeader />
      </div>

      <Grid container spacing={1}>
        <Grid item xs={8} className="flex !flex-col gap-8">
          <PromotionDescription />
          <PromotionProperties />
          {/* <PropertyGallery /> */}
          <PromotionPlan />
        </Grid>
        <Grid item xs={4} className="flex !flex-col gap-8">
          {/* <PropertyContact /> */}
          <PromotionPresentation />
          {/* <PropertyFeatures featuresStyle="!grid-cols-2" /> */}
        </Grid>
      </Grid>
    </Box>
  );
}

export default SinglePromotion;
