import generaleInfosState, {
  initialValueT,
  propertyInfosState,
  propertyStepT,
  StoreT,
} from './types';
import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export const initialGeneralInfos: initialValueT = {
  status: '',
  agency: '',
  department: '',
  availability: '',
  availableFrom: '',
  availableTo: '',
  category: '',
  subCategory: '',
  Ref: '',
  broker: '',
  responsableRate: '',
  brokerRate: '',
  intermediateBrokerRate: '',
  intermediateBroker: '',
  country: '',
};
export const initialPropertyInfos: propertyStepT = {
  displayedPrice: 0,
  pricePerMeter: 0,
  parkingPrice: 0,
  floor: 0,
  balcony: 0,
  terrace: 0,
  lift: false,
  pool: false,
  garden: false,
  terraceSurface: 0,
  gardenSurface: 0,
  rooms: 0,
  bedrooms: 0,
  bathrooms: 0,
  livingArea: 0,
  usefulArea: 0,
  landSurface: 0,
  parkingNumbers: 0,
  parkingAvailable: false,
  parkingBoxesAvailable: false,
  parkingBoxesNumbers: 0,
  originalPrice: 0,
};

const useStore = create<StoreT>()(
  devtools(
    persist(
      (set) => ({
        activeStep: 0,
        handleNext: () =>
          set((state) => ({
            ...state,
            activeStep: state.activeStep + 1,
          })),
        handlePrev: () =>
          set((state) => ({
            ...state,
            activeStep: state.activeStep - 1,
          })),
        reset: () =>
          set(() => ({
            state: initialGeneralInfos,
            activeStep: 0,
          })),
      }),
      { name: 'step' }
    )
  )
);
export default useStore;

const useGeneralInfoStore = create<generaleInfosState>()(
  devtools(
    persist(
      (set) => ({
        generalInfos: initialGeneralInfos,
        updateInfo: (action: { name: string; value: string }) => {
          set((state: generaleInfosState) => ({
            ...state,
            generalInfos: {
              ...state.generalInfos,
              [action.name]: action.value,
            },
          }));
        },
      }),
      { name: 'general-infos' }
    )
  )
);
const usePropertyInfoStore = create<propertyInfosState>()(
  devtools(
    persist(
      (set) => ({
        propertyInfos: initialPropertyInfos,
        updateInfo: (action: { name: string; value: string }) => {
          set((state: propertyInfosState) => ({
            ...state,
            propertyInfos: {
              ...state.propertyInfos,
              [action.name]: action.value,
            },
          }));
        },
      }),
      { name: 'property-infos' }
    )
  )
);
// const useGeneralInfoStore = create<generaleInfosState>()(
//   devtools(
//     persist(
//       (set) => ({
//         generalInfos: initialGeneralInfos,
//         updateInfo: (action: { name: string; value: string }) => {
//           set((state: generaleInfosState) => ({
//             ...state,
//             generalInfos: {
//               ...state.generalInfos,
//               [action.name]: action.value,
//             },
//           }));
//         },
//       }),
//       { name: 'general-infos' }
//     )
//   )
// );
// const useGeneralInfoStore = create<generaleInfosState>()(
//   devtools(
//     persist(
//       (set) => ({
//         generalInfos: initialGeneralInfos,
//         updateInfo: (action: { name: string; value: string }) => {
//           set((state: generaleInfosState) => ({
//             ...state,
//             generalInfos: {
//               ...state.generalInfos,
//               [action.name]: action.value,
//             },
//           }));
//         },
//       }),
//       { name: 'general-infos' }
//     )
//   )
// );
// const useGeneralInfoStore = create<generaleInfosState>()(
//   devtools(
//     persist(
//       (set) => ({
//         generalInfos: initialGeneralInfos,
//         updateInfo: (action: { name: string; value: string }) => {
//           set((state: generaleInfosState) => ({
//             ...state,
//             generalInfos: {
//               ...state.generalInfos,
//               [action.name]: action.value,
//             },
//           }));
//         },
//       }),
//       { name: 'general-infos' }
//     )
//   )
// );
// const useGeneralInfoStore = create<generaleInfosState>()(
//   devtools(
//     persist(
//       (set) => ({
//         generalInfos: initialGeneralInfos,
//         updateInfo: (action: { name: string; value: string }) => {
//           set((state: generaleInfosState) => ({
//             ...state,
//             generalInfos: {
//               ...state.generalInfos,
//               [action.name]: action.value,
//             },
//           }));
//         },
//       }),
//       { name: 'general-infos' }
//     )
//   )
// );
// const useGeneralInfoStore = create<generaleInfosState>()(
//   devtools(
//     persist(
//       (set) => ({
//         generalInfos: initialGeneralInfos,
//         updateInfo: (action: { name: string; value: string }) => {
//           set((state: generaleInfosState) => ({
//             ...state,
//             generalInfos: {
//               ...state.generalInfos,
//               [action.name]: action.value,
//             },
//           }));
//         },
//       }),
//       { name: 'general-infos' }
//     )
//   )
// );

export { useGeneralInfoStore, usePropertyInfoStore };
