/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useReducer } from 'react';
import { initialValues } from '../const/propertyInitialValues';

export declare type ValidationSchema = Record<
  string,
  {
    value?: any;
  }
>;

export type ContextProps = {
  activeStep: number;
  formValues: [
    {
      value?: any;
    }
  ];
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    checked?: boolean
  ) => void;
  handleNext: () => void;
  handleBack: () => void;
};

export const StepperContext = createContext<ContextProps | any>({
  activeStep: 0,
  formValues: initialValues,
  handleChange() {},
  handleNext() {},
  handleBack() {},
});

interface ProviderProps {
  children: React.ReactNode;
}

type State = {
  activeStep: number;
  formValues: ValidationSchema;
};

type Action =
  | { type: 'increase' }
  | { type: 'decrease' }
  | { type: 'form-value'; name: string; value: any };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'increase':
      return {
        ...state,
        activeStep: state.activeStep + 1,
      };
    case 'decrease':
      return {
        ...state,
        activeStep: state.activeStep - 1,
      };
    case 'form-value':
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.name]: {
            ...state.formValues[action.name],
            value: action.value,
          },
        },
      };

    default:
      return state;
  }
}
export function StepsProvider({ children }: ProviderProps) {
  const [{ activeStep, formValues }, dispatch] = useReducer(reducer, {
    activeStep: 1,
    formValues: initialValues,
  });

  // Proceed to next step
  const handleNext = () => dispatch({ type: 'increase' });
  // Go back to prev step
  const handleBack = () => dispatch({ type: 'decrease' });

  // Handle form change
  const handleChange = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    await dispatch({ type: 'form-value', name, value });
  };

  return (
    <StepperContext.Provider
      value={{
        activeStep,
        formValues,
        handleChange,
        handleNext,
        handleBack,
      }}
    >
      <div className="mui-step-form">{children}</div>
    </StepperContext.Provider>
  );
}
