import * as yup from 'yup';

const regex =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

export const AddAgencyFormValidation = yup.object().shape(
  {
    // city: yup.string().when(['service'], {
    //   is: (value: string) => value === '' || value === undefined,
    //   then: yup.string().required('city_required'),
    //   otherwise: yup.string(),
    // }),
    website: yup
      .string()
      .transform((v) => (v === null ? '' : v))
      .nullable(true)
      .when('website', {
        is: (value: string) => value !== '' && value,
        then: yup.string().matches(regex, 'enter_valid_website'),
        otherwise: yup.string(),
      }),
    name: yup.string().trim().required('name_required'),
    email: yup.string().email('enter_valid_email'),
    phone: yup.string().nullable(true),
    logo: yup.string().nullable(true),
  },
  [
    ['city', 'service'],
    ['website', 'website'],
  ]
);
