import { ReactElement } from 'react';
import Aside from './aside';
import Header from './header';
import MobileHeader from './mobile/header';
import useMediaQuery from '../hooks/useMediaQuery';
import { Box } from '@mui/material';

export default function Layout({
  children: main,
}: {
  children: ReactElement | ReactElement[];
}) {
  const { matches } = useMediaQuery();
  return (
    <Box
      sx={{ bgcolor: 'background.default', color: 'text.primary' }}
      className={`flex flex-col h-screen max-h-screen overflow-hidden   `}
    >
      <Box className="flex flex-1 overflow-hidden h-full">
        {matches('up', 'md') && (
          <Box component="aside" className="flex-col w-60 border-r  ">
            <Aside />
          </Box>
        )}

        <Box className="flex flex-1 flex-col">
          <Box className=" h-16 px-4 lg:px-10 border-b  overflow-hidden">
            {matches('up', 'md') ? <Header /> : <MobileHeader />}
          </Box>
          <Box
            component="main"
            className="flex flex-col flex-1 overflow-y-auto px-4 md:px-6 py-8"
          >
            {main}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
