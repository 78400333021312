import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import StepForm from './components/StepForm';
import GoBackTag from '../../../features/tags/goBackTag';
import './index.css';

const theme = createTheme();

const Stepper = () => (
  <div id="stepper_page">
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="div" maxWidth="xl">
        <GoBackTag
          className="text-sm leading-4 font-medium not-italic color-[#1D1D1F]	"
          link="/properties"
          onClick={() => localStorage.removeItem('createdPropertyId')}
        />
        <h1
          style={{ lineHeight: '100%' }}
          className=" text-[32px] font-normal not-italic color-[#1D1D1F]"
        >
          Add a new real estate object
        </h1>
        <Paper className="mt-10" variant="outlined">
          <StepForm />
        </Paper>
      </Container>
    </ThemeProvider>
  </div>
);

export default Stepper;
