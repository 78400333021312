import {
  Avatar,
  Box,
  Button,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';
import { MouseEvent, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import Menu from '@mui/material/Menu';
// import SearchBar from './search';
import { Link } from 'react-router-dom';
import SettingsIcon from '../../features/icons/settingsIcon';
import NotificationIcon from '../../features/icons/notificationIcon';
import HelpInterrogationIcon from '../../features/icons/helpInterrogationIcon';
import LogoutIcon from '../../features/icons/logoutIcon';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useThemeMode } from '../../hooks/useThemeMode';
import { http } from '../../libs/axios';

const dummyPath = [
  {
    name: 'profileSettings',
    link: '/profile',
    icon: <SettingsIcon />,
  },
  {
    name: 'Notifications',
    link: '/notifications',
    icon: <NotificationIcon />,
  },
  {
    name: 'Support',
    link: '/support',
    icon: <HelpInterrogationIcon />,
  },
];

export default function Header() {
  const {
    user: { lastname, firstname, avatar },
    logout,
  } = useAuth();

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = async () => {
    await http.post('/logout');
    logout();
  };
  const { updateMode, mode } = useThemeMode();
  return (
    <Box className="flex justify-between items-center h-full w-full">
      {/* <SearchBar /> */}
      <div className="flex flex-1 h-full"></div>
      <Button
        onClick={handleClick}
        className="flex gap-x-3 "
        size="small"
        sx={{
          color: 'text.primary',
          '& .MuiButton-endIcon': { ml: 0 },
          borderLeft: '1px solid #E6E8EC',
          paddingLeft: '15px',
        }}
        endIcon={<ArrowDropDownIcon />}
      >
        <Avatar sx={{ width: 30, height: 30 }} src={avatar} alt="" />

        <Typography
          className="md:flex hidden "
          fontWeight={500}
          sx={{
            width: '100%',
            textAlign: 'left',
            color: 'text.primary',
          }}
        >
          {firstname[0] + '.' + lastname}
        </Typography>
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {dummyPath.map(({ name, link, icon }, i) => (
          <MenuItem
            key={i}
            onClick={handleClose}
            sx={{ display: 'flex', gap: '16px' }}
          >
            <Link to={link} className="flex gap-4 items-center justify-start">
              {icon}
              {t(`usersPage:${name}`)}
            </Link>
          </MenuItem>
        ))}
        <MenuItem onClick={handleLogout} sx={{ display: 'flex', gap: '16px' }}>
          <LogoutIcon />
          <Box component="span">{t('auth:logout')}</Box>
        </MenuItem>
      </Menu>
      <IconButton
        sx={{ ml: 1 }}
        onClick={() => updateMode(mode === 'dark' ? 'light' : 'dark')}
        color="inherit"
      >
        {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </Box>
  );
}
