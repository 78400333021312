import useSWR from 'swr';
import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import TableGridX from '../../components/tableGridX';
import useTranslationForTableGridXConfig from '../../hooks/useTranslationForTableGridX';
import { contactsListingConfig } from '../../components/material-ui/tablesConfig/contactsListingConfig';
import { useTranslation } from 'react-i18next';

const Contacts = () => {
  const isLarge = useMedia('(min-width: 1640px)');
  const [page, setPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(7);
  const { t } = useTranslation('usersPage');
  const { data } = useSWR(`/owners?page=${page}&itemsPerPage=${itemsPerPage}`);

  useEffect(() => setItemsPerPage(isLarge ? 11 : 7), [isLarge]);
  const contactsListingColumns = useTranslationForTableGridXConfig([
    contactsListingConfig,
    'usersPage',
  ]);
  if (!data) return null;

  return (
    <Stack component="section" sx={{ width: '100%', height: '100%' }}>
      <Box className="mb-12">
        <Typography variant="h3">{t('Contacts')}</Typography>

        <Typography variant="subtitle1" className="text-sm text-gray-400">
          {`${data.length} ${t('entries_found')}`}
        </Typography>
      </Box>
      {data && (
        <Box className="w-full h-full ">
          <TableGridX
            rows={data}
            columns={contactsListingColumns}
            autoPageSize
            pageSize={itemsPerPage}
            page={page - 1}
            paginationMode="server"
            rowCount={data.length}
            onPageChange={(page: number) => {
              setPage(page + 1);
            }}
          />
        </Box>
      )}
    </Stack>
  );
};

export default Contacts;
