import { Box, Typography } from '@mui/material';

import { FieldErrors, UseFormRegister } from 'react-hook-form';
import InputFormField from '../../../../../../../components/form/inputFormField';
import { useGeneralInfoStore } from '../../../store';

type Props = {
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
};
const propertyDetailsInfos = [
  {
    id: 0,
    label: 'Original Price*',
    name: 'originalPrice',
  },
  {
    id: 1,
    label: 'Displayed Price*',
    name: 'displayedPrice',
  },
  {
    id: 2,
    label: 'Price/m²*',
    name: 'pricePerMeter',
  },
  {
    id: 3,
    label: 'Price with parking',
    name: 'parkingPrice',
  },
  {
    id: 4,
    label: 'Charges',
    name: 'charges',
  },
];

const PricesDetails = ({ register, errors }: Props) => {
  const updateInfo = useGeneralInfoStore((state) => state.updateInfo);

  return (
    <Box className="grid grid-cols-[2fr_4fr] mt-12 mb-16">
      <Typography className="stepTitle">Prices</Typography>
      <Box width="100%" display="flex" flexDirection="column" gap="48px">
        <Box width="100%" className="grid grid-cols-3" gap="48px">
          {propertyDetailsInfos.map(({ id, label, name }) => (
            <InputFormField
              key={label + id}
              label={label}
              // placeholder={`Enter ${label}`}
              id="outlined-start-adornment"
              type="number"
              sx={{ border: errors[name] && '1px solid red' }}
              inputProps={{
                step: '0.01',
              }}
              //   InputProps={{
              //     endAdornment: (
              //       <InputAdornment position="start">ch</InputAdornment>
              //     ),
              //   }}
              {...register(name, {
                onChange: (e) =>
                  updateInfo({
                    name: label.toLowerCase(),
                    value: String(e.target.value),
                  }),
              })}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default PricesDetails;
