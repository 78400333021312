import { Box, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function Publication() {
  return (
    <Box
      display="flex"
      flexDirection="row"
      className="py-10 border-b border-[#E6E8EC]"
      gap="80px"
    >
      <Typography width="20%" className="stepTitle">
        Publication
      </Typography>
      <Box width="100%" display="flex" flexDirection="column" gap="48px">
        <Typography className="text-base !mt-1">
          Please select which location you would like to publish per channel.
        </Typography>
        <Box className="flex items-center gap-4">
          <Typography className="!text-base !min-w-[100px]">
            Website :{' '}
          </Typography>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            defaultValue="real location"
          >
            <FormControlLabel
              value="real location"
              control={<Radio />}
              label="Real location"
            />
            <FormControlLabel
              value="alternate location"
              control={<Radio />}
              label="Alternate location"
            />
          </RadioGroup>
        </Box>
        <Box className="flex items-center gap-4">
          <Typography className="!text-base !min-w-[100px]">
            Brochure :{' '}
          </Typography>

          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            defaultValue="real location"
          >
            <FormControlLabel
              value="real location"
              control={<Radio />}
              label="Real location"
            />
            <FormControlLabel
              value="alternate location"
              control={<Radio />}
              label="Alternate location"
            />
          </RadioGroup>
        </Box>
        <Box className="flex items-center gap-4">
          <Typography className="!text-base !min-w-[100px]">
            Portals :{' '}
          </Typography>

          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            defaultValue="real location"
          >
            <FormControlLabel
              value="real location"
              control={<Radio />}
              label="Real location"
            />
            <FormControlLabel
              value="alternate location"
              control={<Radio />}
              label="Alternate location"
            />
          </RadioGroup>
        </Box>
      </Box>
    </Box>
  );
}
