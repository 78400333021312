import TableGridX from '../../../components/tableGridX';
import useTranslationForTableGridXConfig from '../../../hooks/useTranslationForTableGridX';
// import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { propertiesListingConfig } from '../../../components/material-ui/tablesConfig/propertiesListingConfig';
import { Dispatch, SetStateAction } from 'react';
import IProperty from '../../../types/property';

type Props = {
  itemsPerPage: number;
  data: any;
  handlePage: Dispatch<SetStateAction<number>>;
  page: number;
  setSelectedRows: any;
};
const PropertiesListingTable = ({
  itemsPerPage,
  data,
  handlePage,
  page,
  setSelectedRows,
}: Props) => {
  const propertiesListingColumns = useTranslationForTableGridXConfig([
    propertiesListingConfig,
    'usersPage',
  ]);
  const rows = data['hydra:member'];

  if (!data) return null;

  return (
    <Box className="w-full min-h-[80%]">
      <TableGridX
        disableSelectionOnClick={true}
        disableColumnSelector={true}
        rows={rows}
        columns={propertiesListingColumns}
        autoPageSize
        pageSize={itemsPerPage}
        page={page - 1}
        paginationMode="server"
        rowCount={data['hydra:totalItems']}
        onPageChange={(page: number) => {
          handlePage(page + 1);
        }}
        checkboxSelection
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows = rows.filter((row: IProperty) =>
            selectedIDs.has(row.id)
          );

          setSelectedRows(selectedRows);
        }}
      />
    </Box>
  );
};
export default PropertiesListingTable;
