import { Box, Snackbar, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { countries } from '../../../../const/countries';
import useLocale from '../../../../hooks/useLocale';
import { FileNode } from '../../../../types/agency';
import { getCountry } from '../../tree/utils';
import GroupAvatars from './avatarGroup';
// import GroupAvatars from './avatarGroup';
import InfoLabels from './infoLabels';

type InfosBoxProps = {
  infos?: FileNode;
  treeData: FileNode;
};

const InfosBox = ({ infos, treeData }: InfosBoxProps) => {
  const { locale } = useLocale();
  const [infosCountry, setInfosCountry] = useState('');
  const [infosFlag, setInfosFlag] = useState('');
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('agencyPage');

  useEffect(() => {
    if ((infos && infos.country) || treeData.country) {
      const countryToConvert = infos?.country || treeData?.country || '';
      setInfosFlag(countryToConvert.toLowerCase());
      const newCountry = getCountry(countries, countryToConvert)[locale];
      setInfosCountry(newCountry);
    }
  }, [locale, infos?.country]);

  const handleCopyClick = () => {
    setOpen(true);
    if (treeData && treeData.agencyKey) {
      navigator.clipboard.writeText(treeData.agencyKey);
    }
  };

  const key = infos ? infos.agencyKey : treeData.agencyKey;
  return (
    <Box
      sx={{
        width: '45vw',
        height: '100vh',
        background: '#FFFFFF',
        border: '1px solid #E6E8EC',
        padding: '50px 42px',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      {infos?.categoryEntity === 'Filial' && (
        <Box sx={{ marginBottom: '40px' }}>
          <button
            disabled
            style={{
              display: 'flex',
              gap: '8px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
              border: '1px solid #E6E8EC',
              padding: '8px 12px',
              fontSize: '16px',
            }}
          >
            <div className={`react-tel-input flag ${infosFlag}`}></div>
            {infosCountry}
          </button>
        </Box>
      )}
      {}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: '16px',
        }}
      >
        {infos?.categoryEntity === 'Department' && (
          <InfoLabels
            label="Service"
            value={infos ? infos.service : treeData.service}
          />
        )}
        {infos?.categoryEntity === 'Agency' && (
          <InfoLabels label="City" value={infos ? infos.city : treeData.city} />
        )}
        <InfoLabels label="Name" value={infos ? infos.name : treeData.name} />
        <InfoLabels
          label="Location / address"
          value={infos ? infos.location : treeData.location}
        />
        <InfoLabels label="Zip code" value={infos ? infos.cp : treeData.cp} />
        <InfoLabels
          label="Company Email"
          value={infos ? infos.email : treeData.email}
          asLink
          asEmail
        />
        <InfoLabels
          label="Phone"
          value={infos ? '+' + infos.phone : '+' + treeData.phone}
        />
        <InfoLabels
          label="Website link"
          value={infos ? infos.website : treeData.website}
          asLink
        />
        <Box className="!w-1/2 flex items-center">
          <InfoLabels
            label="Agency key"
            // title={infos ? infos.agencyKey : treeData.agencyKey}
            className=" cursor-pointer truncate ... !w-1/2"
            value={infos ? infos.agencyKey : treeData.agencyKey}
            onClick={handleCopyClick}
            icon={
              key && (
                <Tooltip title="copy" arrow placement="top">
                  <span className=" cursor-pointer " onClick={handleCopyClick}>
                    <ContentCopyIcon className="!w-4 !h-4" />
                  </span>
                </Tooltip>
              )
            }
          />

          <Snackbar
            message="Copied to clibboard"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={2000}
            onClose={() => setOpen(false)}
            open={open}
          />
        </Box>
        {infos?.users?.length ? (
          <Box>
            <Typography
              sx={{ color: '#1D1D1F', fontSize: '12px', opacity: 0.5 }}
            >
              {t('agencyUsers')}
            </Typography>
            <GroupAvatars avatarGroup={infos?.users} length={10} />
            {/* <Link to="/users" className="underline text-[#3446B9]"> */}
            {/* </Link> */}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default InfosBox;
