import {
  //   GridColumnHeaderParams,
  GridColumns,
  GridValidRowModel,
} from '@mui/x-data-grid';
import HomeIcon from '../../../features/icons/homeIcon';
import BedroomIcon from '../../../features/icons/bedroomIcon';
import SquareIcon from '../../../features/icons/squareIcon';
import MountainIcon from '../../../features/icons/mountainIcon';
import PlusIcon from '../../../features/icons/plusIcon';
import VisibilityIcon from '@mui/icons-material/Visibility';
import UsersFilledIcon from '../../../features/icons/usersFilledIcon';
import UsersOutlinedIcon from '../../../features/icons/usersOutlinedIcon';
import HistoryIcon from '../../../features/icons/historyIcon';
import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PermissionIcon from '../../../features/icons/permissionIcon';
import EditIcon from '../../../features/icons/editIcon';
import { convertDipslayedPricePrice } from '../../../pages/properties/utils';
import {
  renderFullPropertyAdress,
  renderPropertyAdress,
} from '../../../helpers/utils';
import { Tooltip } from '@mui/material';
import TabGridTag from '../../../features/tags/tabGridTag';

const renderPropertyContact = (row: any) => {
  const { mainBroker, intermediateBroker, owner } = row;
  const contacts = [
    { ...mainBroker, roleContact: 'mainBroker' },
    { ...intermediateBroker, roleContact: 'intermediateBroker' },
    { ...owner, roleContact: 'owner' },
  ];

  return contacts.map(({ firstname, lastname, roleContact, id }) => {
    return (
      id && (
        <Tooltip
          title={`${firstname} ${lastname}`}
          arrow
          placement="top"
          key={id + Math.random()}
        >
          <Box
            className="flex flex-col w-8 h-9 mx-1
           p-1.5 rounded-xl bg-[#F5F5F7] cursor-pointer"
            // title={`${firstname} ${lastname}`}
          >
            <span className="text-[#3446B9] leading-3 m-auto">
              {firstname.charAt(0).toUpperCase()}
              {lastname.charAt(0).toUpperCase()}
            </span>
            <span className="text-[#1D1D1F] leading-5 text-[10px] m-auto">
              {roleContact === 'owner' ? 'own' : 'bro'}
            </span>
          </Box>
        </Tooltip>
      )
    );
  });
};

const renderPropertyAvatar = (row: any) => {
  const {
    media: { images },
  } = row;
  let propertyGalleriesArray: string[] = [];
  let firstImg = '';
  if (images && images.length > 0) {
    propertyGalleriesArray = images.filter(({ type }: any) =>
      type.includes('image')
    );
    if (propertyGalleriesArray.length > 0)
      firstImg = propertyGalleriesArray.map(({ url }: any) => url.thumbnail)[0];
  }
  const serviceName = row?.department?.service;
  let serviceLabel = serviceName;
  if (row && row.department && row.department.service) {
    const splitServiceName = serviceName.split(' ');
    if (splitServiceName.length > 1) {
      const serviceNameFirst = splitServiceName[0][0].toUpperCase();
      const serviceNameLast = splitServiceName[1][0].toUpperCase();
      serviceLabel = `${serviceNameFirst}${serviceNameLast}`;
    } else {
      serviceLabel = serviceName;
    }
  }

  return (
    <Box className="flex items-center relative">
      {row && row.department && row.department.service && (
        <TabGridTag
          textToolTip={`${row.department.name && row.department.name}`}
          textContent={serviceLabel}
          className="absolute bg-[#3446B9] font-bold -top-1 -left-1 border-0 "
          color="white"
          fontSize="8px"
        />
      )}
      <img src={firstImg} alt="media-pic" />
    </Box>
  );
};

const isLarge = localStorage.getItem('isLarge');
const isMacLarge = localStorage.getItem('isMacLarge');
const isMediumLarge = localStorage.getItem('isMediumLarge');
const isMedium = localStorage.getItem('isMedium');

export const propertiesListingConfig: GridColumns<GridValidRowModel> = [
  {
    field: 'media',
    width: isMedium === 'true' ? 80 : 100,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      return renderPropertyAvatar(row);
    },
  },
  {
    field: 'reference',
    width: isLarge === 'true' ? 150 : isMedium === 'true' ? 130 : 150,
    sortable: false,
    disableColumnMenu: true,
    filterable: false,
    renderHeader: () => (
      <Box className="flex flex-col ">
        <span className="leading-5">Reference</span>
        <span className="leading-5">Status</span>
      </Box>
    ),
    renderCell: ({ row }) => {
      return (
        <Box className="flex flex-col py-2 gap-2">
          <div
            className="flex py-1 px-2 rounded-md items-center"
            style={{ border: '1px solid #3446B9 ' }}
          >
            <PermissionIcon className="mr-2 col-span-1 " color="#3446B9" />{' '}
            <span className="text-[#3446B9] leading-5">{row.reference}</span>
          </div>
          <span className="text-[#4CAF50] leading-5 capitalize">
            {row.status}
          </span>
        </Box>
      );
    },
  },
  {
    field: 'category',
    width:
      isLarge === 'true'
        ? 400
        : isMacLarge === 'true'
        ? 350
        : isMediumLarge === 'true'
        ? 330
        : isMedium === 'true'
        ? 262
        : 212,
    sortable: false,
    filterable: false,
    renderHeader: () => (
      <span className="flex flex-col">
        <span className="leading-5">Category</span>
        <span className="leading-5">Location</span>
      </span>
    ),
    renderCell: ({ row }) => {
      return (
        <Box className="flex flex-col gap-2 w-full">
          <div>
            <span className="leading-5 capitalize bg-[#F5F5F7] rounded py-1 px-2">
              {`${row.category} - ${row.type}`}
            </span>
          </div>
          <Tooltip title={renderFullPropertyAdress(row)} arrow placement="top">
            {renderPropertyAdress(row)}
          </Tooltip>
        </Box>
      );
    },
  },
  {
    field: 'dipslayedPrice',
    headerName: 'Price',
    width:
      isLarge === 'true'
        ? 200
        : isMacLarge === 'true'
        ? 202
        : isMediumLarge === 'true'
        ? 165
        : 140,

    sortable: false,
    filterable: false,
    renderCell: ({ row }) => {
      return (
        <Tooltip
          title={
            convertDipslayedPricePrice(row?.dipslayedPrice?.toString()) + '.-'
          }
          arrow
          placement="top"
        >
          <span>
            {row.dipslayedPrice
              ? `CHF ${
                  convertDipslayedPricePrice(row?.dipslayedPrice?.toString()) +
                  '.-'
                }`
              : ''}
          </span>
        </Tooltip>
      );
    },
  },
  {
    field: 'rooms',
    headerName: 'Dimensions',

    width: isLarge === 'true' ? 150 : isMacLarge === 'true' ? 150 : 130,

    disableColumnMenu: true,

    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <Box className="flex justify-between gap-2 w-full">
        <Box className="flex flex-col gap-2">
          <Tooltip title={'Rooms'} arrow placement="top">
            <Box className="flex" sx={{ lineHeight: '20px' }}>
              <HomeIcon className="mr-2 col-span-1 " color="#6E6E72" />{' '}
              <span>{row.rooms}</span>
            </Box>
          </Tooltip>
          <Tooltip title="Bedrooms" arrow placement="top">
            <Box className="flex" sx={{ lineHeight: '20px' }}>
              <BedroomIcon className="mr-2 col-span-1 " color="#6E6E72" />{' '}
              <span>{row.bedrooms}</span>
            </Box>
          </Tooltip>
        </Box>
        <Box className="flex flex-col gap-2">
          <Tooltip title="Living area" arrow placement="top">
            <Box className="flex" sx={{ lineHeight: '20px' }}>
              <SquareIcon className="mr-2 col-span-1 " color="#6E6E72" />{' '}
              <span>{row.livingArea}</span>
            </Box>
          </Tooltip>
          <Tooltip title="Land surface" arrow placement="top">
            <Box className="flex" sx={{ lineHeight: '20px' }}>
              <MountainIcon className="mr-2 col-span-1 " color="#6E6E72" />{' '}
              <span>{row.landSurface}</span>
            </Box>
          </Tooltip>
        </Box>
      </Box>
    ),
  },
  {
    field: 'mainBroker',
    headerName: 'Contacts',
    width:
      isLarge === 'true'
        ? 180
        : isMacLarge === 'true'
        ? 160
        : isMediumLarge === 'true'
        ? 140
        : 130,

    sortable: false,
    disableColumnMenu: true,

    filterable: false,
    renderCell: ({ row }: any) => {
      return renderPropertyContact(row);
    },
  },
  {
    field: 'createdAt',
    headerName: 'date',
    width: isLarge === 'true' ? 250 : isMacLarge === 'true' ? 220 : 200,
    sortable: false,
    filterable: false,
    renderHeader: () => (
      <span
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <span className="leading-5">Date created</span>
        <span className="leading-5">Date updated</span>
      </span>
    ),
    renderCell: ({ row }) => (
      <Box className="flex flex-col gap-2">
        <span style={{ display: 'flex', lineHeight: '20px' }}>
          <PlusIcon className="mr-2 col-span-1" color="#6E6E72" />{' '}
          {row.createdAt}
        </span>
        <span style={{ display: 'flex', lineHeight: '20px' }}>
          <EditIcon className="mr-2 col-span-1" color="#6E6E72" />{' '}
          {/* {row.updatedAt} */}
          {row.createdAt}
        </span>
      </Box>
    ),
  },
  {
    field: '',
    headerName: 'Actions',
    width: isLarge === 'true' ? 148 : isMediumLarge === 'true' ? 125 : 120,
    disableColumnMenu: true,
    sortable: false,
    headerClassName: '!bg-white',
    renderCell: ({ id }) => (
      <>
        <Box className="flex gap-2">
          <Tooltip title="matching" arrow placement="top">
            <span>
              <UsersFilledIcon
                className="w-5 h-5 cursor-pointer"
                color="#3446B9"
              />
            </span>
          </Tooltip>
          <UsersOutlinedIcon
            className="w-5 h-5 cursor-pointer"
            color="#3446B9"
          />
          <Tooltip title="property history" arrow placement="top">
            <span>
              <HistoryIcon className="w-5 h-5 cursor-pointer" color="#3446B9" />
            </span>
          </Tooltip>
          <Link to={`/properties/${id}`}>
            <Tooltip title="property details" arrow placement="top">
              <span>
                <VisibilityIcon
                  className="w-5 h-5 cursor-pointer"
                  color="primary"
                />
              </span>
            </Tooltip>
          </Link>
        </Box>
      </>
    ),
  },
];
