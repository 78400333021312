import TextField from '@mui/material/TextField';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useLocale from '../../hooks/useLocale';
import * as locales from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material';
import { textFieldStyle } from '../../pages/properties/propertiesListing/propertiesFilter';
import { useMemo, useState } from 'react';
import { appTheme } from '../material-ui/theme';
import { useThemeMode } from '../../hooks/useThemeMode';
import { Box } from '@material-ui/core';

type DatePickerT = {
  label?: string;
  name?: string;
  className?: string;
  setValue?: any;
  isRequired?: boolean;
};

export default function DateRangePicker({
  label,
  name,
  className,
  isRequired = false,
  setValue,
}: DatePickerT) {
  const [currentDate, setCurrentDate] = useState<any>(null);
  const { locale } = useLocale();
  const coreLang =
    locale == 'fr'
      ? locales['frFR']
      : locale == 'de'
      ? locales['deDE']
      : locales['enUS'];

  const { mode } = useThemeMode();

  const theme = useMemo(() => createTheme(appTheme(mode)), [mode]);
  const tableGridTheme = createTheme(theme, coreLang);

  return (
    <ThemeProvider theme={tableGridTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box className="flex flex-col gap-1  ">
          <span className="text-xs text-zinc-500">
            {label} {isRequired && <span className="text-red-500">*</span>}
          </span>

          <MobileDatePicker
            className={className}
            value={currentDate}
            inputFormat="DD/MM/YYYY"
            onChange={(value) => {
              setCurrentDate(value);
              const options = {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              };
              const formattedDate = new Intl.DateTimeFormat(
                'en-US',
                options as any
              ).format(value);
              setValue(name, formattedDate);
            }}
            renderInput={(params) => (
              <TextField
                className="!rounded"
                {...params}
                size="small"
                sx={textFieldStyle}
              />
            )}
          />
        </Box>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
