import create from 'zustand';
interface FiltersPropertiesState {
  selectedAdvancedFilter: string[];
  setSelectedAdvancedFilter: (newSelectedAdvancedFilter: string[]) => void;
}

// const initialState = { users: [], unAssignedUsers: [] };
export const useFiltersProperties = create<FiltersPropertiesState>((set) => ({
  selectedAdvancedFilter: [],
  setSelectedAdvancedFilter: (newSelectedAdvancedFilter: string[]) => {
    set({
      selectedAdvancedFilter: newSelectedAdvancedFilter,
    });
  },
}));
