import { Box, Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/modal';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import WithModal, {
  InjectedModalState,
} from '../../../components/modal/widthModal';
import UserForm from '../addNewUserPage/userForm';
import { useAuth } from '../../../hooks/useAuth';
import { useEffect, useState } from 'react';

type Props = {
  nbUsers?: number;
  itemsPerPage: number;
};

function UsersHeader({ itemsPerPage, nbUsers = 0, ...rest }: Props) {
  const { t } = useTranslation('usersPage');
  const modalState = rest as InjectedModalState;

  const [showAddBtn, setBtn] = useState(false);
  const {
    user: { roles },
  } = useAuth();

  const isAdmin = JSON.parse(localStorage.getItem('isAdmin') as string);

  useEffect(() => {
    if (!isAdmin.isAdmin || roles.includes('ROLE_ADMIN_OPERATOR')) {
      setBtn(false);
    } else {
      setBtn(true);
    }
  }, [isAdmin, roles]);

  return (
    <Stack direction="row" justifyContent="space-between">
      <Box>
        <Typography variant="h3">{t('users')}</Typography>

        <Typography variant="subtitle1" className="text-sm text-gray-400">
          {`${nbUsers} ${t('entries_found')}`}
        </Typography>
      </Box>
      {showAddBtn && (
        <Box>
          <Modal
            width={{
              md: 500,
            }}
            title={t('Add_new_user')}
            buttonProps={{
              title: t('Add_new_user'),
              variant: 'contained',
              startIcon: <AddOutlinedIcon />,
            }}
            {...modalState}
          >
            <UserForm
              type="add"
              closeModal={modalState.closeModal}
              apiKey={'/users'}
              itemsPerPage={itemsPerPage}
            />
          </Modal>
        </Box>
      )}
    </Stack>
  );
}

export default WithModal(UsersHeader);
