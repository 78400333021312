import PropertyDetails from './propertyDetails';
import PropertyGallery from './propertyGallery';
import PropertyFeatures from './propertyFeatures';
import { Box } from '@mui/material';
import { useMemo } from 'react';

type Props = {
  data: any;
};

function PropertyInfos({ data }: Props) {
  const {
    media: { images },
  } = data;

  const propertyGalleries = useMemo(() => {
    let propertyGalleriesArray = null;
    propertyGalleriesArray = images;
    if (propertyGalleriesArray) {
      return propertyGalleriesArray.filter(({ type }: any) =>
        type.includes('image')
      );
    } else {
      return [];
    }
  }, [images]);

  const galleryImgs: string[] = propertyGalleries.map(
    ({ url }: any) => url.large
  );

  const firstImg: string = galleryImgs[0] as string;

  return (
    <Box className="w-full aspect-square flex flex-col gap-8">
      <PropertyDetails data={data} firstImg={firstImg} />
      {galleryImgs.length > 0 && (
        <PropertyGallery propertyGalleries={galleryImgs} />
      )}
      <PropertyFeatures data={data} />
    </Box>
  );
}

export default PropertyInfos;
