import { IconProps } from './locationIcon';

export default function TreeIcon({ className }: IconProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect width="24" height="24" rx="12" fill="#F5F5F7" />
      <path
        d="M6.25 6L10 6M10 6L17.75 6M10 6L10 11.5M14.5 17L10 17L10 11.5M10 11.5L14.5 11.5"
        stroke="#3446B9"
      />
      <circle cx="6" cy="6" r="0.5" fill="#3446B9" stroke="#3446B9" />
      <circle cx="15" cy="11.5" r="0.5" fill="#3446B9" stroke="#3446B9" />
      <circle cx="18" cy="6" r="0.5" fill="#3446B9" stroke="#3446B9" />
      <circle cx="15" cy="17" r="0.5" fill="#3446B9" stroke="#3446B9" />
    </svg>
  );
}
