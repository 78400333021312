import { Box, Typography } from '@mui/material';
import { MouseEventHandler } from 'react';

type Props = {
  label: string;
  title?: string;
  value?: string;
  className?: string;
  asLink?: boolean;
  asEmail?: boolean;
  icon?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLSpanElement>;
};

export default function InfoLabels({
  label,
  value,
  asEmail = false,
  asLink = false,
  title,
  className,
  onClick,
  icon,
}: Props) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {value && (
        <Typography
          className={className}
          title={title}
          variant="subtitle1"
          sx={{ color: '#1D1D1F', fontSize: '12px', opacity: 0.5 }}
          onClick={onClick}
        >
          {label}
        </Typography>
      )}
      <Box className="flex">
        {!asLink ? (
          <Typography
            title={title}
            className={className}
            variant="subtitle1"
            sx={{ color: '#1D1D1F', fontSize: '16px' }}
            onClick={onClick}
          >
            {value}
          </Typography>
        ) : (
          <a
            title={title}
            href={asEmail ? `mailto:${value}` : value}
            className="text-[#1D1D1F] text-base underline cursor-pointer"
            onClick={onClick}
          >
            {value}
          </a>
        )}
        {icon}
      </Box>
    </Box>
  );
}
