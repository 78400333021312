import { NavLink } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import DashboardIcon from '../../features/icons/dashboardIcon';
import AgencyIcon from '../../features/icons/agencyIcon';
import UserIcon from '../../features/icons/userIcon';
import PromoIcon from '../../features/icons/promoIcon';
import PropertiesIcon from '../../features/icons/propertiesIcon';
import WebsitesIcon from '../../features/icons/websitesIcon';
import { useTranslation } from 'react-i18next';
import LangSwitcher from '../footer/langSwitcher';
import { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import ContactIcon from '../../features/icons/contactIcon';

export default function Nav() {
  const { t } = useTranslation('common');
  const [currentLink, setCurrentLink] = useState('');
  useEffect(() => {
    setCurrentLink(window.location.pathname);
  }, [window.location.pathname]);
  const {
    user: { roles },
  } = useAuth();
  type LinkT = {
    path: string;
    name: string;
    icon: any;
  };
  const adminOperatorNavLinks = [
    {
      path: '/',
      name: t('Dashboard'),
      icon: (
        <DashboardIcon color={currentLink === '/' ? '#3446B9' : '#1D1D1F'} />
      ),
    },

    {
      path: '/users',
      name: t('Users'),
      icon: (
        <UserIcon color={currentLink === '/users' ? '#3446B9' : '#1D1D1F'} />
      ),
    },

    {
      path: '/promotions',
      name: t('Promotions'),
      icon: (
        <PromoIcon
          color={currentLink === '/promotions' ? '#3446B9' : '#1D1D1F'}
        />
      ),
    },
    {
      path: '/properties',
      name: t('Properties'),
      icon: (
        <PropertiesIcon
          color={currentLink === '/properties' ? '#3446B9' : '#1D1D1F'}
        />
      ),
    },
    {
      path: '/websites',
      name: t('Websites'),
      icon: (
        <WebsitesIcon
          color={currentLink === '/websites' ? '#3446B9' : '#1D1D1F'}
        />
      ),
    },
    {
      path: '/contacts',
      name: t('Contacts'),
      icon: (
        <ContactIcon color={currentLink === '/users' ? '#3446B9' : '#1D1D1F'} />
      ),
    },
  ];
  const adminNavLinks = [
    {
      path: '/',
      name: t('Dashboard'),
      icon: (
        <DashboardIcon color={currentLink === '/' ? '#3446B9' : '#1D1D1F'} />
      ),
    },

    {
      path: '/agencies',
      name: t('Agencies'),
      icon: (
        <AgencyIcon
          color={currentLink === '/agencies' ? '#3446B9' : '#1D1D1F'}
        />
      ),
    },

    {
      path: '/users',
      name: t('Users'),
      icon: (
        <UserIcon color={currentLink === '/users' ? '#3446B9' : '#1D1D1F'} />
      ),
    },
    {
      path: '/contacts',
      name: t('Contacts'),
      icon: (
        <ContactIcon
          color={currentLink === '/contacts' ? '#3446B9' : '#1D1D1F'}
        />
      ),
    },
    {
      path: '/promotions',
      name: t('Promotions'),
      icon: (
        <PromoIcon
          color={currentLink === '/promotions' ? '#3446B9' : '#1D1D1F'}
        />
      ),
    },
    {
      path: '/properties',
      name: t('Properties'),
      icon: (
        <PropertiesIcon
          color={currentLink === '/properties' ? '#3446B9' : '#1D1D1F'}
        />
      ),
    },
    {
      path: '/websites',
      name: t('Websites'),
      icon: (
        <WebsitesIcon
          color={currentLink === '/websites' ? '#3446B9' : '#1D1D1F'}
        />
      ),
    },
  ];
  const userNavLinks = [
    {
      path: '/',
      name: t('Dashboard'),
      icon: (
        <DashboardIcon color={currentLink === '/' ? '#3446B9' : '#1D1D1F'} />
      ),
    },
    {
      path: '/users',
      name: t('Users'),
      icon: (
        <UserIcon color={currentLink === '/users' ? '#3446B9' : '#1D1D1F'} />
      ),
    },
    {
      path: '/contacts',
      name: t('Contacts'),
      icon: (
        <ContactIcon
          color={currentLink === '/contacts' ? '#3446B9' : '#1D1D1F'}
        />
      ),
    },

    {
      path: '/promotions',
      name: t('Promotions'),
      icon: (
        <PromoIcon
          color={currentLink === '/promotions' ? '#3446B9' : '#1D1D1F'}
        />
      ),
    },
    {
      path: '/properties',
      name: t('Properties'),
      icon: (
        <PropertiesIcon
          color={currentLink === '/properties' ? '#3446B9' : '#1D1D1F'}
        />
      ),
    },
    {
      path: '/websites',
      name: t('Websites'),
      icon: (
        <WebsitesIcon
          color={currentLink === '/websites' ? '#3446B9' : '#1D1D1F'}
        />
      ),
    },
  ];
  const [navLinks, setNavLinks] = useState<LinkT[]>([]);

  const isAdmin = JSON.parse(localStorage.getItem('isAdmin') as string);

  useEffect(() => {
    if (isAdmin.isAdmin) {
      if (roles.includes('ROLE_ADMIN_OPERATOR')) {
        setNavLinks(adminOperatorNavLinks);
      } else {
        setNavLinks(adminNavLinks);
      }
    } else {
      setNavLinks(userNavLinks);
    }
  }, [currentLink]);
  return (
    <>
      <Box component="ul" className="flex flex-col pt-10">
        {navLinks.map(({ path, name, icon }, i) => (
          <Box component="li" key={i}>
            <NavLink
              to={path}
              className="leading-[100%] font-normal text-base flex gap-x-2 items-center h-12  px-8 "
            >
              {icon}
              <Typography>{name}</Typography>
            </NavLink>
          </Box>
        ))}
      </Box>
      <Box className="mt-auto w-full mb-6 px-8 flex items-center">
        <LangSwitcher />
      </Box>
    </>
  );
}
