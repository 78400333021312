import { userItem } from '../pages/agencies/components/userItem';
export interface FileNode {
  index?: number;
  logo?: string;
  categoryEntity: string;
  name: string;
  indexChild?: number;
  level?: number;
  children?: FileNode[];
  users?: userItem[] | [];
  id: number;
  service?: string;
  city?: string;
  country?: string | '';
  brand?: string;
  title?: any;
  subtitle?: any;
  expanded?: boolean;
  cp?: string;
  email?: string;
  phone?: string;
  website?: string;
  location?: string;
  agencyKey?: string;
  site: string;
}
export interface Offset {
  x: number;
  y: number;
}
export interface Agency {
  service?: string;
  name: string;
  id: number;
  country: string | '';
  location: string;
  phone: string;
  email: string;
  cp: number;
  website: string;
  site: string;
  categoryEntity: string;
  city?: string;
  brand?: string;
  logo?: string;
  users?: Partial<userItem[]>;
  agencyKey?: string;
}

export interface AgencyDrawerContent {
  currentNode: Agency;
  type: string;
}

export const departmentService: string[] = [
  'Buy',
  'Rent',
  'Commercial Buy',
  'Commercial Rent',
];

export const dataAgency: any = {
  '@context': '/api/contexts/Company',
  '@id': '/api/companies/1',
  '@type': 'Company',
  name: 'Hills and Sons',
  email: 'amir.souissi@yahoo.fr',
  location: '562 Amir Circle\nSchusterside, WV 04774-2048',
  phone: '1-246-748-2472x9134',
  cp: '33629',
  website: 'https:website.com',
  children: [
    {
      '@id': '/api/filials/1',
      '@type': 'Filial',
      name: 'Fisher, Mohr and Legros',
      email: 'amir.souissi@yahoo.fr',
      location: null,
      cp: '24627',
      phone: '573-568-2325x873',
      website: 'https:website.com',
      country: null,
      children: [
        {
          '@id': '/api/agencies/1',
          '@type': 'Agency',
          name: 'Lindgren and Sons',
          phone: null,
          location: 'West Alverta',
          email: 'Leannon.Elizabeth@Paucek.com',
          website: null,
          cp: null,
          children: [
            // {
            //   '@id': '/api/departments/1',
            //   '@type': 'Department',
            //   name: 'Dare.com',
            //   email: 'rWilderman@Jakubowski.com',
            //   location: '021 London Greens\nEmmanuellemouth, GA 64282',
            //   cp: '49854',
            //   phone: '1-810-235-9141',
            //   website: 'https:website.com',
            //   service: null,
            //   createdAt: '2022-08-29T14:22:08+02:00',
            //   updatedAt: '2022-08-29T14:22:08+02:00',
            //   id: 1,
            //   categoryEntity: 'Department',
            // },
            // {
            //   '@id': '/api/departments/2',
            //   '@type': 'Department',
            //   name: 'Dept Switzerland',
            //   email: 'rWilderman@Jakubowski.com',
            //   location: '021 London Greens\nEmmanuellemouth, GA 64282',
            //   cp: '49854',
            //   phone: '1-810-235-9141',
            //   website: 'https:website.com',
            //   service: null,
            //   createdAt: '2022-08-29T14:22:08+02:00',
            //   updatedAt: '2022-08-29T14:22:08+02:00',
            //   id: 2,
            //   categoryEntity: 'Department',
            // },
          ],
          city: null,
          createdAt: '2022-08-29T14:22:08+02:00',
          updatedAt: '2022-08-29T14:22:08+02:00',
          id: 1,
          categoryEntity: 'Agency',
        },
        {
          '@id': '/api/agencies/19',
          '@type': 'Agency',
          name: 'Agency_ID_19',
          phone: '41222223333',
          location: 'Winterthur Agency',
          email: 's.ferhi@ewm.swiss',
          website: null,
          cp: '33629',
          children: [],
          city: 'Winterthur',
          createdAt: '2022-12-07T14:29:16+01:00',
          updatedAt: '2022-12-07T14:29:16+01:00',
          id: 19,
          categoryEntity: 'Agency',
        },
        {
          '@id': '/api/agencies/34',
          '@type': 'Agency',
          name: 'Zurich Agency',
          phone: '41222223333',
          location: 'Zurich Agency',
          email: 's.ferhi@ewm.swiss',
          website: null,
          cp: '33629',
          children: [],
          city: 'Zurich',
          createdAt: '2022-12-07T14:29:16+01:00',
          updatedAt: '2022-12-07T14:29:16+01:00',
          id: 34,
          categoryEntity: 'Agency',
        },
        {
          '@id': '/api/agencies/88',
          '@type': 'Agency',
          name: 'Genova Agency',
          phone: '41222223333',
          location: 'Genova Agency',
          email: 's.ferhi@ewm.swiss',
          website: null,
          cp: '33629',
          children: [],
          city: 'Genova',
          createdAt: '2022-12-07T14:29:16+01:00',
          updatedAt: '2022-12-07T14:29:16+01:00',
          id: 88,
          categoryEntity: 'Agency',
        },
      ],
      brand: null,
      createdAt: '2022-08-29T14:22:08+02:00',
      updatedAt: '2022-08-29T14:22:08+02:00',
      id: 1,
      categoryEntity: 'Filial',
    },
    // {
    //   '@id': '/api/filials/23',
    //   '@type': 'Filial',
    //   name: 'seif ferhi',
    //   email: 'amir.souissi@yahoo.fr',
    //   location: 'Circle Schusterside, WV 04774-2048',
    //   cp: '33629',
    //   phone: '41232342222',
    //   website: 'website.com',
    //   country: 'CH',
    //   children: [
    //     {
    //       '@id': '/api/agencies/9',
    //       '@type': 'Agency',
    //       name: 'Hello test',
    //       phone: '41222223333',
    //       location: 'Agency_ID_9',
    //       email: 's.ferhi@ewm.swiss',
    //       website: null,
    //       cp: '33629',
    //       children: [],
    //       city: 'City',
    //       createdAt: '2022-12-07T14:29:16+01:00',
    //       updatedAt: '2022-12-07T14:29:16+01:00',
    //       id: 9,
    //       categoryEntity: 'Agency',
    //     },
    //   ],
    //   brand: null,
    //   createdAt: '2022-12-07T14:22:22+01:00',
    //   updatedAt: '2022-12-07T14:22:22+01:00',
    //   id: 23,
    //   categoryEntity: 'Filial',
    // },
    {
      '@id': '/api/filials/45',
      '@type': 'Filial',
      name: 'Italy Filial',
      email: 'amir.souissi@yahoo.fr',
      location: 'Circle Schusterside, WV 04774-2048',
      cp: '33629',
      phone: '41232342222',
      website: 'website.com',
      country: 'IT',
      children: [
        {
          '@id': '/api/agencies/9',
          '@type': 'Agency',
          name: 'Winterthur Agency',
          phone: '41222223333',
          location: 'Winterthur Agency',
          email: 's.ferhi@ewm.swiss',
          website: null,
          cp: '33629',
          children: [],
          city: 'Winterthur',
          createdAt: '2022-12-07T14:29:16+01:00',
          updatedAt: '2022-12-07T14:29:16+01:00',
          id: 9,
          categoryEntity: 'Agency',
        },
      ],
      brand: null,
      createdAt: '2022-12-07T14:22:22+01:00',
      updatedAt: '2022-12-07T14:22:22+01:00',
      id: 45,
      categoryEntity: 'Filial',
    },
  ],
  users: [
    {
      id: 4,
      firstname: 'Bilel',
      lastname: 'Oueslati',
      avatar: null,
      roles: ['ROLE_ADMIN_AGENT', 'ROLE_USER'],
    },
    {
      id: 6,
      firstname: 'houssem',
      lastname: 'ben mustapha',
      avatar:
        'https://127.0.0.1:8000/uploads/avatar/f52a763a78f38a23f45894c2fd1fee7c.jpg',
      roles: ['ROLE_ADMIN', 'ROLE_USER'],
    },
    {
      id: 9,
      firstname: 'Ramzi chamat',
      lastname: 'Demo',
      avatar:
        'https://127.0.0.1:8000/uploads/avatar/951af06a831380fafe9898e76bd2f20f.jpg',
      roles: ['ROLE_USER'],
    },
    {
      id: 10,
      firstname: 'Ali abssi',
      lastname: 'Demo',
      avatar: null,
      roles: ['ROLE_USER'],
    },
    {
      id: 11,
      firstname: 'Ms. Shany Mayer',
      lastname: 'Brant Ward',
      avatar: null,
      roles: ['ROLE_USER'],
    },
    {
      id: 14,
      firstname: 'Obie Paucek',
      lastname: 'Jamir Lebsack',
      avatar: null,
      roles: ['ROLE_USER'],
    },
    {
      id: 15,
      firstname: 'Will Cronin IV',
      lastname: 'Ryan Koelpin',
      avatar: null,
      roles: ['ROLE_USER'],
    },
    {
      id: 16,
      firstname: 'Oliver Balistreri',
      lastname: 'Brooks Gaylord',
      avatar: null,
      roles: ['ROLE_USER'],
    },
    {
      id: 17,
      firstname: 'Prof. Chelsey Leannon IV',
      lastname: 'Howell Orn',
      avatar: null,
      roles: ['ROLE_USER'],
    },
    {
      id: 18,
      firstname: 'Javon Sporer',
      lastname: 'Dr. Curtis Reilly',
      avatar: null,
      roles: ['ROLE_USER'],
    },
    {
      id: 19,
      firstname: 'Dorothy Turner',
      lastname: 'Vena Grimes',
      avatar: null,
      roles: ['ROLE_USER'],
    },
    {
      id: 20,
      firstname: 'Korey Rau',
      lastname: 'Mr. Thaddeus Ferry V',
      avatar: null,
      roles: ['ROLE_USER'],
    },
    {
      id: 21,
      firstname: 'Dagmar Herzog',
      lastname: 'Miss Ivory Haley DDS',
      avatar: null,
      roles: ['ROLE_USER'],
    },
    {
      id: 22,
      firstname: 'Devante Hahn',
      lastname: "Jedidiah O'Keefe",
      avatar: null,
      roles: ['ROLE_USER'],
    },
    {
      id: 23,
      firstname: 'Mrs. Arlie Cummerata III',
      lastname: 'Dr. Yoshiko Corwin',
      avatar: null,
      roles: ['ROLE_USER'],
    },
    {
      id: 24,
      firstname: 'Dr. Travon Olson',
      lastname: 'Adolf Rogahn',
      avatar: null,
      roles: ['ROLE_USER'],
    },
    {
      id: 25,
      firstname: 'Dr. Berneice Erdman III',
      lastname: 'Angie Feeney III',
      avatar: null,
      roles: ['ROLE_USER'],
    },
    {
      id: 64,
      firstname: 'Amir',
      lastname: 'Souissi',
      avatar: null,
      roles: ['ROLE_ADMIN', 'ROLE_USER'],
    },
  ],
  brand: null,
  createdAt: '2022-08-29T14:22:08+02:00',
  updatedAt: '2022-08-29T14:22:08+02:00',
  id: 1,
  categoryEntity: 'Company',
};
