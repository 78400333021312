import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';

import Nav from '../../aside/nav';

export default function TemporaryDrawer() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button sx={{ paddingLeft: 0 }} onClick={() => setIsOpen(!isOpen)}>
        <MenuIcon />
      </Button>
      <Drawer anchor={'left'} open={isOpen} onClose={() => setIsOpen(false)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '250px',
            height: '100%',
            padding: '64px 16px 0px ',
            justifyContent: 'space-between',
          }}
          role="presentation"
          onClick={() => setIsOpen(false)}
          onKeyDown={() => setIsOpen(false)}
        >
          <Nav />
        </Box>
      </Drawer>
    </>
  );
}
