import { Box } from '@mui/material';
import PromotionStatsTable from './statsTab';

function PromotionStats() {
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        color: 'text.primary',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        gap: '12px',
        borderBottom: '2px solid #F5F5F7',
        paddingBottom: '24px',
      }}
    >
      <PromotionStatsTable />
      <PromotionStatsTable />
    </Box>
  );
}

export default PromotionStats;
