import { Box, Stack } from '@mui/material';

function PromotionStatsTable() {
  const stats = [
    { label: 'Max Rooms:', value: '26' },
    { label: 'Min Rooms:', value: '4' },
    { label: 'Max Surface:', value: '10,763 Sq Ft.' },
    { label: 'Min Surface: ', value: '6,428 Sq Ft.' },
    { label: 'Max Bathrooms: ', value: '8' },
    { label: 'Min Bathrooms: ', value: '4' },
  ];
  return (
    <Stack
      sx={{
        width: '100%',
        backgroundColor: 'background.default',
        color: 'text.primary',
        borderRadius: '8px',
        borderWidth: '1px  ',
        padding: '12px 32px',
      }}
    >
      {stats.map(({ label, value }, index) => (
        <Box
          className="border-b border-[#E6E8EC] last:border-none text-xs"
          key={label + index}
          sx={{
            display: 'flex',
            gap: '8px',
            justifyContent: 'space-between',
            padding: '8px 0px',
            backgroundColor: 'background.default',
            color: 'text.primary',
          }}
        >
          <label> {label}</label>
          <p className="  pr-4">{value}</p>
        </Box>
      ))}
    </Stack>
  );
}

export default PromotionStatsTable;
