import { Button, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CalendarIcon from '../../../features/icons/calendarIcon';
import EditIcon from '../../../features/icons/editIcon';
import PermissionIcon from '../../../features/icons/permissionIcon';
import ShareIcon from '../../../features/icons/shareIcon';
import TabGridTag from '../../../features/tags/tabGridTag';

function SinglePromotionHeader() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        color: 'text.primary',
        bgColor: 'background.default',
      }}
    >
      <section className="flex flex-col gap-2">
        <Typography variant="h3">
          {t('promotionPage:promotionDetails')}
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TabGridTag
            icon={<PermissionIcon color="#3446B9" />}
            iconPlacement="start"
            textContent="ID: R46024072"
            color="#3446B9"
            className="flex gap-1 items-center py-0 px-2 !max-w-full "
          />

          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <CalendarIcon />
            <p>2020-10-07 13:47:24</p>
          </Box>
        </Box>
      </section>
      <section className="flex gap-4 justify-center items-start">
        <Button variant="outlined" startIcon={<ShareIcon />}>
          {t('common:share')}
        </Button>
        <Button
          sx={{ color: 'text.primary' }}
          variant="contained"
          startIcon={<EditIcon />}
        >
          {t('common:editInfos')}
        </Button>
      </section>
    </Box>
  );
}

export default SinglePromotionHeader;
