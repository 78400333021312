import { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../hooks/useAuth';
import useSWR from 'swr';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AgencyDependencies from './AgencyDependencies';
import AgencyInfos from './AgencyInfos';
import FormAgency from './FormAgency';
import { ApiListing } from '../../types/api';
import { Agency, AgencyDrawerContent, FileNode } from '../../types/agency';
import User from '../../types/user';
import Tree from './tree';
import { numberOfChildren } from './tree/utils';
import { If, Then, Else } from 'react-if';
import { Box, Button, Typography } from '@mui/material';
import TreeIcon from '../../features/icons/treeIcon';
import VerticalTreeView from './verticalTree';
import { Xwrapper } from 'react-xarrows';
import { useTranslation } from 'react-i18next';
import AssignUserModal from './components/assignUserModal';
import UserIcon from '../../features/icons/userIcon';

const Agencies = () => {
  const { i18n } = useTranslation('');
  const [isTreeVisible, setTreeVisibility] = useState<boolean>(false);
  const [treeData, setTreeData] = useState<FileNode | any>({});
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);
  const [isRoleModalOpen, setRoleModal] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');
  const [drawerContent, setContentInDrawer] = useState<AgencyDrawerContent>({
    currentNode: {} as Agency,
    type: 'unassignedUsers',
  });

  const {
    user,
    user: { roles: userRoles },
  } = useAuth();
  const urlRef = useRef('');
  const locale = i18n.language;

  const getTreeData = (
    user: Partial<User>,
    locale: string
  ): string | undefined => {
    const { company, filial, agency, department } = user;

    if (userRoles.includes('ROLE_ADMIN')) {
      const baseUrl = `/companies/${company?.id}=${locale}`;
      urlRef.current = baseUrl;
      return baseUrl;
    } else if (userRoles.includes('ROLE_ADMIN_ASSISTANT')) {
      const baseUrl = `/filials/${filial?.id}=${locale}`;
      urlRef.current = baseUrl;
      return baseUrl;
    } else if (userRoles.includes('ROLE_ADMIN_AGENT')) {
      const baseUrl = `/agencies/${agency?.id}=${locale}`;
      urlRef.current = baseUrl;
      return baseUrl;
    } else if (userRoles.includes('ROLE_ADMIN_OPERATOR')) {
      const baseUrl = `/departments/${department?.id}=${locale}`;
      urlRef.current = baseUrl;
      return baseUrl;
    }
  };
  useEffect(() => {
    if (locale) {
      const newUrl = getTreeData(user, locale)!;
      setUrl(newUrl);
    }
  }, [locale]);

  const { data } = useSWR<ApiListing<FileNode>>(
    Object.keys(user).length > 0 && url
  );

  useEffect(() => {
    setTreeData(data);
  }, [data]);

  isTreeVisible &&
    treeData &&
    Object.keys(treeData).length > 0 &&
    numberOfChildren(treeData, 0, 'Company', 1);

  const setDrawerContent = (contentType: any) => {
    toggleDrawer(true);
    setIsDrawerOpen(true);
    setContentInDrawer({ ...contentType });
  };

  const RenderDrawerContent = (props: any) => {
    if (props.type.includes('formAgenciesAdd')) {
      return (
        <FormAgency
          content={drawerContent}
          submitTypeForm="add"
          basedUrl={urlRef.current}
          setDrawerContent={setDrawerContent}
          toggleDrawer={toggleDrawer}
        />
      );
    } else if (props.type.includes('formAgenciesEdit')) {
      return (
        <FormAgency
          content={drawerContent}
          submitTypeForm="edit"
          basedUrl={urlRef.current}
          setDrawerContent={setDrawerContent}
          toggleDrawer={toggleDrawer}
        />
      );
    } else {
      switch (props.type) {
        case 'unassignedUsers':
          return (
            <AgencyDependencies
              content={drawerContent}
              setRoleModal={setRoleModal}
              basedUrl={urlRef.current}
            />
          );
        case 'agencyUsers':
          return (
            <AgencyDependencies
              content={drawerContent}
              setRoleModal={setRoleModal}
              basedUrl={urlRef.current}
            />
          );
        case 'info':
          return (
            <AgencyInfos
              firstNodeId={treeData.id}
              content={drawerContent}
              setDrawerContent={setDrawerContent}
              basedUrl={urlRef.current}
            />
          );
        default:
          return (
            <AgencyDependencies
              content={drawerContent}
              setRoleModal={setRoleModal}
              basedUrl={urlRef.current}
            />
          );
      }
    }
  };

  const handleCloseDrawer = () => {
    setContentInDrawer({ type: 'info', currentNode: {} as Agency });
    setIsDrawerOpen(false);
  };

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const toggleDrawer = (toggle: boolean) => {
    setIsDrawerOpen(toggle);
  };
  const { type } = drawerContent;

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '32px',
        }}
      >
        <Typography variant="h1">Agencies</Typography>
        <Box className="flex items-center">
          {isTreeVisible && (
            <Button
              variant="outlined"
              startIcon={<UserIcon color="#3446B9" />}
              sx={{ zIndex: 9, background: 'white', marginRight: '10px' }}
              onClick={() => {
                setContentInDrawer({
                  type: 'unassignedUsers',
                  currentNode: {} as Agency,
                });
                setIsDrawerOpen(true);
              }}
            >
              show unAssigned users
            </Button>
          )}
          <Button
            variant="outlined"
            startIcon={<TreeIcon />}
            sx={{ zIndex: 9, background: 'white' }}
            onClick={() => setTreeVisibility(!isTreeVisible)}
          >
            {`Show as ${!isTreeVisible ? 'vertical' : 'horizontal'} flow`}
          </Button>
        </Box>
      </Box>
      <If condition={isTreeVisible}>
        <Then>
          <div>
            <div className="relative" id="canvas">
              <If condition={treeData && Object.keys(treeData).length > 0}>
                <Then>
                  <Xwrapper>
                    <div>
                      <AssignUserModal
                        basedUrl={urlRef.current}
                        isRoleModalOpen={isRoleModalOpen}
                        setRoleModal={setRoleModal}
                      />
                      <Tree
                        basedUrl={urlRef.current}
                        node={treeData}
                        setDrawerContent={setDrawerContent}
                        drawerContent={drawerContent}
                        handleCloseDrawer={handleCloseDrawer}
                        handleOpenDrawer={handleOpenDrawer}
                        toggleDrawer={toggleDrawer}
                        isDrawerOpen={isDrawerOpen}
                        setRoleModal={setRoleModal}
                      />
                    </div>
                  </Xwrapper>
                </Then>
                <Else>
                  <span></span>
                </Else>
              </If>
            </div>

            <SwipeableDrawer
              sx={{
                marginTop: '!9vh',
                position: 'relative !important',
              }}
              anchor={'right'}
              open={isDrawerOpen}
              onClose={() => toggleDrawer(false)}
              onOpen={() => toggleDrawer(true)}
            >
              <IconButton
                style={{
                  cursor: 'pointer',
                  width: '10%',
                  alignSelf: 'end',
                }}
                onClick={() => toggleDrawer(false)}
              >
                <CloseIcon />
              </IconButton>
              <Box
                sx={{
                  minWidth: '23vw',
                  maxWidth: '23vw',
                  padding: '0px 16px',
                  margin: '0px auto',
                }}
              >
                <RenderDrawerContent type={type} setRoleModal={setRoleModal} />
              </Box>
            </SwipeableDrawer>
          </div>
        </Then>
        <Else>
          <VerticalTreeView treeData={treeData} />
        </Else>
      </If>
    </div>
  );
};
export default Agencies;
