import { Avatar, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LocationIcon from '../../../../features/icons/locationIcon';

type Props = {
  banner: string;
  avatar: string;
  location: string;
  promotionTitle: string;
  price: string;
};

function DescriptionHeader({
  banner,
  avatar,
  location,
  promotionTitle,
  price,
}: Props) {
  const { t } = useTranslation('promotionPage');

  return (
    <Box sx={{ backgroundColor: 'background.default', color: 'text.primary' }}>
      <Box>
        <img src={banner} alt="promotion-desc" />
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.default',
          color: 'text.primary',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          width: '100%',
        }}
      >
        <Box className="flex items-end  gap-8">
          <Avatar
            sx={{
              bgcolor: 'white',
              width: '128px',
              height: '128px',
              border: '1px solid #E6E8EC',
              marginTop: '-64px',
              marginLeft: '16px',
            }}
            variant="square"
          >
            <img src={avatar} alt="avatar" />
          </Avatar>
          <Box className="flex flex-col">
            <Box className="flex items-center gap-2">
              <LocationIcon />
              <p>{location}</p>
            </Box>
            <Typography sx={{ fontSize: '32px', lineHeight: '38.5px' }}>
              {promotionTitle}
            </Typography>
          </Box>
        </Box>
        <Box className="border border-[#3446B9] rounded p-[10px] h-full">
          <p className="text-[#3446B980] ">{t('priceFrom')}:</p>
          <p className="text-[#3446B9] text-2xl">${price}</p>
        </Box>
      </Box>
    </Box>
  );
}

export default DescriptionHeader;
