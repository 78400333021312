import {
  DataGrid,
  DataGridProps,
  deDE,
  enUS,
  frFR,
  GridColumns,
  GridValidRowModel,
} from '@mui/x-data-grid';

import {
  deDE as coredeDE,
  enUS as coreenEN,
  frFR as corefrFR,
} from '@mui/material/locale';
import { Box, createTheme, ThemeProvider } from '@mui/material';
import useLocale from '../../hooks/useLocale';
import { TableGridXStyling } from './tableGridXStyling';
import { useThemeMode } from '../../hooks/useThemeMode';
import { appTheme } from '../material-ui/theme';
import { useMemo } from 'react';

interface tableGridPropsT extends DataGridProps {
  rows: Array<GridValidRowModel>;
  columns: GridColumns<GridValidRowModel>;
  checkboxSelection?: boolean;
  disableColumnMenu?: boolean;
  density?: 'standard' | 'compact' | 'comfortable';
  rowsPerPageOptions?: Array<number>;
  loading?: boolean;
}

function TableGridX({
  rows,
  columns,
  density = 'standard',
  ...rest
}: tableGridPropsT) {
  const { locale } = useLocale();
  const [lang, coreLang] =
    locale == 'fr'
      ? [frFR, corefrFR]
      : locale == 'de'
      ? [deDE, coredeDE]
      : [enUS, coreenEN];

  const { mode } = useThemeMode();
  const theme = useMemo(() => createTheme(appTheme(mode)), [mode]);

  const tableGridTheme = createTheme(theme, lang, coreLang);

  return (
    <ThemeProvider theme={tableGridTheme}>
      <Box sx={TableGridXStyling}>
        <DataGrid
          componentsProps={{
            panel: {
              placement: 'bottom-end',
            },
          }}
          {...rest}
          rows={rows}
          columns={columns}
          density={density}
          disableSelectionOnClick
        />
      </Box>
    </ThemeProvider>
  );
}

export default TableGridX;
