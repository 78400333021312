import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFiltersProperties } from '../../../../hooks/useFiltersProperties';
import PropertiesFilterHeader from './propertiesFilterHeader';
import {
  Box,
  Button,
  createTheme,
  // FormControl,
  // InputLabel,
  // Select,
  ThemeProvider,
} from '@mui/material';
// import MenuItem from '@mui/material/MenuItem';
import DateRangePicker from '../../../../components/dateRangePicker/dateRangePicker';
import SearchIcon from '../../../../features/icons/searchIcon';
import { appTheme } from '../../../../components/material-ui/theme';
import { useThemeMode } from '../../../../hooks/useThemeMode';
import { IfieldFilter } from '../../../../types/property';
import AutocompleteSelect from '../../stepper/components/form/autoCompleteSelect';
import ControlledSelectedForm from '../../../../components/form/controlledSelectedForm';
import InputFormField from '../../../../components/form/inputFormField';

export const textFieldStyle = {
  '& .MuiInputBase-root , .MuiSelect-select': {
    borderRadius: '1px',
  },
};

type Props = {
  mainOptions: IfieldFilter[];
  advancedOptions: IfieldFilter[];
};

function PropertiesFilter({ mainOptions, advancedOptions }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const { selectedAdvancedFilter } = useFiltersProperties();
  const {
    register,
    clearErrors,
    // handleSubmit,
    setValue,
    watch,
  } = useForm();

  const selectedFilter: IfieldFilter[] = useMemo(() => {
    return advancedOptions.filter(({ title }) =>
      selectedAdvancedFilter.includes(title)
    );
  }, [selectedAdvancedFilter]);

  console.log('selectedFilter');

  const { mode } = useThemeMode();

  const theme = useMemo(
    () =>
      createTheme({
        ...appTheme(mode),
        typography: {
          fontSize: 13,
        },
      }),
    [mode]
  );

  return (
    <ThemeProvider theme={theme}>
      <Box className="w-full mt-2 flex flex-col relative text-sm rounded mb-8 filter-container  ">
        <PropertiesFilterHeader
          setIsOpen={setIsOpen}
          advancedOptions={advancedOptions}
        />

        <>
          <Box
            sx={{ bgcolor: '#ececefde', color: 'text.primary' }}
            className={`${
              isOpen ? 'h-full p-4 ' : 'max-h-0  '
            } bg-[#F5F5F7]  justify-between  overflow-hidden transition-all duration-500 ease-in`}
          >
            <Box
              className={`${
                isOpen ? ' visible' : 'invisible '
              } transition-opacity delay-500 grid grid-cols-3 gap-3 justify-center items-center`}
            >
              {mainOptions.map((option, index) => {
                const { title, type, items } = option;
                if (type === 'listSearch') {
                  return (
                    <div key={`${title}-${index}`}>
                      <AutocompleteSelect
                        width="360px"
                        isMultiple
                        clearErrors={clearErrors}
                        label={title}
                        name={title}
                        className="!h-12 lg:!max-w-[360px]"
                        register={register}
                      />
                    </div>
                  );
                }
                if (type === 'list') {
                  return (
                    <div key={`${title}-${index}`}>
                      <ControlledSelectedForm
                        className="!h-12 w-full bg-white  lg:!max-w-[360px]"
                        watch={watch}
                        setValue={setValue}
                        isMultiple
                        options={items!}
                        label={title}
                        name={title}
                        register={register}
                      />
                    </div>
                  );
                }
                if (type === 'text') {
                  return (
                    <InputFormField
                      key={`${title}-${index}`}
                      label={title}
                      placeholder={`Enter ${title}`}
                      {...register(title)}
                      id="outlined-size-small"
                      size="small"
                      sx={textFieldStyle}
                      className="  col-span-1  !h-12 "
                    />
                  );
                }
                if (type === 'number') {
                  return (
                    <InputFormField
                      key={`${title}-${index}`}
                      label={title}
                      placeholder={`Enter ${title}`}
                      {...register(title)}
                      id="outlined-size-small"
                      size="small"
                      sx={textFieldStyle}
                      className="col-span-1  !h-12 "
                    />
                  );
                }
              })}

              {selectedFilter.map((option, index) => {
                const { title, type, items } = option;

                if (type === 'listSearch') {
                  return (
                    <AutocompleteSelect
                      isMultiple
                      key={`${title}-${index}`}
                      clearErrors={clearErrors}
                      label={title}
                      name={title}
                      className="!h-12 lg:!max-w-[360px]"
                      register={register}
                    />
                  );
                }
                if (type === 'list') {
                  return (
                    <ControlledSelectedForm
                      className="!h-12 w-full bg-white   lg:!max-w-[360px]"
                      key={`${title}-${index}`}
                      watch={watch}
                      setValue={setValue}
                      isMultiple
                      options={items!}
                      label={title}
                      name={title}
                      register={register}
                    />
                  );
                }
                if (type === 'text') {
                  return (
                    <InputFormField
                      key={`${title}-${index}`}
                      placeholder={`Enter ${title}`}
                      label={title}
                      {...register(title)}
                      id="outlined-size-small"
                      size="small"
                      sx={textFieldStyle}
                      className="!h-12 w-full   lg:!max-w-[360px]"
                    />
                  );
                }
                if (type === 'number') {
                  return (
                    <InputFormField
                      placeholder={`Enter ${title}`}
                      key={`${title}-${index}`}
                      label={title}
                      {...register(title)}
                      id="outlined-size-small"
                      size="small"
                      sx={textFieldStyle}
                      className="!h-12 w-full  col-span-1 lg:!max-w-[360px]"
                    />
                  );
                }
                if (type === 'date') {
                  return (
                    <Box
                      key={`${title}-${index}`}
                      className="flex flex-col  !h-12 !w-full  col-span-1 lg:!max-w-[360px]"
                    >
                      <Box className="col-span-1 grid grid-cols-2 gap-2">
                        <DateRangePicker label="From" />
                        <DateRangePicker label="To" />
                      </Box>
                    </Box>
                  );
                }
                if (type === 'range-number') {
                  return (
                    <Box
                      key={`${title}-${index}`}
                      className="flex   !h-12 !w-full  col-span-1 lg:!max-w-[360px]"
                    >
                      {' '}
                      <Box className="col-span-1 grid grid-cols-2 gap-2">
                        <InputFormField
                          label={'Min'}
                          placeholder={`Enter ${title}`}
                          {...register(title)}
                          id="outlined-size-small"
                          size="small"
                          sx={textFieldStyle}
                        />
                        <InputFormField
                          label={'Max'}
                          placeholder={`Enter ${title}`}
                          {...register(title)}
                          id="outlined-size-small"
                          size="small"
                          sx={textFieldStyle}
                        />{' '}
                      </Box>
                    </Box>
                  );
                }
              })}
              <div className="col-span-1  w-full cursor-pointer   !max-w-[360px]">
                <Button
                  className="flex gap-4 h-9"
                  variant="outlined"
                  sx={{
                    marginTop: selectedFilter.length > 0 ? '14px' : '22px',
                    color: '#3446B9',
                    width: '100%',
                  }}
                >
                  <SearchIcon
                    color="#3446B9
"
                  />
                  <Box component="span">Search</Box>
                </Button>
              </div>
            </Box>
          </Box>
        </>
      </Box>
    </ThemeProvider>
  );
}

export default PropertiesFilter;
