import { Box, Switch, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import useStore from '../../store';
import User from '../../../../../../types/user';
import AutocompleteSelect from '../../form/autoCompleteSelect';
import useLocale from '../../../../../../hooks/useLocale';
import { yupResolver } from '@hookform/resolvers/yup';
import { OwnerInfosStep } from '../../../../../../libs/yup/propertyValidation';
import { useEffect, useState } from 'react';
import { http } from '../../../../../../libs/axios';
import ControlledSelectedForm from '../../../../../../components/form/controlledSelectedForm';
import { ScrollUp } from '../../../../../../helpers/utils/scroolUp';
import { OwnerT } from '../../../../../../types/property';
import {
  languagesOptions,
  originOptions,
  titleOptions,
  typeOptions,
} from '../../../../../../const/propertiesOptions';
import OwnerInfos from './components/ownerInfos';
import OwnersList from './components/ownersList';
import PreviousButton from '../../form/previousButton';
import NextButton from '../../form/nextButton';

type Props = { brokersList: User[] };

const OwnerStepForm = ({ brokersList }: Props) => {
  const handleNext = useStore((state) => state.handleNext);
  const { locale: currentLanguage } = useLocale();
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<OwnerT>({
    resolver: yupResolver(OwnerInfosStep),
    shouldFocusError: false,
    defaultValues: { isExisting: false },
  });
  const [selectedIdsToSend, setSelectedIdsToSend] = useState();

  const OwnerExist = watch('isExisting');

  const onSubmit = async (data: any) => {
    const {
      address,
      city,
      company,
      gender,
      email,
      firstname,
      lastname,
      mobile,
      phone,
      zipCode,
      // correspondenceTitle,
      // language,
      // user,
      // origin,
      // status,
      // type,
    } = data;
    const newOwnerInfos = {
      owner: {
        firstname,
        lastname,
        gender,
        company,
        phone: String(phone),
        mobile: String(mobile),
        email,
        address: {
          zipCode,
          translations: {
            [`${currentLanguage}`]: {
              locale: currentLanguage,
              address,
              city,
            },
          },
        },
      },
      // prospections: [
      //   {
      //     title: correspondenceTitle,
      //   },
      //   { status },
      //   { user },
      //   { language },
      //   { type },
      //   { origin },
      // ],
    };

    const existingOwner = { owner: selectedIdsToSend };
    const dataToSend = watch('isExisting') ? existingOwner : newOwnerInfos;

    const createdPropertyId = localStorage.getItem('createdPropertyId');
    try {
      const res = await http.patch(
        `/v1/properties/${createdPropertyId}`,
        dataToSend
      );
      if (res.status === 200) {
        handleNext();
      }
    } catch (error: any) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    ScrollUp('main');
  }, []);
  return (
    <>
      {/* <EditModal /> */}
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '48px',
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box display="flex" flexDirection="row" className="mb-16" gap="80px">
          <Typography className="stepTitle" width="20%" variant="h6">
            Owner&apos;s info
          </Typography>
          <Box width="100%" display="flex" flexDirection="column" gap="24px">
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="24px"
            >
              <Switch {...register('isExisting')} />
              <Typography>Already existing owner</Typography>
            </Box>

            {OwnerExist && (
              <OwnersList
                setSelectedIdsToSend={setSelectedIdsToSend}
                watch={watch}
                errors={errors}
                setValue={setValue}
                register={register}
              />
            )}
            {!OwnerExist && (
              <OwnerInfos
                clearErrors={clearErrors}
                watch={watch}
                errors={errors}
                setValue={setValue}
                register={register}
              />
            )}
          </Box>
        </Box>
        {!OwnerExist && (
          <Box display="flex" flexDirection="row" className="mb-16" gap="80px">
            <Typography className="stepTitle" width="20%" variant="h6">
              Additional information{' '}
            </Typography>
            <Box width="100%" display="flex" flexDirection="column" gap="24px">
              <Box className="grid grid-cols-2" gap="24px">
                <ControlledSelectedForm
                  options={titleOptions}
                  label="Title of correspondenceTitle"
                  name="correspondenceTitle"
                  error={errors['correspondenceTitle']}
                  register={register}
                  watch={watch}
                  setValue={setValue}
                />
              </Box>
              <Box className="grid grid-cols-2" gap={3}>
                <AutocompleteSelect
                  isStepper
                  clearErrors={clearErrors}
                  setValue={setValue}
                  options={brokersList}
                  label="Broker responsible"
                  name="user"
                  error={errors['user']}
                  className="w-full"
                  register={register}
                />

                <ControlledSelectedForm
                  options={languagesOptions}
                  label="Language for contact"
                  name="language"
                  error={errors['language']}
                  register={register}
                  watch={watch}
                  setValue={setValue}
                />
              </Box>
              <Box className="grid grid-cols-2" gap={3}>
                <ControlledSelectedForm
                  options={typeOptions}
                  label="Type"
                  name="type"
                  error={errors['type']}
                  register={register}
                  watch={watch}
                  setValue={setValue}
                />
                <ControlledSelectedForm
                  options={originOptions}
                  label="Origin"
                  name="origin"
                  error={errors['origin']}
                  register={register}
                  watch={watch}
                  setValue={setValue}
                />
              </Box>
            </Box>
          </Box>
        )}
        <Box className="flex justify-end pt-6 border-t border-[#E6E8EC] gap-6">
          <PreviousButton />
          <NextButton />
        </Box>
      </Box>
    </>
  );
};

export default OwnerStepForm;
