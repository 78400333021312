import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactFlagsSelect from 'react-flags-select';
import { SketchPicker } from 'react-color';
import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Typography,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { mutate } from 'swr';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import PhoneInput from 'react-phone-input-2';
import { toast } from 'react-toastify';

import { countries } from '../../const/countries';
import { useAuth } from '../../hooks/useAuth';
import { http } from '../../libs/axios';
import {
  Agency,
  AgencyDrawerContent,
  departmentService,
} from '../../types/agency';
import { AddAgencyFormValidation } from '../../libs/yup/agencyFormValidation';
import '../../features/icons/flags/flags.css';
import ImageValidator from '../../helpers/imageValidator';

import InputFormField from '../../components/form/inputFormField';
import { captureException } from '@sentry/react';
type AgencyDataT = Pick<
  Agency,
  | 'service'
  | 'name'
  | 'country'
  | 'city'
  | 'location'
  | 'cp'
  | 'email'
  | 'phone'
  | 'website'
  | 'brand'
  | 'logo'
>;

type Props = {
  content: AgencyDrawerContent;
  submitTypeForm: string;
  basedUrl: string;
  setDrawerContent: any;
  toggleDrawer: any;
};

const FormAgency = ({
  content,
  submitTypeForm,
  basedUrl,
  toggleDrawer,
  setDrawerContent,
}: Props) => {
  const {
    user: { roles: userRoles, subscription },
  } = useAuth();

  const { t } = useTranslation('agencyPage');
  const [selectedLogo, setSelectedLogo] = useState({} as FileList);
  const [country, setCountry] = useState<string | ''>('CH');
  const [isColorBrandOpen, setIsColorBrandOpen] = useState(false);
  const onSelect = (code: string) => setCountry(code);
  const { type, currentNode } = content;
  const {
    currentNode: {
      country: currentNodeCoutry,
      location,
      email,
      phone,
      website,
      cp,
      categoryEntity,
      city,
      service,
      name,
      brand,
    },
  } = content;
  const [brandColor, setBrandColor] = useState(brand || ('#808080' as string));

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch,
  } = useForm<AgencyDataT>({
    resolver: yupResolver(AddAgencyFormValidation),
    shouldFocusError: false,
  });
  useEffect(() => {
    if (submitTypeForm === 'edit' && currentNode) {
      switch (categoryEntity) {
        case 'Filial':
          setCountry(currentNodeCoutry);
          break;
        case 'Agency':
          setValue('city', city);
          break;
        case 'Department':
          setValue('service', service);
          break;
      }
      setValue('name', name);
      setValue('location', location);
      setValue('phone', phone);
      setValue('website', website);
      setValue('email', email);
      setValue('cp', cp);
      setBrandColor(brand || '#808080');
    }
    // }
  }, [submitTypeForm]);

  // submit form on add
  const handleAddSubmitFrom = async (data: any, entity: string, id: number) => {
    const { city, cp, email, location, name, phone, website, service } = data;
    try {
      switch (entity) {
        case 'formAgenciesAdd-Filial':
          await http.post('/filials', {
            cp,
            email,
            location,
            name,
            phone,
            website,
            company: id.toString(),
            country,
            brand: brandColor,
          });
          mutate(basedUrl);
          break;
        case 'formAgenciesAdd-Agency':
          await http.post('/agencies', {
            city,
            cp,
            email,
            location,
            name,
            phone,
            website,
            filial: id.toString(),
            brand: brandColor,
          });
          mutate(basedUrl);
          break;
        case 'formAgenciesAdd-Department':
          await http.post('/departments', {
            service,
            cp,
            email,
            location,
            name,
            phone,
            website,
            agency: id.toString(),
            brand: brandColor,
          });
          mutate(basedUrl);
          break;
      }
      toast.success(
        t(`alerts:agency.success.${categoryEntity}_added`) as string
      );
      toggleDrawer(false);
    } catch (event: any) {
      captureException(event);
      if (
        event.response.status === 459 ||
        event.response.status === 458 ||
        event.response.status === 457
      ) {
        toast.warning(event.response.data.message as string);
      } else {
        toast.error(
          t(`alerts:agency.error.${categoryEntity}_not_added`) as string
        );
      }
    }
  };

  // submit form on Edit
  const handleEditSubmitFrom = async (
    data: any,
    entity: string,
    id: number
  ) => {
    try {
      switch (entity) {
        case 'formAgenciesEdit-Company':
          delete data.service;
          delete data.city;
          await http.patch(`companies/${id}`, {
            ...data,
            brand: brandColor,
          });
          mutate(basedUrl);
          break;
        case 'formAgenciesEdit-Filial':
          await http.patch(`filials/${id}`, {
            ...data,
            country,
            brand: brandColor,
          });
          mutate(basedUrl);
          break;
        case 'formAgenciesEdit-Agency':
          delete data.service;
          await http.patch(`agencies/${id}`, { ...data, brand: brandColor });
          mutate(basedUrl);
          break;
        case 'formAgenciesEdit-Department':
          delete data.city;
          await http.patch(`departments/${id}`, { ...data, brand: brandColor });
          mutate(basedUrl);
          break;
      }
      toast.success(
        t(`alerts:agency.success.updated_${categoryEntity}`) as string
      );
      setDrawerContent({
        type: `info`,
        currentNode: { id, categoryEntity, ...data },
      });
      toggleDrawer(false);
    } catch (event: any) {
      captureException(event);
      if (
        event.response.status === 459 ||
        event.response.status === 458 ||
        event.response.status === 457
      ) {
        toast.warning(event.response.data.message as string);
      } else {
        toast.error(
          t(`alerts:agency.error.${categoryEntity}_not_updated`) as string
        );
      }
    }
  };

  const onSubmit = async (data: AgencyDataT, event: any) => {
    event.preventDefault();

    if (submitTypeForm === 'add') {
      handleAddSubmitFrom(data, type, currentNode.id);
    } else {
      handleEditSubmitFrom(data, type, currentNode.id);
      if (!selectedLogo || !selectedLogo[0]) return;
      const file = selectedLogo[0];

      const validator = new ImageValidator({ file });

      validator.validate({ maxSize: 3 });

      const formData = new FormData();

      formData.append('file', file);

      await http.post(`/companies/logo/add`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    }
  };

  // handle logo file
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files) {
      setSelectedLogo(files);
    }
  };
  const renderCountryDropDown = (submitTypeForm: string) => {
    return (
      <Box
        sx={{
          height: '44px',
          borderRadius: '5px',
          opacity: submitTypeForm === 'edit' && country ? 0.8 : 1,
          background:
            submitTypeForm === 'edit' && country ? '#0001' : 'transparent',
        }}
      >
        <ReactFlagsSelect
          selected={country}
          onSelect={onSelect}
          countries={countries.map(({ alpha2 }) => alpha2)}
          searchPlaceholder="Search countries"
          searchable={true}
          disabled={submitTypeForm === 'edit' && country ? true : false}
        />
      </Box>
    );
  };

  const renderDropDownService = (submitTypeForm: string) => {
    return (
      <FormControl
        sx={{
          display: `${
            (submitTypeForm === 'add' && categoryEntity === 'Agency') ||
            (submitTypeForm === 'edit' && categoryEntity === 'Department')
              ? 'flex'
              : ' none'
          }`,
        }}
        error={errors['service'] ? true : false}
      >
        <InputLabel id="demo-simple-select-error-label">service</InputLabel>
        <Select
          labelId="demo-simple-select-error-label"
          className="col-span-1"
          label="service"
          defaultValue={(submitTypeForm === 'add' ? 'Buy' : service) as string}
          error={Object.keys(errors).includes('service')}
          {...register('service')}
          disabled={
            submitTypeForm === 'edit' &&
            userRoles.includes('ROLE_ADMIN_OPERATOR')
          }
        >
          {departmentService.map((service: string, index: number) => (
            <MenuItem key={index} value={service}>
              {service}
            </MenuItem>
          ))}
        </Select>
        {errors['service'] && (
          <FormHelperText className="!text-[#D63E3E]">
            {t('validation:' + errors['service']?.message)}
          </FormHelperText>
        )}
      </FormControl>
    );
  };
  const renderInputAgencyCity = () => {
    return (
      <InputFormField
        sx={{
          display: `${
            (submitTypeForm === 'add' && categoryEntity === 'Filial') ||
            (submitTypeForm === 'edit' && categoryEntity === 'Agency')
              ? 'flex'
              : ' none'
          }`,
        }}
        placeholder="Enter city"
        className="col-span-1"
        label={t('city')}
        {...register('city')}
        error={Object.keys(errors).includes('city')}
        helperText={
          errorKeys.includes('city') &&
          t('validation:' + errors['city']?.message)
        }
      />
    );
  };

  const renderCompanylogoModal = () => {
    return (
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography
          width="40%"
          sx={{ fontWeight: '400', fontSize: '12px', color: '#6E6E72' }}
        >
          Upload logo
        </Typography>
        <Box width="100%" display="flex" flexDirection="column" gap={3}>
          <input
            accept=".jpg,.png,.jpeg"
            style={{ display: 'none' }}
            id={'logo'}
            type="file"
            {...register('logo', {
              onChange: (event) => handleFileUpload(event),
            })}
          />
          <InputLabel
            htmlFor={'logo'}
            sx={{ display: 'flex', marginBottom: '16px' }}
          >
            <Typography
              sx={{
                flex: 2,
                padding: '16px',
                border: '1px solid #E6E8EC',
                borderRight: '0px',
                cursor: 'pointer',
                color: '#6E6E7250',
                background: '#F5F5F7;',
              }}
            >
              {Object.keys(selectedLogo) && selectedLogo[0]?.name}
            </Typography>
            <Typography
              sx={{
                padding: '16px',
                border: '1px solid #E6E8EC',
                cursor: 'pointer',
              }}
            >
              Choose file
            </Typography>
          </InputLabel>
        </Box>
      </Box>
    );
  };

  const renderAgencyGlobalLocation = (categoryEntity: string) => {
    switch (categoryEntity) {
      case 'Company':
        return renderCountryDropDown(submitTypeForm);
      case 'Filial':
        return renderInputAgencyCity();
      case 'Agency':
        return renderDropDownService(submitTypeForm);
    }
  };

  const renderAgencyGlobalLocationonEdit = (categoryEntity: string) => {
    switch (categoryEntity) {
      case 'Company':
        return renderCompanylogoModal();
      case 'Filial':
        return renderCountryDropDown(submitTypeForm);
      case 'Agency':
        return renderInputAgencyCity();
      case 'Department':
        return renderDropDownService(submitTypeForm);
    }
  };
  const handledisableSubmitFormt = () => {
    const { city, cp, email, location, name, phone, website, logo } = watch();
    switch (categoryEntity) {
      case 'Agency':
        return _.isEqual(
          {
            city,
            cp,
            email,
            location,
            name,
            phone,
            website,
            brand: brandColor,
          },
          _.pick(currentNode, [
            'city',
            'name',
            'location',
            'cp',
            'email',
            'phone',
            'website',
            'brand',
          ])
        );
      case 'Department':
        return _.isEqual(
          {
            service,
            cp,
            email,
            location,
            name,
            phone,
            website,
            brand: brandColor,
          },
          _.pick(currentNode, [
            'service',
            'name',
            'location',
            'cp',
            'email',
            'phone',
            'website',
            'brand',
          ])
        );
      default:
        return _.isEqual(
          {
            cp,
            email,
            location,
            name,
            phone,
            website,
            brand: brandColor,
            logo,
          },
          _.pick(currentNode, [
            'name',
            'logo',
            'location',
            'cp',
            'email',
            'phone',
            'website',
            'brand',
          ])
        );
    }
  };
  const handleColorChange = (color: any) => {
    const { hex } = color;
    setIsColorBrandOpen(!isColorBrandOpen);
    setBrandColor(hex);
  };

  const handleColorClick = () => {
    setIsColorBrandOpen(!isColorBrandOpen);
  };

  const editBrandPermission = () => {
    const { level } = subscription;
    // subscription / userRoles.includes('ROLE_ADMIN_OPERATOR')
    return (
      (Number(level) === 1 &&
        (userRoles.includes('ROLE_ADMIN') ||
          userRoles.includes('ROLE_ADMIN_ASSISTANT')) &&
        (type === 'formAgenciesEdit-Filial' ||
          type === 'formAgenciesAdd-Filial' ||
          type === 'formAgenciesEdit-Company')) ||
      (Number(level) === 2 &&
        userRoles.includes('ROLE_ADMIN_ASSISTANT') &&
        type === 'formAgenciesEdit-Filial') ||
      (Number(level) === 3 &&
        userRoles.includes('ROLE_ADMIN_AGENT') &&
        type === 'formAgenciesEdit-Agency') ||
      (Number(level) === 4 &&
        userRoles.includes('ROLE_ADMIN_OPERATOR') &&
        type === 'formAgenciesEdit-Department')
    );
  };

  const errorKeys = Object.keys(errors) as (keyof typeof errors)[];

  return (
    <>
      <Typography
        variant="h2"
        fontSize={25}
        style={{ textAlign: 'center', marginBottom: '30px' }}
      >
        {t(type)}
      </Typography>
      <Box
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          color: 'text.primary',
        }}
        component="form"
      >
        <Box className="flex flex-col md:grid gap-8">
          {submitTypeForm === 'add'
            ? renderAgencyGlobalLocation(categoryEntity)
            : renderAgencyGlobalLocationonEdit(categoryEntity)}

          <InputFormField
            className="col-span-1"
            placeholder="Enter name"
            label={t('name')}
            {...register('name')}
            error={Object.keys(errors).includes('name')}
            helperText={
              errorKeys.includes('name') &&
              t('validation:' + errors['name']?.message)
            }
          />

          {((categoryEntity !== 'Agency' && submitTypeForm === 'add') ||
            (categoryEntity !== 'Department' && submitTypeForm === 'edit')) && (
            <InputFormField
              className="col-span-1"
              label={t('location')}
              placeholder="Enter location"
              {...register('location')}
              error={Object.keys(errors).includes('location')}
              helperText={
                errorKeys.includes('location') &&
                t('validation:' + errors['location']?.message)
              }
            />
          )}

          {((categoryEntity !== 'Agency' && submitTypeForm === 'add') ||
            (categoryEntity !== 'Department' && submitTypeForm === 'edit')) && (
            <InputFormField
              className="col-span-1"
              label={t('Enter zip code')}
              placeholder="zip code"
              {...register('cp')}
              error={Object.keys(errors).includes('cp')}
              helperText={
                errorKeys.includes('cp') &&
                t('validation:' + errors['cp']?.message)
              }
            />
          )}
          <InputFormField
            className="col-span-1"
            label={t('email')}
            placeholder="Enter email"
            {...register('email')}
            error={errorKeys.includes('email')}
            helperText={
              errorKeys.includes('email') &&
              t('validation:' + errors.email?.message)
            }
            {...register('email')}
          />
          <FormControl className="col-span-1 ">
            <Controller
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  inputStyle={{
                    height: '56px',
                    width: '100%',
                    border: errors['phone'] ? ' 1px solid #D63E3E ' : '',
                    color: '#1D1D1F',
                  }}
                  country={'ch'}
                  {...(errors['phone'] && {
                    buttonStyle: {
                      border: '1px solid #D63E3E',
                      borderRight: 'none',
                    },
                  })}
                />
              )}
              control={control}
              name="phone"
            />
            {errors['phone'] && (
              <FormHelperText className="!text-[#D63E3E]">
                {t('validation:' + errors.phone?.message)}
              </FormHelperText>
            )}
          </FormControl>

          <InputFormField
            className="col-span-1"
            label={t('website')}
            placeholder="Enter website link"
            {...register('website')}
            error={errorKeys.includes('website')}
            helperText={
              errorKeys.includes('website') &&
              t('validation:' + errors['website']?.message)
            }
          />
          {editBrandPermission() && (
            <Box className="pl-4 flex">
              <Typography sx={{ color: '#808080e3', marginRight: '16px' }}>
                {' '}
                Color{' '}
              </Typography>
              {!isColorBrandOpen && (
                <Button
                  variant="contained"
                  sx={{ backgroundColor: brandColor }}
                  onClick={() => handleColorClick()}
                >
                  {/* <span className="picker-Input"> {brandColor}</span> */}
                </Button>
              )}
              {isColorBrandOpen && (
                <div>
                  <SketchPicker
                    color={brandColor}
                    onChange={(color: any) => handleColorChange(color)}
                  />
                </div>
              )}
            </Box>
          )}
        </Box>
        <Box className="w-full flex  justify-center px-16 py-8">
          <Button
            variant="contained"
            disabled={submitTypeForm === 'edit' && handledisableSubmitFormt()}
            type="submit"
            startIcon={<CheckIcon fontSize="small" />}
          >
            {t(`save-${type}`)}
          </Button>
        </Box>
      </Box>
    </>
  );
};
export default FormAgency;
