import { Box, Button, TextField, TextFieldProps } from '@mui/material';
import { forwardRef, useId, useState } from 'react';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

type InputProps = TextFieldProps & {
  helperText?: string;
};

const InputPassword = forwardRef<HTMLInputElement, InputProps>(
  ({ onChange, error = false, helperText, ...props }, ref) => {
    const [type, setType] = useState<'password' | 'text'>('password');

    const handleTypeChange = () => {
      setType((type) => (type === 'password' ? 'text' : 'password'));
    };

    const id = useId();

    return (
      <Box className="relative">
        <TextField
          className="z-10"
          sx={{
            width: '100%',
          }}
          type={type}
          id={id}
          variant="outlined"
          ref={ref}
          helperText={error ? helperText : null}
          onChange={onChange}
          error={error}
          {...props}
        />

        <Button
          onClick={handleTypeChange}
          variant="text"
          sx={{
            position: 'absolute',
            padding: '0',
            minWidth: 'auto',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          className="right-3 top-3.5 z-20"
        >
          {type === 'password' ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
        </Button>
      </Box>
    );
  }
);

InputPassword.displayName = 'InputPassword';

export default InputPassword;
