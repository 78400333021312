import { UseFormRegister } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import InputFormField from '../../../../../../../components/form/inputFormField';
type Props = {
  register: UseFormRegister<any>;
};

const TitleBox = ({ register }: Props) => {
  return (
    <Box className="mb-16 flex gap-20">
      <Typography className="stepTitle max-w-[160px]">
        Title (max. 70 characters)
      </Typography>
      <Box className="flex flex-col w-full gap-6">
        <InputFormField
          {...register('title')}
          id="outlined-basic"
          label="title"
          variant="outlined"
          fullWidth
          width="w-full"
        />
      </Box>
    </Box>
  );
};

export default TitleBox;
