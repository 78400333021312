import { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SortableTree from 'react-sortable-tree-patch-react-17/dist/index.cjs';
import 'react-sortable-tree-patch-react-17/style.css'; // This only needs to be imported once in your app
import { Box } from '@mui/material';
import { FileNode } from '../../../../types/agency';
import { convertToTreeData } from '../../tree/utils';
import useLocale from '../../../../hooks/useLocale';

interface verticalTreeProps {
  data: FileNode[];
  setInfoData: any;
}

function VerticalTree({ data, setInfoData }: verticalTreeProps) {
  const [treeData, setTreeData] = useState<typeof data>([] as typeof data);
  const { locale } = useLocale();
  useEffect(() => {
    const dataToConvert = data && data[0];
    setTreeData(data);
    convertToTreeData(dataToConvert!);
  }, [locale]);

  const updateTreeData = (treeData: typeof data) => {
    setTreeData(treeData);
  };

  return (
    <Box
      sx={{
        width: '33vw',
        height: '100vh',
        background: '#FFFFFF',
        border: '1px solid #E6E8EC',
        padding: '50px 42px',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <SortableTree
        isNodeHovered={false}
        treeData={treeData}
        canDrag={false}
        onChange={(treeData: typeof data) => updateTreeData(treeData)}
        generateNodeProps={({ node }: any) => ({
          onClick: () => setInfoData(node),
        })}
      />
    </Box>
  );
}

export default VerticalTree;
