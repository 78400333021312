import { Select } from '@material-ui/core';
import { Button, MenuItem, Box } from '@mui/material';
import Modal from '../../../components/modal';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDragAndDropUsers } from '../../../hooks/useDragAndDropUsers';
import { http } from '../../../libs/axios';
import { toast } from 'react-toastify';
import { mutate } from 'swr';
import { getUserRole } from '../tree/utils';
import { captureException } from '@sentry/react';
// import { userItem } from './userItem';

type Props = {
  isRoleModalOpen: boolean;
  setRoleModal: (arg: boolean) => void;
  basedUrl: string;
};
const AssignUserModal = ({
  isRoleModalOpen,
  setRoleModal,
  basedUrl,
}: Props) => {
  const [selectedRole, setSelectedRole] = useState('user');
  const { t } = useTranslation('alerts');

  const roleOptions = [
    { id: 1, value: 'agent' },
    { id: 0, value: 'admin' },
  ];
  const {
    users,
    // unAssignedUsers,
    updateUserWhenDrop,
    updateUnAssignedUserWhenDrop,
    updateUser,
  } = useDragAndDropUsers();

  //   getUserRole(type, containerRole);
  const handleSubmit = async () => {
    const dataUser = localStorage.getItem('userDraggedData') as string;
    const { id, type, containerValues } = JSON.parse(dataUser);
    const containerType = JSON.parse(containerValues!).type.toLocaleLowerCase();
    const containerId = JSON.parse(containerValues!).id;

    const role = getUserRole(containerType, selectedRole);
    const dataToSend = {
      [`${containerType}`]: containerId,
      roles: role,
    };

    try {
      const res = await http.patch(`/users/${id}`, dataToSend);
      if (res.status === 200) {
        if (type === 'users') {
          const updatedUsers = users.filter((item) => item.id !== Number(id));
          updateUserWhenDrop(updatedUsers);

          mutate(basedUrl);
          toast.success(t(`alerts:user_added`) as string);
        } else if (type == 'changeRole') {
          updateUser([
            ...users.map((user) => {
              return user.id == id
                ? { ...user, roles: dataToSend.roles }
                : { ...user };
            }),
          ]);
          mutate(basedUrl);
          toast.success(t(`alerts:user_change_role`) as string);
        } else {
          // const newUser = unAssignedUsers.find((user) => user.id == id);
          // console.log('dataToSend', dataToSend);
          // const newUserWithCorrectRole = {
          //   avatar: newUser?.avatar,
          //   firstname: newUser?.firstname,
          //   id: Number(newUser?.id),
          //   lastname: newUser?.lastname,
          //   roles: dataToSend.roles,
          // };
          // updateUser([
          //   newUserWithCorrectRole as userItem,
          //   ...(users as userItem[]),
          // ]);
          updateUnAssignedUserWhenDrop(id);
          mutate(basedUrl);
          toast.success(t(`alerts:user_added`) as string);
        }
      }
      setRoleModal(false);
    } catch (event: any) {
      captureException(event);
      if (event.response.status === 466) {
        toast.warning(event.response.data.message as string);
      } else {
        toast.error(t('alerts:user_not_added') as string);
      }
    }
    setRoleModal(false);
    setSelectedRole('user');
    localStorage.removeItem('userDraggedData');
  };

  return (
    <Modal
      withButton={false}
      isOpen={isRoleModalOpen}
      closeModal={() => {
        setSelectedRole('user');
        setRoleModal(false);
      }}
      openModal={() => {
        setRoleModal(true);
      }}
      width={{
        md: 300,
      }}
      title={'Choose role'}
      buttonProps={{
        title: 'Choose role',
      }}
    >
      <Select
        variant="outlined"
        className="w-full m-auto"
        onChange={(e) => {
          setSelectedRole(e.target.value as string);
        }}
        defaultValue={roleOptions[0]?.value}
      >
        {roleOptions.map((item: any, index) => (
          <MenuItem key={item.value + index} value={item.value}>
            {item.value}
          </MenuItem>
        ))}
      </Select>
      <Box className="w-full flex  justify-center px-16 py-8">
        <Button
          onClick={handleSubmit}
          variant="contained"
          startIcon={<CheckIcon fontSize="small" />}
        >
          {t('save')}
        </Button>
      </Box>
    </Modal>
  );
};

export default AssignUserModal;
