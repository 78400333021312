import { Alert, Box, Button, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import MountTransition from '../../components/common/mountTransition';
import { useAuth } from '../../hooks/useAuth';
import { useThemeMode } from '../../hooks/useThemeMode';
import AuthLayout from '../../layout/authLayout';
import { http, isHttpError } from '../../libs/axios';
import { Auth } from './signIn';

type Form = {
  two_factor_code: string;
};

type Items<T> = {
  [K in keyof T]-?: Required<T[K]>;
};

type Payload = Items<Pick<Auth, 'user' | 'token' | 'refresh_token'>>;

export default function TwoFactorAuthentication() {
  const { token: two_factor_token } = useParams();

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<Form>();

  const { t } = useTranslation('auth');

  const { login } = useAuth();

  const navigate = useNavigate();

  const onSubmit = async ({ two_factor_code }: Form) => {
    try {
      const {
        data: { user, token, refresh_token },
      } = await http.post<Payload>('/twofactor/login', {
        two_factor_token,
        two_factor_code,
      });

      login({ token, refresh_token }, user);

      navigate('/');
    } catch (e: unknown) {
      if (isHttpError(e)) {
        setError('two_factor_code', {
          type: 'invalid_code',
          message: t('2fa.invalid_code'),
        });
      }
    }
  };
  const { mode } = useThemeMode();

  return (
    <AuthLayout>
      <MountTransition
        mountDelay={100}
        className="transition-transform duration-700"
        transitionStyles={{
          exited: { transform: 'translateX(-100%)' },
          entered: { transform: 'translateX(0%)' },
        }}
      >
        <Box
          sx={{
            backgroundColor: mode === 'dark' ? 'background.default' : '#fff',
            color: 'text.primary',
          }}
          className="  px-8 py-10 shadow-sm"
        >
          <Box className="text-center mb-8">
            <Typography className="!mb-2" variant="h1">
              {t('2fa.title')}
            </Typography>
            <Typography variant="body1">{t('2fa.msg')}</Typography>
          </Box>

          {errors && errors.two_factor_code?.type === 'invalid_code' && (
            <Alert sx={{ mb: 3, justifyContent: 'center' }} severity="error">
              {errors.two_factor_code.message}
            </Alert>
          )}

          <Box
            component="form"
            className="flex flex-col gap-y-6 w-full"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <TextField
              type="number"
              id="2fa"
              label="Code"
              variant="outlined"
              error={Boolean(errors.two_factor_code)}
              {...register('two_factor_code', {
                minLength: 6,
                maxLength: 6,
                required: true,
              })}
            />

            <Button
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              sx={{ textTransform: 'uppercase' }}
            >
              <Box component="span" className="mr-3">
                {t('login_to_dashboard')}
              </Box>
            </Button>
          </Box>
        </Box>
      </MountTransition>
    </AuthLayout>
  );
}
