import UserItem, { userItem } from './userItem';
import { Box, Typography } from '@mui/material';
import { AgencyDrawerContent } from '../../../types/agency';

type Props = {
  content: AgencyDrawerContent;
  usersList: userItem[];
  type: 'users' | 'unassignedUsers';
  setRoleModal: (arg: boolean) => void;
  basedUrl: string;
};
function UsersList({
  usersList,
  type,
  setRoleModal,
  basedUrl,
  content,
}: Props) {
  if (!usersList || !usersList.length) return null;
  return (
    <Box className="flex flex-col gap-8 max-w-sm">
      <Typography variant="body1" className="opacity-50">
        You can choose the user and drag & drop it to the desired Country /
        Agency / Service to add it.
      </Typography>
      <Box className="flex flex-col gap-4  ">
        {usersList.map((user) => (
          <UserItem
            content={content}
            setRoleModal={setRoleModal}
            type={type}
            key={user.id + Math.random()}
            userItem={user}
            basedUrl={basedUrl}
          />
        ))}
      </Box>
    </Box>
  );
}

export default UsersList;
