// import UserInfos from './userInfos';
import useSWR from 'swr';
import { useNavigate, useParams } from 'react-router-dom';
import SpinnerLoading from '../../../features/spinner/spinnerLoading';
import { HttpError } from '../../../libs/axios';
import User from '../../../types/user';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Box } from '@mui/material';
import UserInfos from './userInfos';
import SingleContactHeader from './singleContactHeader';

function SingleContactPage() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('alerts');
  const { data, error } = useSWR<User, HttpError>(`/owners/${userId}`);
  useEffect(() => {
    if (error) {
      toast.error(t('user_not_found') as string);
      navigate('/contacts');
    }
  }, [error, navigate, t]);

  if (!data) return <SpinnerLoading />;
  return (
    <Box component="section" className="w-full h-full flex flex-col">
      <SingleContactHeader data={data} />
      <UserInfos data={data} />
    </Box>
  );
}

export default SingleContactPage;
