import { Box } from '@mui/material';
import {
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import ControlledSelectedForm from '../../../../../../../components/form/controlledSelectedForm';
import InputFormField from '../../../../../../../components/form/inputFormField';
import { GenreOptions } from '../../../../../../../const/propertiesOptions';

type Props = {
  register: UseFormRegister<any>;
  clearErrors: UseFormClearErrors<any>;
  errors: FieldErrors<any>;
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<any>;
};

function OwnerInfos({ register, clearErrors, errors, watch, setValue }: Props) {
  return (
    <Box width="100%" display="flex" flexDirection="column" gap="24px">
      <Box className="grid grid-cols-2" gap="24px">
        <Box display="flex" flexDirection="row" width="416px" gap={3}>
          <Box width="35%">
            <ControlledSelectedForm
              options={GenreOptions}
              label="Gender"
              isRequired
              name="gender"
              error={errors['gender']}
              register={register}
              watch={watch}
              setValue={setValue}
              className={'!w-36'}
            />
          </Box>
          <Box className="w-full">
            <InputFormField
              label="Firstname"
              isRequired
              variant="outlined"
              onFocus={() => clearErrors('firstname')}
              sx={{
                border: errors.firstname && '1px solid red',
                borderRadius: '5px',
                height: '43px',
              }}
              fullWidth
              {...register('firstname')}
            />
          </Box>
        </Box>
        <InputFormField
          label="Lastname"
          isRequired
          variant="outlined"
          fullWidth
          onFocus={() => clearErrors('lastname')}
          sx={{
            border: errors.lastname && '1px solid red',
            borderRadius: '5px',
            height: '43px',
          }}
          {...register('lastname')}
        />
      </Box>
      <Box className="grid grid-cols-2 " width="100%" gap={3}>
        <InputFormField
          {...register('company')}
          onFocus={() => clearErrors('company')}
          sx={{
            border: errors.company && '1px solid red',
            borderRadius: '5px',
          }}
          label="Company"
          variant="outlined"
          fullWidth
        />
        <InputFormField
          onFocus={() => clearErrors('address')}
          sx={{
            border: errors.address && '1px solid red',
            borderRadius: '5px',
            height: '43px',
          }}
          {...register('address')}
          label="Address"
          isRequired
          variant="outlined"
          fullWidth
        />
      </Box>
      <Box className="grid grid-cols-2" gap={3}>
        <Box className="grid grid-cols-2" width="416px" gap={3}>
          <InputFormField
            onFocus={() => clearErrors('zipCode')}
            sx={{
              border: errors.zipCode && '1px solid red',
              borderRadius: '5px',
              height: '43px',
            }}
            {...register('zipCode')}
            label="Zip code"
            isRequired
            variant="outlined"
          />
          <InputFormField
            {...register('city')}
            onFocus={() => clearErrors('city')}
            sx={{
              border: errors.city && '1px solid red',
              borderRadius: '5px',
              height: '43px',
            }}
            label="City"
            isRequired
            variant="outlined"
            fullWidth
          />
        </Box>
        <InputFormField
          {...register('phone')}
          onFocus={() => clearErrors('phone')}
          label="Phone"
          variant="outlined"
          fullWidth
          type="number"
        />
      </Box>
      <Box className="grid grid-cols-2" gap={3}>
        <InputFormField
          {...register('mobile')}
          onFocus={() => clearErrors('mobile')}
          label="Mobile"
          variant="outlined"
          fullWidth
          type="number"
        />
        <InputFormField
          {...register('email')}
          onFocus={() => clearErrors('email')}
          sx={{
            border: errors.email && '1px solid red',
            borderRadius: '5px',
            height: '43px',
          }}
          label="Email"
          isRequired
          variant="outlined"
          fullWidth
          type="email"
        />
      </Box>
    </Box>
  );
}

export default OwnerInfos;
