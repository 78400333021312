import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import { Box } from '@mui/material';

function SpinnerLoading() {
  return (
    <Box className="w-full h-full flex justify-center items-center ">
      <ScatterPlotIcon
        fontSize="large"
        className="animate-spin"
        sx={{
          fill: '#6E6E72',
        }}
      />
    </Box>
  );
}

export default SpinnerLoading;
