import { Box, Typography } from '@mui/material';

import { FieldErrors, UseFormRegister } from 'react-hook-form';
import InputFormField from '../../../../../../../components/form/inputFormField';
import { useGeneralInfoStore } from '../../../store';

type Props = {
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
};
const propertyDetailsInfos = [
  {
    id: 0,
    label: 'Number of rooms',
    name: 'rooms',
  },
  {
    id: 1,
    label: 'Number of bedrooms',
    name: 'bedrooms',
  },
  {
    id: 2,
    label: 'Number of bathrooms',
    name: 'bathrooms',
  },
];

const RoomsDetails = ({ register, errors }: Props) => {
  const updateInfo = useGeneralInfoStore((state) => state.updateInfo);

  return (
    <Box className="grid grid-cols-[2fr_4fr] mt-12">
      <Typography className="stepTitle">Rooms</Typography>
      <Box width="100%" display="flex" flexDirection="column" gap="48px">
        <Box width="100%" className="grid grid-cols-3" gap="48px">
          {propertyDetailsInfos.map(({ id, label, name }) => (
            <InputFormField
              isRequired
              key={label + id}
              label={label}
              inputProps={{
                step: '0.01',
              }}
              // placeholder={`Enter ${label}`}
              sx={{ border: errors[name] && '1px solid red' }}
              id="outlined-start-adornment"
              type="number"
              {...register(name, {
                onChange: (e) =>
                  updateInfo({
                    name: label.toLowerCase(),
                    value: String(e.target.value),
                  }),
              })}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default RoomsDetails;
