import { UseFormSetValue } from 'react-hook-form';
import { Box, Stack, Switch, Typography } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DateRangePicker from '../../../../../../../components/dateRangePicker/dateRangePicker';

type Props = { setValue: UseFormSetValue<any> };

const PublishOnGateWays = ({ setValue }: Props) => {
  return (
    <Box display="flex" flexDirection="row" className="mb-16" gap="80px">
      <Typography width="20%" className="stepTitle" variant="h6">
        Publish on gateways
      </Typography>
      <Box className="flex gap-52 w-full    ">
        <Box className="flex flex-col gap-3 w-[40%]">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
              <Box
                width="100%"
                className="grid grid-cols-1 items-center justify-center mt-1"
                gap={3}
              >
                <DateRangePicker
                  label={'start date'}
                  name={'startDate'}
                  setValue={setValue}
                />
                <DateRangePicker
                  label={'end date'}
                  name={'endDate'}
                  setValue={setValue}
                />
              </Box>
            </Stack>
          </LocalizationProvider>
        </Box>
        <Box className="flex flex-col gap-3  ">
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={3}
          >
            <Typography className="min-w-[250px]">
              Publish on{' '}
              <span className="text-blue-600 underline">www.immobilier.ch</span>{' '}
            </Typography>
            <Switch />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={3}
          >
            <Typography className="min-w-[250px] ">Publish Homegate</Typography>
            <Switch />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={3}
          >
            <Typography className="min-w-[250px] ">
              Publish on Immoscout 24
            </Typography>
            <Switch />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={3}
          >
            <Typography className="min-w-[250px] ">
              Publish on Properstar
            </Typography>
            <Switch />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={3}
          >
            <Typography className="min-w-[250px] ">
              Publish on Acheter-louer
            </Typography>
            <Switch />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PublishOnGateWays;
