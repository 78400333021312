import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  title: string;
  children: ReactNode;
};

function MediasBlock({ title, children }: Props) {
  return (
    <Box className="flex gap-20 mb-16">
      <Typography className="stepTitle w-2/5">{title}</Typography>
      <Box className="w-full flex flex-col gap-8">{children}</Box>
    </Box>
  );
}

export default MediasBlock;
