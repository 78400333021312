import { Link } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

type Props = {
  className?: string;
  onClick?: () => void;
  link: string;
};

function GoBackTag({ link, className, onClick }: Props) {
  const { t } = useTranslation('usersPage');
  return (
    <Box onClick={onClick} className={'flex mb-4 cursor-pointer ' + className}>
      <Link to={link} className="px-4 py-1 rounded border border-gray-300">
        <KeyboardBackspaceIcon fontSize="small" />
        <span className="ml-2 text-sm">{t('Back_to_list')}</span>
      </Link>
    </Box>
  );
}

export default GoBackTag;
