import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function ShareIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <circle cx="3" cy="13" r="1.5" stroke={color}></circle>
      <circle cx="13" cy="8" r="1.5" stroke={color}></circle>
      <circle cx="3" cy="3" r="1.5" stroke={color}></circle>
      <path stroke={color} d="M4.5 12.5l7-4m0-1L4.5 4"></path>
    </svg>
  );
}
