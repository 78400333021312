import { Box, Tooltip } from '@mui/material';
import { GridColumns, GridValidRowModel } from '@mui/x-data-grid';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  renderFullPropertyAdress,
  renderPropertyAdress,
} from '../../../helpers/utils';
import { Link } from 'react-router-dom';

const isLarge = localStorage.getItem('isLarge');
const isMacLarge = localStorage.getItem('isMacLarge');
const isMediumLarge = localStorage.getItem('isMediumLarge');
// const isMedium = localStorage.getItem('isMedium');
export const contactsListingConfig: GridColumns<GridValidRowModel> = [
  {
    field: 'firstname',
    headerClassName: '!bg-white',
    headerName: 'Firstname',
    width:
      isLarge === 'true'
        ? 250
        : isMacLarge === 'true'
        ? 200
        : isMediumLarge === 'true'
        ? 180
        : 150,
    renderCell: (params) => (
      <Tooltip title={params.row.firstname} arrow placement="top">
        <span>{params.row.firstname}</span>
      </Tooltip>
    ),
  },
  {
    field: 'lastname',
    headerName: 'Lastname',
    width:
      isLarge === 'true'
        ? 250
        : isMacLarge === 'true'
        ? 200
        : isMediumLarge === 'true'
        ? 180
        : 150,
    renderCell: (params) => (
      <Tooltip title={params.row.lastname} arrow placement="top">
        <span>{params.row.lastname}</span>
      </Tooltip>
    ),
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 250,
    renderCell: (params) => {
      return (
        <Tooltip title={params.value} arrow placement="top">
          <a
            className="text-[#3446B9] underline"
            href={`mailto:${params.value}`}
          >
            {params.value}
          </a>
        </Tooltip>
      );
    },
  },
  {
    field: 'company',
    headerName: 'Company',
    width:
      isLarge === 'true'
        ? 250
        : isMacLarge === 'true'
        ? 200
        : isMediumLarge === 'true'
        ? 200
        : 150,
    renderCell: (params) => (
      <Tooltip title={params.row.company} arrow placement="top">
        <span>{params.row.company}</span>
      </Tooltip>
    ),
  },
  {
    field: 'mobile',
    headerName: 'Mobile',
    width: isMacLarge === 'true' ? 160 : 150,
    renderCell: (params) => (
      <Tooltip
        title={
          params.row.mobile[0] === '+'
            ? params.row.mobile
            : '+' + params.row.mobile
        }
        arrow
        placement="top"
      >
        <p>
          {params.row.mobile[0] === '+'
            ? params.row.mobile
            : '+' + params.row.mobile}
        </p>
      </Tooltip>
    ),
  },
  {
    field: 'phone',
    headerName: 'Phone',
    width: isMacLarge === 'true' ? 160 : 150,
    renderCell: (params) => (
      <Tooltip
        title={
          params.row.phone[0] === '+'
            ? params.row.phone
            : '+' + params.row.phone
        }
        arrow
        placement="top"
      >
        <p>
          {params.row.phone[0] === '+'
            ? params.row.phone
            : '+' + params.row.phone}
        </p>
      </Tooltip>
    ),
  },
  {
    field: 'adress',
    headerName: 'Adress',
    width:
      isLarge === 'true'
        ? 235
        : isMacLarge === 'true'
        ? 235
        : isMediumLarge === 'true'
        ? 185
        : 150,
    renderCell: ({ row }: any) => {
      return (
        <Box className="w-3/4 flex justify-center items-center  ">
          <Tooltip title={renderFullPropertyAdress(row)} arrow placement="top">
            {renderPropertyAdress(row)}
          </Tooltip>
        </Box>
      );
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 97,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (value) => {
      return (
        <Box className="w-full flex justify-center items-center  ">
          <Link to={`/contacts/${value.id}`}>
            <Tooltip title="contact details" arrow placement="top">
              <span>
                <VisibilityOutlinedIcon
                  color="primary"
                  className="w-5 h-5 cursor-pointer"
                />
              </span>
            </Tooltip>
          </Link>
        </Box>
      );
    },
  },
];
