import { useState } from 'react';
import { Box } from '@mui/material';
import copyTextToClipboard from '../../helpers/copyToClipboard/copyToClipboard';

type Props = {
  link: string;
  icon: JSX.Element;
  CopyText?: string;
  className?: string;
};

function CopyLinkTag({ link, icon, CopyText, className }: Props) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    copyTextToClipboard(link)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        color: 'text.primary',
      }}
      className={className + ' flex items-center gap-3'}
    >
      {icon}
      <p>{link}...</p>
      <p
        onClick={handleCopyClick}
        className={`text-sm  cursor-pointer ${
          isCopied ? 'text-green-600' : 'text-[#3446B9]'
        }`}
      >
        {isCopied ? 'Copied !' : CopyText || 'Copy Link'}
      </p>
    </Box>
  );
}

export default CopyLinkTag;
